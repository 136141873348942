import { useSelector } from 'react-redux';

import { ShoppingBagIcon20 } from '../../../../../assets';
import TitleBar from '../../../../../components/organisms/TitleBar';
import { LinkProps } from '../../../../../components/organisms/TitleBar/TitleBar.types';
import { State } from '../../../../../types/state.types';
import { SERVICE } from '../../../../config';
import { orderConfig } from '../../../config';
import useCartInfo from '../../../hooks/useCartInfo/useCartInfo';
import useResetKioskSessionAfterTwoMin from '../../../hooks/useClearKioskSession/useResetKioskSessionAfterTwoMin';
import { useOrderTranslation } from '../../../hooks/useOrderTranslation';

const CartWidget = ({ hide }: LinkProps) => {
  const { label } = useOrderTranslation(__filename);
  const services = useSelector((state: State) => state.Core.services);
  const isOrderServiceAvailable = services.list.find(
    (el: { name: string }) => el.name === SERVICE.FOOD_ORDER
  );
  const cartInfo = useCartInfo();
  useResetKioskSessionAfterTwoMin();

  if (hide) return <></>;

  const { isKioskTitleBar } = orderConfig();

  const basketButtonInfo = isOrderServiceAvailable && cartInfo;
  const cartItemCount = basketButtonInfo?.itemsCount;
  const cartItemCountDisplayText = cartItemCount ? cartItemCount.toString() : '';
  const moreThan99BasketItems = cartItemCount && cartItemCount > 99;

  return (
    <TitleBar.Widget
      label={label}
      labelKey="cart"
      Icon={ShoppingBagIcon20}
      filled
      iconOnTheLeft
      forceToDisplayLabel={isKioskTitleBar}
      linkTo={basketButtonInfo?.linkTo}
      highlightText={moreThan99BasketItems ? '99+' : cartItemCountDisplayText}
      srOnlyText={cartItemCount ? 'Ref: products in cart' : 'Ref: no product in cart'}
    />
  );
};

export default CartWidget;
