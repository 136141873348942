import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import momentjs from 'moment';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { ShoppingBagIcon, CartIcon, HomeIcon } from '../../../assets/icons';
import AutoFocusContainer from '../../../components/atoms/AutoFocusContainer/AutoFocusContainer';
import Button from '../../../components/atoms/Button/Button';
import { BUTTON_LOOK } from '../../../components/atoms/Button/Button.types';
import Checkbox from '../../../components/atoms/Checkbox';
import Divider from '../../../components/atoms/Divider';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title';
import { Alert } from '../../../components/molecules/Alert';
import Card from '../../../components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '../../../components/molecules/Notification';
import WidgetPlaceholder from '../../../components/molecules/WidgetPlaceholder';
import ActionsBar from '../../../components/organisms/ActionsBarV2/ActionsBar';
import Column from '../../../components/organisms/Column';
import Container from '../../../components/organisms/Container';
import Modal from '../../../components/organisms/Modal/Modal';
import TilesList from '../../../components/organisms/TilesList/TilesList';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import { DATE_FORMAT, SETUP_OPTIONS } from '../../../constants';
import useToggle from '../../../helpers/hooks/useToggle';
import { isKiosk } from '../../../helpers/misc';
import { TestingPropsV2 } from '../../../types';
import { legalDocType, pagePaths as corePagePaths } from '../../Core/config';
import { getMenus, setNewSubmissionTrackingId, syncTrackingId } from '../actions';
import { orderConfig, pagePaths } from '../config';
import CartItemTile from '../containers/CartItemTile';
import { getAllergensFromCache } from '../helpers/allergens.helper';
import {
  buildCartActions,
  canPlaceConferenceCateringOrder,
  getCartDateLabel,
  getCartNbItems,
  getModifierIdPerQuantity,
  getPaymentOptions,
  getPaymentSetupOptions,
  isCartItemLoyaltyReward,
  isCartItemProvidingLoyaltyStamps,
  mergeCartItemsDuplicates,
} from '../helpers/cart.helper';
import { getPrice } from '../helpers/order.helper';
import { useCheckCanOrder } from '../hooks/useCheckCanOrder/useCheckCanOrder';
import useCheckoutOrder from '../hooks/useCheckoutOrder';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import useResetTableAfterOneHour from '../hooks/useResetTableAfterOneHour';
import { useRetrieveKioskSession } from '../hooks/useRetrieveKioskSession/useRetrieveKioskSession';
import { useCheckNoSuggestionsToDisplay } from '../hooks/useSuggestions/useCheckNoSuggestionsToDisplay';
import { useFetchSuggestions } from '../hooks/useSuggestions/useFetchSuggestions';
import { PaymentModality } from '../types/cart.types';
import { PageType } from '../types/menuCartActions.types';
import {
  CartMenuItem,
  FacilityMenuSource,
  ScannableMenuItem,
  StateWithOrder,
} from '../types/orderState.types';
import { HightlightAllergenFilterValues } from '../types/productList.types';
import { useScannerComponent } from '../widgets/ReorderWidget/useScannerComponent';
import { SuggestPlace } from '../widgets/SuggestionsWidget/SuggestionsWidget.types';
import { useIsSuggestionsEnabled } from '../widgets/SuggestionsWidget/useIsSuggestionsEnabled';

import { ButtonsPair } from './ButtonsPair/ButtonsPair';
import ContinueJourneyModal from './ContinueJourneyModal/ContinueJourneyModal';
import LoyaltyInfoButton from './LoyaltyInfoButton/LoyaltyInfoButton';
import OrderSummary from './OrderSummary/OrderSummary';
import { PickupInformationForm } from './PickupInformationForm';
import {
  getDefaultFiltersFromCache,
  getFilteredSuggestions,
} from './ProductsList/productList.helper';
import PromotionInfo from './Promotions/PromotionInfo';
import SuggestionContainer from './SuggestionContainer/SuggestionContainer';

import { useTrackPageView } from '@/helpers/hooks/Analytics/useTrackPageView';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import useLoginStatus from '@/helpers/hooks/useLoginStatus';
import { SERVICE } from '@/modules/config';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { useGetLoyaltySchemesQuery } from '@/modules/LoyaltyStamps/api';
import LoyaltyCard from '@/modules/LoyaltyStamps/components/LoyaltyCard/LoyaltyCard';

import styles from './Cart.module.css';

const Cart = () => {
  const { isCartDayPartLabelShown, isKioskTitleBar, areSuggestionsEnabled } = orderConfig();
  const paymentSetupOptions = getPaymentSetupOptions();

  const { label } = useOrderTranslation(__filename);
  const location = useLocation();
  const history = useHistory();
  const { isGuest } = useLoginStatus();

  const dispatch = useDispatch();
  const { currentLanguageCode: languageCode } = useLanguage();
  const site = useSite({ throwWhenNoActiveSite: true })!;

  const {
    menus,
    cart,
    locks: orderLocks,
    draft: orderDraft,
    tableQRScanTime,
  } = useSelector((state: StateWithOrder) => state.Order);

  useTrackPageView({ isGlobal: false, facilityId: cart?.facilityId });
  const facilities = useSelector((state: StateWithOrder) => state.Facilities);
  const facilityName = cart?.facilityId ? facilities?.data[cart?.facilityId]?.title : undefined;

  const {
    createOrderDraft: orderDraftLock,
    getMenusForFacility: menusForFacilityLock,
    getPickupTimeSlots: isPickupTimeSlotsLocked,
  } = orderLocks;

  const pickupTimeSlots = cart?.pickupTimeSlots;
  const pickupInformation = cart?.pickupInformation;
  const menu = menus.find((x) => x.id === cart?.menuId);

  const { siteId: siteIdFromKiosk } = useParams<{ siteId: string }>();
  const { trackingId } = useParams<{ trackingId: string }>();
  const { isRetrievingOrder } = useRetrieveKioskSession({ trackingId, cart, siteIdFromKiosk });

  const isRrMenu = useMemo(() => menu?.source === FacilityMenuSource.Rr, [menu?.source]);

  const [scanSelected, setScanSelected] = useState<ScannableMenuItem | undefined>();

  const {
    data: stamps,
    isLoading: isLoadingStamps,
    isError: isErrorLoadingStamps,
  } = useGetLoyaltySchemesQuery({
    siteId: site.id,
    useErrorBoundary: false,
  });

  const guestOrderingEnabled = useIsSetupOptionEnabled(
    SETUP_OPTIONS.FOOD_ORDER.enableGuestOrdering,
    SERVICE.FOOD_ORDER
  );

  const isGuestWithoutOrderingEnabled = isGuest && !guestOrderingEnabled;

  const menuDate = cart?.date
    ? momentjs(cart?.date).format(DATE_FORMAT)
    : momentjs().format(DATE_FORMAT);

  useEffect(() => {
    const refreshMenus = () => {
      if (!menus) {
        dispatch(
          getMenus({
            siteId: site.id,
            date: menuDate,
            useCache: true,
          })
        );
      }
    };
    refreshMenus();
  }, [site.id, menuDate, menus, dispatch]);

  const menuId = menu ? menu.id : undefined;

  const { suggestions, isLoading } = useFetchSuggestions(
    site?.id,
    menuDate,
    menuId,
    isRrMenu,
    menu?.isOrderable
  );

  const selectedAllergens = useMemo(() => getAllergensFromCache() ?? [], []);

  const hideAllergens = useMemo(() => {
    if (!!menu) {
      const defaultFilters = getDefaultFiltersFromCache(site.id, menu.name, menuDate);
      return defaultFilters?.filter_allergens_highlight
        ? defaultFilters.filter_allergens_highlight[HightlightAllergenFilterValues.Hide]
        : false;
    } else {
      return false;
    }
  }, [menu, menuDate, site.id]);

  const momentBasedSuggestions = useMemo(
    () =>
      cart?.moment
        ? getFilteredSuggestions(
            suggestions.filter(
              (item) => item.mealName?.toLowerCase() === cart?.moment.toLowerCase()
            ),
            selectedAllergens,
            hideAllergens
          )
        : suggestions,
    [cart?.moment, hideAllergens, selectedAllergens, suggestions]
  );

  const { suggestionsLimitDesktop } = useCheckNoSuggestionsToDisplay(momentBasedSuggestions);

  const suggestionsToDisplay = useMemo(
    () => [...momentBasedSuggestions]?.slice(0, suggestionsLimitDesktop),
    [momentBasedSuggestions, suggestionsLimitDesktop]
  );

  const scannerComponent = useScannerComponent({
    scanSelected,
    menus: !!menu ? [menu] : menus,
    menuDate: menuDate,
    site,
    currentLanguageCode: languageCode,
    createOrderDraft: orderDraftLock,
    setScanSelected,
  });

  useResetTableAfterOneHour({ tableQRScanTime });

  const cartListItems = useMemo(() => {
    return cart ? cart.menuPortionItems?.sort((a, b) => (a.id > b.id ? 1 : -1)) : [];
  }, [cart]);

  const isNonEmptyCart = cartListItems && cartListItems.length > 0;

  const {
    popup,
    checkCartAndPay,
    isPayingOnline,
    isSubmitInProgress,
    mustAcknowledgeTermsOfSale,
    isMinimumAmountError,
    minimumAmount,
  } = useCheckoutOrder({
    site,
    cart,
    label,
    orderDraftLock,
    orderDraft,
    paymentSetupOptions,
    submissionTrackingId: cart?.submissionTrackingId,
  });

  const dateLabel = getCartDateLabel(cart?.date, label, languageCode);

  const redirectToProductList = () => {
    if (cart?.menuPortionItems?.length) {
      history.push(
        pagePaths.ProductsList.replace(':facilityId', menu?.facilityId || '')
          .replace(':menuId', menu?.id?.toString() || '')
          .replace(':date', cart?.date ? momentjs(cart.date).format(DATE_FORMAT) : '')
      );
    } else {
      history.push(pagePaths.Module);
    }
  };

  const { canOrder } = useCheckCanOrder({
    siteId: site.id,
    orderPickupDate: cart?.date ? momentjs(cart?.date).format(DATE_FORMAT) : undefined,
    selectedMenu: menu,
  });

  useEffect(() => {
    if (cart) mergeCartItemsDuplicates({ cart, dispatch });
  }, [cart, dispatch]);

  useEffect(() => {
    dispatch(setNewSubmissionTrackingId({ submissionTrackingId: uuid() })); // Payment API needs new trackingCode when someone goes back
  }, [dispatch]);

  const { state: isTermsOfSalesAcknowledged, toggle: setIsTermsOfSalesAcknowledged } =
    useToggle(false);

  const {
    state: isInfoModalOpen,
    toggleOn: openInfoModal,
    toggleOff: hideInfoModal,
  } = useToggle(false);

  const {
    state: isContinueOrderingJourneyModalOpen,
    toggleOn: openContinueOrderingJourneyModal,
    toggleOff: hideContinueOrderingJourneyModal,
  } = useToggle(false);

  const {
    state: isLoyaltyInfoModalOpen,
    toggleOn: openLoyaltyInfoModal,
    toggleOff: closeLoyaltyInfoModal,
  } = useToggle(false);

  const [paymentMethod, setPaymentMethod] = useState<PaymentModality | undefined>();
  const onSelectPaymentMethod = useCallback(
    (selected: string) => setPaymentMethod(selected as PaymentModality),
    [setPaymentMethod]
  );

  const isCartUpToDate = orderDraft && !orderDraftLock;

  const isTimeSlotsLocked =
    isPickupTimeSlotsLocked || !pickupInformation?.pickupSpotId || orderDraftLock;

  const [isOrderUnavailableAlert, setIsOrderUnavailableAlert] = useState(false);

  const totalPrice = isCartUpToDate
    ? getPrice(orderDraft?.total, languageCode, site.currency!.isoCode)
    : ' ';

  const isConferenceCateringAvailable = useMemo(
    () => canPlaceConferenceCateringOrder(site.id, menu?.isConferenceCatering),
    [menu?.isConferenceCatering, site?.id]
  );

  const paymentOptions = useMemo(() => {
    const isConferenceCateringAvailable = canPlaceConferenceCateringOrder(
      site.id,
      menu?.isConferenceCatering
    );
    const paymentOptions =
      !isGuestWithoutOrderingEnabled && orderDraft?.total !== 0
        ? getPaymentOptions({
            label,
            selectedPaymentOption: paymentMethod,
            paymentSetupOptions,
            total: totalPrice,
            isConferenceCateringAvailable,
          })
        : [];
    return paymentOptions;
  }, [
    menu?.isConferenceCatering,
    orderDraft,
    isGuestWithoutOrderingEnabled,
    site.id,
    label,
    paymentMethod,
    paymentSetupOptions,
    totalPrice,
  ]);

  const showNoTimeSlotsNotification =
    (pickupTimeSlots?.length === 0 || !pickupTimeSlots) && !isTimeSlotsLocked;

  useEffect(() => {
    if (showNoTimeSlotsNotification) {
      setIsOrderUnavailableAlert(Capacitor.isNativePlatform());
    } else {
      setIsOrderUnavailableAlert(false);
    }
  }, [showNoTimeSlotsNotification]);

  const actions = buildCartActions({
    label,
    checkCartAndPay,
    cart,
    orderDraft,
    isLoading: !orderDraft || orderDraftLock || isSubmitInProgress,
    isGuest: isGuest,
    isGuestOrdering: guestOrderingEnabled,
    paymentSetupOptions,
    redirectToRegister: () =>
      history.push({ pathname: corePagePaths.GuestRegistration, state: { from: location } }),
    mustAcknowledgeTermsOfSale,
    isTermsOfSalesAcknowledged,
    paymentMethod,
    isOrderingPossible: canOrder,
    openInfoModal,
    openContinueOrderingJourneyModal,
    syncTrackingId: () => syncTrackingId({ dispatch }),
    isConferenceCateringAvailable,
    isScanAndGo: menu?.isScanAndGo ?? false,
    languageCode,
  });

  const bottomBarTopContent = isNonEmptyCart &&
    mustAcknowledgeTermsOfSale &&
    actions?.length > 0 &&
    !isGuestWithoutOrderingEnabled &&
    !isKiosk && (
      <div>
        <Checkbox
          id="termsOfSales"
          onClick={setIsTermsOfSalesAcknowledged}
          data-testid="cart-terms-of-sales-checkbox"
        />
        <label htmlFor="termsOfSales" className={styles.termsOfSalesLabel}>
          <span className={classNames(styles.termOfSaleLabel)}>
            {label('Ref: Accept terms of sale prefix')}{' '}
            <Link
              to={corePagePaths.LegalDoc.replace(
                ':document_type',
                legalDocType.terms_of_sales
              ).replace(':version', 'current')}
            >
              {label('Ref: Accept terms of sale')}
            </Link>
            {label('Ref: Accept terms of sale suffix')}
          </span>
        </label>
      </div>
    );

  const noOfStampsToGet =
    orderDraft?.loyaltyStampsToBeEarned?.reduce((total, item) => total + item.stampsCount, 0) ?? 0;
  const loyaltyInfo = stamps && isCartUpToDate && noOfStampsToGet > 0 && !isKiosk && (
    <>
      <p className={classNames(styles.actionBarInfo)}>
        <span className={classNames(styles.stampsLabel)}>
          {noOfStampsToGet} {noOfStampsToGet > 1 ? label('Ref: Stamps') : label('Ref: Stamp')}
        </span>
        <LoyaltyInfoButton onClick={openLoyaltyInfoModal} title={label('Ref: Loyalty Info')} />
      </p>
      <Divider data-testid="cart-divier-loyalty" className={classNames(styles.noMargin)}></Divider>
    </>
  );

  const actionsBar =
    actions.length !== 0 && isNonEmptyCart ? (
      <ActionsBar inMainColumn>
        <div className={styles.actionBar}>
          {loyaltyInfo}
          {isGuestWithoutOrderingEnabled ? (
            <div>{label('Ref: Please login or sign up to place an order')}</div>
          ) : (
            bottomBarTopContent
          )}
          {actions.map((action, index) => (
            <Button
              key={action.key}
              {...action}
              onClick={action.action}
              data-testid={`cart-action-${index}`}
            />
          ))}
        </div>
      </ActionsBar>
    ) : null;

  const topContent = isNonEmptyCart ? (
    <div>
      <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES} className={'mb-M'}>
        {facilityName}
      </Title>
      <ButtonsPair
        buttons={[
          { onClick: redirectToProductList, title: label('Shop'), icon: CartIcon },
          { onClick: () => history.push(corePagePaths.Home), title: label('home'), icon: HomeIcon },
        ]}
        className={'mb-L'}
      />
      {isCartDayPartLabelShown && (
        <div className={'mb-S'}>
          {cart?.moment && (
            <Title size={TITLE_SIZE.BODYMDEFAULT} className={styles.cartDate}>
              <p className={classNames(styles.cartDateWrapper)}>
                {dateLabel}, {cart.moment} - {totalPrice}
              </p>
            </Title>
          )}
        </div>
      )}
      <Divider className={styles.withShortMargin} data-testid="cart-top-content-divider" />
      {showNoTimeSlotsNotification && (
        <div className={styles.notificationWrapper}>
          <Notification title={label('Ref: Order Unavailable')} look={NOTIFICATION_LOOK.ERROR} />
          <Alert
            isOpen={isOrderUnavailableAlert}
            header={label('Ref: No Availability')}
            message={label('Ref: Order Unavailable')}
            onDismiss={() => setIsOrderUnavailableAlert(false)}
            data-testid="cart-order-unavailable-alert"
          />
        </div>
      )}
      {isMinimumAmountError && (
        <div className={styles.notificationWrapper}>
          <Notification
            title={label('Ref: Order minimum amount', {
              replace: {
                minimumAmount: minimumAmount
                  ? getPrice(minimumAmount, languageCode, site.currency?.isoCode ?? '')
                  : '',
              },
            })}
            look={NOTIFICATION_LOOK.ERROR}
          />
        </div>
      )}
    </div>
  ) : null;

  const productsCount = isCartUpToDate && orderDraft && cart ? getCartNbItems(cart) : 0;

  const emptyCartContent = (
    <>
      <WidgetPlaceholder icon={<ShoppingBagIcon />} title={label('Ref: The cart is empty')} />
      <ButtonsPair
        buttons={[
          { onClick: redirectToProductList, title: label('Shop'), icon: CartIcon },
          { onClick: () => history.push(corePagePaths.Home), title: label('home'), icon: HomeIcon },
        ]}
      />
    </>
  );

  const isSuggestionsOnCart = useIsSuggestionsEnabled(SuggestPlace.CART);

  const cartSuggestions = (
    <>
      <SuggestionContainer
        id={'suggestions_cart'}
        isLoading={isLoading}
        suggestions={suggestionsToDisplay}
        date={cart?.date ?? momentjs()}
        menus={menus}
        onScanButtonClick={(item) => setScanSelected(item)}
        label={label}
        highlightAllergens={!hideAllergens}
        selectedAllergens={cart?.menuPortionItems?.length ? selectedAllergens : undefined}
        pageType={PageType.cart}
      />
      {scannerComponent}
    </>
  );

  const cartListItemsWithTestingId = useMemo(() => {
    return cartListItems?.map((item) => ({
      ...item,
      'data-testid': `cart-item-${item.id}`,
    }));
  }, [cartListItems]);

  const firstColumn =
    isNonEmptyCart && orderDraft ? (
      <>
        <Card>
          <div className="mb-M">
            <OrderSummary
              subTotal={orderDraft?.subtotal}
              total={orderDraft?.total}
              tax={orderDraft?.tax}
              subsidy={orderDraft?.subsidy}
              delivery={pickupInformation?.pickupTimeSlotId ? orderDraft?.delivery : undefined}
              deliveryOptionType={cart?.selectedFulfillmentType?.type}
              promotionDiscount={orderDraft?.promotionDiscount}
              appliedPromotions={orderDraft?.promotionsApplied}
              productsCount={productsCount}
              isCartUpToDate={isCartUpToDate}
              showClearCart
            />
          </div>
          <TilesList
            items={cartListItemsWithTestingId}
            itemRenderer={(item: CartMenuItem & TestingPropsV2) => {
              const props = {
                ...item,
                description: item.modifiersDisplayText || item.description,
                menuId: menu!.id,
                date: momentjs(cart?.date).format(DATE_FORMAT),
                pageType: PageType.cart,
                itemModifierIds: getModifierIdPerQuantity(item.modifiers),
                isLoyaltyReward: isCartItemLoyaltyReward(item, menu?.id, stamps),
                isProvidingLoyaltyStamps: isCartItemProvidingLoyaltyStamps(item, menu?.id, stamps),
                isKiosk: isKiosk,
              };
              return <CartItemTile key={item.id} {...props} />;
            }}
          />
        </Card>
        {areSuggestionsEnabled && isRrMenu && menu?.isOrderable && isSuggestionsOnCart
          ? cartSuggestions
          : null}
      </>
    ) : areSuggestionsEnabled && isSuggestionsOnCart ? (
      cartSuggestions
    ) : (
      <AutoFocusContainer inheritStyle={styles.container}>{emptyCartContent}</AutoFocusContainer>
    );

  const loyaltyCard =
    !isLoadingStamps &&
    !isKiosk &&
    orderDraft?.loyaltyStampsToBeEarned?.length &&
    !isErrorLoadingStamps ? (
      <LoyaltyCard
        label={label}
        loyaltySchemes={stamps}
        loyaltyStampsToBeEarned={orderDraft?.loyaltyStampsToBeEarned}
        menuId={menu?.id}
        pageType={PageType.cart}
        modalOpen={isLoyaltyInfoModalOpen}
        openModal={openLoyaltyInfoModal}
        closeModal={closeLoyaltyInfoModal}
        key="loyalityCard"
      />
    ) : null;

  const secondColumn =
    isNonEmptyCart && menu ? (
      <>
        <Card>
          <PickupInformationForm
            label={label}
            menu={menu}
            paymentOptions={paymentOptions}
            setPaymentMethod={onSelectPaymentMethod}
            isGuestOrdering={isGuest && guestOrderingEnabled}
          />
        </Card>
      </>
    ) : areSuggestionsEnabled && isSuggestionsOnCart ? (
      emptyCartContent
    ) : null;

  const alertPopup = popup && (
    <Alert
      isOpen={true}
      onDismiss={popup.onDismiss}
      className="popup-warning"
      header={popup.header}
      message={popup.message}
      buttons={popup.buttons}
      data-testid="cart-alert-popup"
    />
  );

  const promotionInfo = isNonEmptyCart ? (
    <PromotionInfo key="promotionInfo" />
  ) : (
    <Fragment key="promotionInfoEmptyCart"></Fragment>
  );

  if (isRetrievingOrder || menusForFacilityLock || isPayingOnline || isSubmitInProgress) {
    return <LoadingPage />;
  }

  return (
    <SimpleFormPage
      title={label('Ref: Page title')}
      hasBackLink={true}
      actions={[]}
      actionsBarTopContent={null}
      hideAccountWidget={isKioskTitleBar}
      hideNotificationsWidget={isKioskTitleBar}
    >
      {
        <Container.WithAdditionalSections
          notificationContent={promotionInfo}
          sideBottomSection={loyaltyCard}
        >
          <Column.Side>
            {topContent} {secondColumn}
          </Column.Side>
          <Column.Main>
            {alertPopup}
            {firstColumn}
            {actionsBar}
            <Modal
              isOpen={isInfoModalOpen}
              onDismiss={hideInfoModal}
              title={label('Service unavailable')}
              aria-modal="true"
              footer={
                <Button
                  inheritStyle={styles.modalButton}
                  look={BUTTON_LOOK.SECONDARY}
                  onClick={hideInfoModal}
                  data-testid="cart-info-modal-close-button"
                >
                  {label('close')}
                </Button>
              }
            >
              <Modal.ColumnLayout aria-modal="true">
                {label('Ref: Cannot order')}
              </Modal.ColumnLayout>
            </Modal>
            <ContinueJourneyModal
              siteId={site?.id}
              submissionTrackingId={cart?.submissionTrackingId}
              isModalOpen={isContinueOrderingJourneyModalOpen}
              hideModal={hideContinueOrderingJourneyModal}
              isLoading={orderDraftLock}
            />
          </Column.Main>
        </Container.WithAdditionalSections>
      }
    </SimpleFormPage>
  );
};

export default Cart;
