import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import useLoginStatus from '../../../../helpers/hooks/useLoginStatus';
import { cacheSelectedAllergens } from '../../../Order/helpers/allergens.helper';
import { resetFilters } from '../../../Order/hooks/useClearKioskSession/useResetKioskSession';
import {
  useLazyGetFilterPreferencesQuery,
  useSaveFilterPreferencesMutation,
} from '../../api/account/accountPreferencesApi';
import {
  getAccountPageConfig,
  legalDocType,
  legalDocTypeNamesToCodeMap,
  pagePaths,
} from '../../config';
import {
  canDisplayFilterPreference,
  generateActions,
  generateMyInfo,
  getVersionInfo,
} from '../../helpers/accountPage.helper';
import { applyTheme } from '../../helpers/helpers';
import { getPushNotificationRegistrationData } from '../../helpers/notificationRegistrationHelper';
import { isSSOUser } from '../../helpers/onboardingHelpers';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';
import useLanguage from '../../hooks/useLanguage';
import useSite from '../../hooks/useSite';

import { AccountUserPhone } from './AccountPage.types';
import {
  definePreferenceFilters,
  mapFilteringToSaveModel,
  mapResponseToFilters,
} from './filterPreferences.helper';

import { AccountIllustration } from '@/assets/illustrations';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Filters from '@/components/atoms/Filters/Filters';
import { Filtering } from '@/components/atoms/Filters/Filters.types';
import FormLine from '@/components/atoms/FormLine/FormLine';
import Hint from '@/components/atoms/Hint';
import { PhoneInput } from '@/components/atoms/Input';
import { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import Form from '@/components/molecules/Form';
import { trimFields } from '@/components/molecules/Form/helpers';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import List from '@/components/organisms/List/List';
import Modal from '@/components/organisms/Modal';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { waitForDomElementToExist } from '@/helpers/dom';
import { logout } from '@/modules/Core/actions';
import { useGetIdentityContextQuery } from '@/modules/Core/api/account/accountApi';
import {
  useGetAccountUserContextQuery,
  useUpdateUserContextMutation,
} from '@/modules/Core/api/account/userContextApi';
import { getAllergens } from '@/modules/Order/actions';
import { State } from '@/types/state.types';

import styles from './AccountPage.module.css';

const AccountPage = () => {
  const { currentLanguage } = useLanguage();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { label } = useCoreTranslation(__filename);

  const { user, isGuest } = useLoginStatus();
  const site = useSite();

  const [getFilters] = useLazyGetFilterPreferencesQuery();
  const [saveFilterPreferences] = useSaveFilterPreferencesMutation();
  const [updateUserContext, { isLoading }] = useUpdateUserContextMutation();
  const { data: userData } = useGetAccountUserContextQuery({});
  const { email } = userData ?? {
    email: '',
  };

  const { data: identity } = useGetIdentityContextQuery(
    { username: email ?? '' },
    { skip: !email }
  );

  const themeSettings = useSelector((state: State) => state.Core.context.theme?.settings);
  const acknowledgements = useSelector((state: State) => state.Core.user.acknowledgements);
  const legalDocsList = useSelector((state: State) => state.Core.legalDocs?.list ?? []);
  const allergens = useSelector((state: State) => state.Order?.allergens) ?? [];
  const isAllergensLocked = useSelector((state: State) => state.Order?.locks.getAllergens) ?? false;

  const shouldDisplayTermsOfSalesLink = !!acknowledgements?.find(
    (el) => el.type?.value === legalDocTypeNamesToCodeMap[legalDocType.terms_of_sales]
  );
  const shouldDisplayImprintLink = !!legalDocsList?.find(
    (el) => el.type?.value === legalDocTypeNamesToCodeMap[legalDocType.imprint]
  );
  const logoutUser = async () => {
    dispatch(await logout());
    history.push(pagePaths.Home); //redirect now so that the user will land on the home after the next login
  };

  const goToRegistration = () => {
    history.push({
      pathname: pagePaths['GuestRegistration'],
      state: { from: location },
    });
  };

  const [currentFiltering, setFiltering] = useState<Filtering | null>(null);
  const [showPushNotificationInfoCounter, setShowPushNotificationInfoCounter] = useState(0);

  useEffect(() => {
    if (showPushNotificationInfoCounter === 7) {
      setShowPushNotificationInfoCounter(0);
      const platform = Capacitor.getPlatform();
      if (platform !== 'web') {
        const pushNotificationData = getPushNotificationRegistrationData();

        if (pushNotificationData) alert(JSON.stringify(pushNotificationData, null, 2));
        else alert('Push notification registration data missing');
      }
    }
  }, [showPushNotificationInfoCounter]);

  const openConsentPreferencesWindow = useCallback(() => {
    global.Optanon.ToggleInfoDisplay();
    waitForDomElementToExist('#onetrust-consent-sdk').then(() => {
      setTimeout(() => {
        applyTheme(themeSettings);
      }, 1);
    });
  }, [themeSettings]);

  const { termsOfSale } = getAccountPageConfig({ shouldDisplayTermsOfSalesLink });

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);

  const formDefaultValues = useMemo(() => {
    return {
      mobile: userData?.mobile ?? '',
    };
  }, [userData]);

  const {
    handleSubmit,
    formState: { isValid },
    control,
    reset,
  } = useForm<AccountUserPhone>({
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const onSubmit: SubmitHandler<AccountUserPhone> = async (mobile) => {
    const trimmedData = trimFields<AccountUserPhone>(mobile);

    await updateUserContext(trimmedData);
    setIsPhoneModalOpen(false);
  };

  useEffect(() => {
    if (isPhoneModalOpen) reset(formDefaultValues);
  }, [formDefaultValues, isPhoneModalOpen, reset]);

  const legalDocRegex = /:document_type|:version/g;

  const versionInfo = getVersionInfo();
  const myInfos = useMemo(
    () => generateMyInfo(userData, label, isGuest, site?.name, setIsPhoneModalOpen),
    [userData, isGuest, site, label, setIsPhoneModalOpen]
  );

  const settings = [
    {
      id: 'Password',
      label: label('Ref: Password'),
      path: pagePaths['AccountPasswordChange'],
      hide: isGuest || (identity && isSSOUser(identity)),
      'data-testid': 'psw-item',
    },
    {
      id: 'Lang',
      label: label('Ref: Language'),
      value: currentLanguage?.name,
      path: pagePaths['Language'],
      'data-testid': 'lan-item',
    },
    {
      id: 'T&C',
      label: label('Ref: Terms & conditions'),
      'data-testid': 'terms-item',
      path: pagePaths['LegalDoc'].replace(
        legalDocRegex,
        (match) =>
          ({ ':document_type': legalDocType.terms_of_use, ':version': 'current' }[match] || '')
      ),
    },
    {
      id: 'PP',
      label: label('Ref: Privacy policy'),
      'data-testid': 'privacy-item',
      path: pagePaths['LegalDoc'].replace(
        legalDocRegex,
        (match) =>
          ({ ':document_type': legalDocType.privacy_policy, ':version': 'acknowledged' }[match] ||
          '')
      ),
    },
    {
      label: label('Ref: Cookie Settings'),
      id: 'otg',
      'data-testid': 'cookie-item',
      action: openConsentPreferencesWindow,
    },
  ];

  if (site && canDisplayFilterPreference(isGuest, site)) {
    settings.push({
      id: 'FilPref',
      label: label('Ref: Filtering Preferences'),
      'data-testid': 'filter-pref-item',
      action: async () => {
        await dispatch(getAllergens({ siteId: site.id }));
        const response = await getFilters({ siteId: site.id });
        if (!!response.data) {
          setFiltering(mapResponseToFilters(response.data));
        }

        setIsFilterModalOpen(true);
      },
    });
  }

  if (shouldDisplayImprintLink) {
    settings.push({
      id: 'imp',
      label: label('Ref: Imprint'),
      'data-testid': 'imprint-item',
      path: pagePaths['LegalDoc'].replace(
        legalDocRegex,
        (match) => ({ ':document_type': legalDocType.imprint, ':version': 'current' }[match] || '')
      ),
    });
  }

  if (termsOfSale.display) {
    settings.push({
      id: 'ToS',
      label: label('Ref: Terms of sale'),
      'data-testid': 'terms-sale-item',
      path: pagePaths['LegalDoc'].replace(
        legalDocRegex,
        (match) =>
          ({ ':document_type': legalDocType.terms_of_sales, ':version': termsOfSale.version }[
            match
          ] || '')
      ),
    });
  }

  // push DeleteAccount to the Settings as the last one
  if (!isGuest) {
    settings.push({
      id: 'DeleteAccount',
      'data-testid': 'delete-account-item',
      label: label('Ref: Delete my account'),
      path: pagePaths['AccountDelete'],
    });
  }

  const actions = generateActions(
    user,
    isGuest,
    label,
    shouldDisplayTermsOfSalesLink,
    logoutUser,
    goToRegistration
  ).filter((action) => action);

  const defaultFiltering: Filtering = {
    filter_allergens_highlight: { highlight: true },
  };

  const handleSaveFiltering = (filtering: Filtering) => {
    const saveModel = {
      siteId: site?.id ?? '',
      filters: mapFilteringToSaveModel(filtering),
    };
    saveFilterPreferences(saveModel);
    resetFilters(site?.id);
    cacheSelectedAllergens(saveModel.filters.selectedAllergens || []);
  };

  //only mobile is editable at the moment. review the function to support more fields.
  return (
    <SimpleFormPage hasBackLink={false} title={label('Ref: Page title')}>
      <Container>
        <Column.Main>
          <Card
            title={{
              tag: TITLE_TAG.H3,
              size: TITLE_SIZE.HEADLINEXS,
              children: label('Ref: My Information'),
            }}
          >
            <List data-testid="my-info-list" items={myInfos} />
          </Card>
          <Card
            title={{
              tag: TITLE_TAG.H3,
              size: TITLE_SIZE.HEADLINEXS,
              children: label('Ref: Settings'),
            }}
          >
            <List data-testid="settings-list" items={settings} />
          </Card>
          <span
            aria-hidden="true"
            onClick={() => setShowPushNotificationInfoCounter((current) => current + 1)}
          >
            <Hint
              data-testid="account-page"
              className={classNames(styles.applicationInfo)}
              text={versionInfo}
            />
          </span>

          {isAllergensLocked ? (
            <LoadingPage />
          ) : (
            <Filters
              filters={definePreferenceFilters(label, allergens, isGuest)}
              handleChange={handleSaveFiltering}
              filtering={currentFiltering ?? defaultFiltering}
              hideTitle={true}
              setModalState={setIsFilterModalOpen}
              isModalOpen={isFilterModalOpen}
              data-testid="account-filtering-preferences"
            />
          )}
          <Modal
            data-testid="account-phone-modal"
            contentClassName={styles.modalWidth}
            isOpen={isPhoneModalOpen && !isLoading}
            onDismiss={() => {
              setIsPhoneModalOpen(false);
            }}
            title={label('Mobile phone')}
            children={
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormLine data-testid="account-phone-edit">
                  <PhoneInput
                    control={control}
                    name="mobile"
                    labelFunc={label}
                    data-ci="input-phone"
                    data-testid="account-phone"
                    required
                  />
                </FormLine>
              </Form>
            }
            footer={
              <Button
                className={classNames(styles.modalButton)}
                disabled={!isValid}
                look={BUTTON_LOOK.PRIMARY}
                onClick={handleSubmit(onSubmit)}
                srOnlyText={label('Confirm mobile phone')}
                data-testid="confirm-phone-edit"
              >
                {label('confirm', { textTransform: 'capitalize' })}
              </Button>
            }
          />
        </Column.Main>
        <Column.Complementary>
          <AccountIllustration />
        </Column.Complementary>
        <ActionsBar>{actions}</ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default AccountPage;
