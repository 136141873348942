import classNames from 'classnames';
import momentjs from 'moment';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { CommentIcon } from '../../../assets/icons';
import Button, { BUTTON_LOOK } from '../../../components/atoms/Button';
import { MultiCardSelectParams } from '../../../components/atoms/MultiCardSelect/MultiCardSelect.types';
import { SelectOption } from '../../../components/atoms/Select/Select.types';
import Dropdown from '../../../components/molecules/Dropdown';
import Notification, { NOTIFICATION_LOOK } from '../../../components/molecules/Notification';
import SecondaryActions from '../../../components/molecules/SecondaryActions/SecondaryActions';
import { Tag } from '../../../components/molecules/Tile/Tag';
import { TAG_VARIANT } from '../../../components/molecules/Tile/Tile.types';
import { ContentDetailsHeaderProps } from '../../../components/organisms/ContentDetailsHeader';
import ContentDetailsPage from '../../../components/templates/ContentDetailsPage';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { SETUP_OPTIONS } from '../../../constants';
import { toLocalDate } from '../../../helpers/dateTime';
import { isKioskApp } from '../../../helpers/misc';
import { State } from '../../../types/state.types';
import { pagePaths } from '../../Review/config';
import { PATH_REVIEW_TYPES, REDIRECTED_PAGES } from '../../Review/types/reviewForm.types';
import { getMenus } from '../actions';
import { orderConfig } from '../config';
import {
  filterBySelectedAllergens,
  getAllergensFromCache,
  getPortionAndSelectedModifiersAllergens,
} from '../helpers/allergens.helper';
import {
  isFFAppSharingEnabled,
  isFFProductFavouriteEnabled,
} from '../helpers/feature.flags.helper';
import { buildProductTotalPrice } from '../helpers/menuCartActions.helper';
import { validateModifiers } from '../helpers/modifiers.helper';
import {
  getInitialModifiers,
  getMenuItemById,
  getModifiersFromCart,
  portionPrice,
} from '../helpers/productDetails.helper';
import {
  buildAllergenChip,
  buildLoyaltyPointsForOrderingChip,
  buildLoyaltyRewardChip,
  buildVegeChip,
  hasPortionProvidingLoyaltyStamps,
  hasPortionRedeemableAsRewardInLoyaltyScheme,
} from '../helpers/productTile.helpers';
import { useLoyaltyReward } from '../hooks/useLoyaltyReward/useLoyaltyReward';
import { useMenuItemsAvailableForScan } from '../hooks/useMenuItemsAvailableForScan';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import usePortion from '../hooks/usePortion';
import { useProductPortion } from '../hooks/useProductPortion/useProductPortion';
import { useCheckNoSuggestionsToDisplay } from '../hooks/useSuggestions/useCheckNoSuggestionsToDisplay';
import { useFetchSuggestions } from '../hooks/useSuggestions/useFetchSuggestions';
import { PageType } from '../types/menuCartActions.types';
import { ModifierErrors } from '../types/modifiers.types';
import { FacilityMenuSource, MenuItem } from '../types/orderState.types';
import { CardRefs, ModifierSelections } from '../types/productDetails.types';
import { HightlightAllergenFilterValues, SiteCurrency } from '../types/productList.types';
import { SuggestPlace } from '../widgets/SuggestionsWidget/SuggestionsWidget.types';
import { useIsSuggestionsEnabled } from '../widgets/SuggestionsWidget/useIsSuggestionsEnabled';

import MenuCartActions from './MenuCartActions';
import { MenuTitleOrderable } from './MenuTitleOrderable';
import { MenuTitleViewable } from './MenuTitleViewable';
import {
  MenuDescription,
  MenuImage,
  MenuNutritions,
  MenuAdditives,
  MenuIngredients,
  MenuEmissions,
  MenuModifiers,
  AllergensNotification,
  Allergens,
  computeShareableLink,
  getRedirectURL,
} from './ProductDetailsHelpers';
import ProductScanner from './ProductScanner';
import {
  getDefaultFiltersFromCache,
  getFilteredSuggestions,
} from './ProductsList/productList.helper';
import SuggestionContainer from './SuggestionContainer/SuggestionContainer';

import { Share20Icon, StarFilledIcon, StarOutlinedIcon } from '@/assets/index';
import useGeoCode from '@/helpers/hooks/useGeoCode';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { useSetupOption } from '@/helpers/hooks/useSetupOption/useSetupOption';
import useShareModal from '@/helpers/hooks/useShareModal';
import useToggle from '@/helpers/hooks/useToggle';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { SERVICE } from '@/modules/config';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { useGetLoyaltySchemesQuery } from '@/modules/LoyaltyStamps/api';
import LoyaltyCard from '@/modules/LoyaltyStamps/components/LoyaltyCard/LoyaltyCard';
import { ISiteCurrency } from '@/modules/Sites/types/sites.types';
import { UserSteps } from '@/types/userSteps.types';

import styles from './ProductDetails.module.css';

const ProductDetails = () => {
  const history = useHistory();
  const params: {
    id: string;
    facilityId: string;
    date: string;
    menuId: string;
    cartItem: string;
  } = useParams();
  const dispatch = useDispatch();

  // Site data
  const site = useSite({ throwWhenNoActiveSite: true })!;

  // Extracting parameters from URL
  const { id, facilityId, date, menuId, cartItem } = params;

  const parsedId = parseInt(id, 10);
  const parsedMenuId = parseInt(menuId, 10);

  // Accessing Redux state
  const { menus, draft: orderDraft, cart } = useSelector((state: State) => state.Order);
  const menuItem = getMenuItemById(parsedId, menus);
  const selectedCartItem = useSelector((state: State) =>
    state.Order.cart?.menuPortionItems?.find((x) => x.id === cartItem)
  );
  const menu = menus.find((x) => x.id === parsedMenuId);
  const isScanAndGo = menu?.isScanAndGo;
  const { currentLanguageCode: languageCode } = useLanguage();
  const isLocked = useSelector((state: State) => state.Order?.locks?.getMenusForFacility);
  const orderDraftLock = useSelector((state: State) => state.Order.locks.createOrderDraft);
  const menuType = useSelector((state: State) => state.Order.menusType);

  const foodFeedbackEnabled = useIsSetupOptionEnabled('foodFeedback', SERVICE.REVIEW);
  const { label } = useOrderTranslation(__filename);

  const cardRefs = useRef<CardRefs[]>([]);
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  const { data: stamps, isLoading: isLoadingStamps } = useGetLoyaltySchemesQuery({
    siteId,
    useErrorBoundary: false,
  });

  const { isKioskTitleBar, areSuggestionsEnabled } = orderConfig();

  const shareSiteIdentifier = useSetupOption(
    SETUP_OPTIONS.RETAIL.shareSiteIdentifier,
    SERVICE.FOOD_MENU
  ).value;

  const { currentGeoCode } = useGeoCode();

  const shareableLink: string = useMemo(() => {
    return computeShareableLink(
      window.location.href,
      shareSiteIdentifier,
      menuType,
      currentGeoCode,
      facilityId,
      menu,
      menuItem
    );
  }, [shareSiteIdentifier, menuType, currentGeoCode, facilityId, menu, menuItem]);

  const { shareOptionsModal, triggerShareModal } = useShareModal({ label });

  const handleShare = () => triggerShareModal(menuItem?.name as string, shareableLink);

  const isSuggestionsOnProductDetails = useIsSuggestionsEnabled(SuggestPlace.PRODUCT_DETAILS);

  const { logUserSteps } = useUserStepsInsightsLogging();

  const hideAllergens = useMemo(() => {
    if (!!menu) {
      const defaultFilters = getDefaultFiltersFromCache(site.id, menu.name, date);
      return defaultFilters?.filter_allergens_highlight
        ? defaultFilters.filter_allergens_highlight[HightlightAllergenFilterValues.Hide]
        : false;
    } else {
      return false;
    }
  }, [menu, date, site.id]);

  const { pathname } = useLocation();
  const isGuest = useSelector((state: State) => !!state.Core.user.isGuest);

  const isShareEnabled = isFFAppSharingEnabled() && shareSiteIdentifier;

  useEffect(() => {
    document.getElementById('main-content')?.scrollIntoView();
  }, [pathname]);

  useEffect(() => {
    if (!!facilityId) {
      logUserSteps({ event: UserSteps.ProductDetails, facilityId });
    }
  }, [facilityId, logUserSteps]);

  const refreshMenu = useCallback(() => {
    if (!isLocked && (!menuItem || !menu))
      dispatch(getMenus({ siteId: site.id, date, useCache: true, menuType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, facilityId, site.id, date, menuType]);

  useEffect(() => {
    refreshMenu();
  }, [refreshMenu]);

  const defaultPortion = useMemo(
    () => menuItem?.productPortions.find((portion) => portion.isDefault),
    [menuItem?.productPortions]
  );

  const { productPortion, addToFavorites, removeFromFavorites } = useProductPortion(
    menuItem?.menuItemId as number,
    defaultPortion?.uomId as number,
    site?.id
  );
  const { selectedPortion, selectPortion } = usePortion(menuItem, selectedCartItem);
  const [selectedModifiers, setSelectedModifiers] = useState<ModifierSelections[]>(
    selectedCartItem ? getModifiersFromCart(selectedCartItem.modifiers) : []
  );
  const [modifierErrors, setModifierErrors] = useState<ModifierErrors | undefined>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [liveMessage, setLiveMessage] = useState('');

  const handleStarActionClick = useCallback(() => {
    if (productPortion?.isFavorite) {
      removeFromFavorites();
      setLiveMessage(label('Ref: Removed from favorites'));
    } else {
      addToFavorites();
      setLiveMessage(label('Ref: Added to favorites'));
    }
  }, [addToFavorites, label, productPortion?.isFavorite, removeFromFavorites]);

  const {
    state: isLoyaltyInfoModalOpen,
    toggleOn: openLoyaltyInfoModal,
    toggleOff: closeLoyaltyInfoModal,
  } = useToggle(false);

  const { portionsFreeItemsInfo, isLoading: isLoadingLoyaltyStamps } = useLoyaltyReward(
    menuItem?.productPortions ?? [],
    parsedMenuId,
    isGuest
  );

  const selectedPortionFreeItemsInfo = portionsFreeItemsInfo.find(
    (item) =>
      item.portion.foodItemId === selectedPortion?.foodItemId &&
      item.portion.uomId === selectedPortion?.uomId
  );
  const selectedPortionNumberOfFreeItems = selectedPortionFreeItemsInfo?.numberOfFreeItems ?? 0;

  const [isScanOpen, setIsScanOpen] = useState<boolean>(false);

  const isRrMenu = useMemo(() => menu?.source === FacilityMenuSource.Rr, [menu?.source]);
  const isOrderableMenu = useMemo(() => menu?.isOrderable, [menu?.isOrderable]);

  const { suggestions, isLoading } = useFetchSuggestions(
    site?.id,
    date,
    menu?.id,
    isRrMenu,
    isOrderableMenu
  );

  const moments: string[] = [];

  menu?.menuItems.forEach((menuItem) => {
    if (!moments.find((x) => x === menuItem.dayPart) && menuItem.dayPart !== null) {
      moments.push(menuItem.dayPart);
    }
  });

  const momentBasedSuggestions = useMemo(
    () =>
      menuItem?.mealName
        ? suggestions.filter(
            (item) => item.mealName?.toLowerCase() === menuItem?.mealName?.toLowerCase()
          )
        : suggestions,
    [menuItem?.mealName, suggestions]
  );

  const { suggestionsLimitDesktop } = useCheckNoSuggestionsToDisplay(momentBasedSuggestions);

  const isPortionInCart = useMemo(
    () => (cart?.menuPortionItems ?? []).some((x) => x.foodItemId === selectedPortion?.foodItemId),
    [cart, selectedPortion]
  );

  function onPortionSelected(params: MultiCardSelectParams | SelectOption) {
    const foundPortion = menuItem?.productPortions.find((x) => x.portionId === params.value);
    selectPortion(foundPortion || null);
    setSelectedModifiers([]);
  }

  const onModifiersSelected = (modifierItems: ModifierSelections[]) => {
    setSelectedModifiers([...modifierItems]);
    if (isSubmitted) validate();
  };

  const validate = useCallback(() => {
    const errors: ModifierErrors = validateModifiers(
      selectedPortion?.modifiers,
      selectedModifiers,
      label
    );
    setModifierErrors(errors);
    return errors;
  }, [selectedModifiers, selectedPortion, label]);

  const handleValidation = useCallback(() => {
    const errors = validate();
    const isValid = Object.keys(errors).length === 0;

    if (!isValid) {
      const erroredElements = cardRefs.current.filter(({ id }) => errors[id]);
      const firstErrored = erroredElements.sort((a, b) => a.index - b.index)[0];

      if (firstErrored.element.scrollIntoView)
        firstErrored.element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsSubmitted(true);

    return isValid;
  }, [validate]);

  const handleReview = useCallback(() => {
    const productDetailsReviewPath = pagePaths.ReviewForm.replace(
      ':reviewType',
      PATH_REVIEW_TYPES.MENU_ITEM
    )
      .replace(':facilityId', facilityId)
      .replace(':menuId?', `${parsedMenuId}`)
      .replace(':foodItemId?', `${selectedPortion?.foodItemId}`);
    history.push({
      pathname: productDetailsReviewPath,
      search: `${new URLSearchParams({
        referer: window.location.pathname,
        redirectedFrom: REDIRECTED_PAGES.PRODUCT_DETAILS,
      })}`,
    });
  }, [history, facilityId, parsedMenuId, selectedPortion?.foodItemId]);

  useEffect(() => {
    const initialModifiersSelection = getInitialModifiers(selectedPortion?.modifiers);
    if (!selectedModifiers.length) setSelectedModifiers(initialModifiersSelection || []);
  }, [selectedModifiers.length, selectedPortion?.modifiers]);

  const selectedAllergens = useMemo(() => getAllergensFromCache() ?? [], []);

  const allergens = useMemo(
    () => getPortionAndSelectedModifiersAllergens(selectedPortion, selectedModifiers),
    [selectedModifiers, selectedPortion]
  );
  const warningAllergens = useMemo(
    () => filterBySelectedAllergens(allergens, selectedAllergens),
    [allergens, selectedAllergens]
  );
  const showAllergensWarning = warningAllergens.length > 0;

  const filteredSuggestions = getFilteredSuggestions(
    momentBasedSuggestions,
    selectedAllergens,
    hideAllergens
  );

  const suggestionsToDisplay = useMemo(
    () => [...filteredSuggestions]?.slice(0, suggestionsLimitDesktop),
    [filteredSuggestions, suggestionsLimitDesktop]
  );

  const menuItemsAvailableForScan = useMenuItemsAvailableForScan(
    suggestionsToDisplay,
    menuItem,
    menu?.menuItems
  ) as MenuItem[];

  const allergensChip = useMemo(() => {
    return buildAllergenChip({
      showAllergensWarning,
      label: label('Ref: Allergens'),
      ariaLabel: label('Ref: Presence of allergen'),
    });
  }, [showAllergensWarning, label]);

  const vegeChip = useMemo(
    () =>
      buildVegeChip({
        isVegan: selectedPortion?.isVegan,
        isVegetarian: selectedPortion?.isVegetarian,
        veganLabel: label('Ref: Vegan'),
        vegeterianLabel: label('Ref: Vegeterian'),
      }),
    [label, selectedPortion]
  );

  const isProvidingLoyaltyStamps = hasPortionProvidingLoyaltyStamps({
    productPortions: selectedPortion !== null ? [selectedPortion] : undefined,
    menuId: parsedMenuId,
    loyaltySchemes: stamps,
  });

  const isLoyaltyReward = hasPortionRedeemableAsRewardInLoyaltyScheme({
    productPortions: selectedPortion !== null ? [selectedPortion] : undefined,
    menuId: parsedMenuId,
    loyaltySchemes: stamps,
  });

  const loyaltyPointsForOrderingChip = useMemo(
    () =>
      !isKioskApp() &&
      buildLoyaltyPointsForOrderingChip({
        showLoyaltyPointsForOrderingChip: isProvidingLoyaltyStamps,
        label: label('Ref: Equation for stamps per item'),
      }),
    [label, isProvidingLoyaltyStamps]
  );

  const loyaltyRewardChip = useMemo(
    () =>
      !isKioskApp() &&
      buildLoyaltyRewardChip({
        showLoyaltyRewardChip: isLoyaltyReward,
        label: label('Ref: Loyalty Reward'),
        ariaLabel: label('Ref: Can be redeemed as a Reward in Loyalty Scheme'),
      }),
    [label, isLoyaltyReward]
  );

  useEffect(() => {
    // Module prefix from share link
    const sharedModuleName = pathname.split('/')[1];
    // Date from shared link
    const sharedDate = pathname.split('/')[5];
    // Menu item id from shared link
    const sharedMenuItemId = Number(pathname.split('/')[6]);

    // Check for current menu to have the same date as shared menu
    const isMenuSameDate = sharedDate === momentjs(menu?.date).format('YYYY-MM-DD');

    // Only if dates are the same we can check for a valid id
    const isInMenu = isMenuSameDate
      ? menu?.menuItems.find((item) => item.menuItemId === sharedMenuItemId)
      : true;

    if (!isInMenu && menu) {
      const newURL = getRedirectURL(sharedModuleName, facilityId, menu.id, sharedDate);
      history.replace(newURL);
    }
  }, [facilityId, history, menu, menuItem, pathname]);

  if (isLocked) return <LoadingPage />;
  const discountPrice = orderDraft?.promotionalDiscountForItems?.find(
    (item) => item.uomId === selectedPortion?.uomId
  );

  const alergensNotification = (
    <AllergensNotification
      portion={selectedPortion}
      label={label}
      selectedAllergens={selectedAllergens}
      selectedModifiers={selectedModifiers}
    />
  );

  const { totalPrice: unitPrice } = buildProductTotalPrice({
    portion: selectedPortion ?? undefined,
    selectedModifiers: selectedModifiers || [],
    languageCode,
    isoCode: (site.currency as ISiteCurrency).isoCode,
    productQuantity: 1,
  });

  const tags =
    allergensChip || vegeChip || loyaltyPointsForOrderingChip || loyaltyRewardChip ? (
      <div className={classNames(styles.tagsContainer)}>
        {allergensChip && (
          <Tag {...allergensChip} variant={TAG_VARIANT.CHIP} data-testid="allergens-chip" />
        )}
        {vegeChip && <Tag {...vegeChip} variant={TAG_VARIANT.CHIP} data-testid="vege-chip" />}
        {loyaltyPointsForOrderingChip && (
          <Tag
            {...loyaltyPointsForOrderingChip}
            variant={TAG_VARIANT.CHIP}
            data-testid="loyality-points-chip"
          />
        )}
        {loyaltyRewardChip && (
          <Tag
            {...loyaltyRewardChip}
            variant={TAG_VARIANT.CHIP}
            data-testid="loyality-reward-chip"
          />
        )}
      </div>
    ) : null;

  const header: ContentDetailsHeaderProps = {
    children: menu ? (
      <>
        {tags}
        {isOrderableMenu ? (
          <MenuTitleOrderable
            menuItem={menuItem}
            languageCode={languageCode}
            site={{ id: site.id, currency: site.currency as SiteCurrency }}
            calculatedPrice={unitPrice}
            discountPrice={unitPrice - (discountPrice?.discount as number)}
            redeemableQuantity={selectedPortionNumberOfFreeItems}
            label={label}
            isLoading={isLoadingLoyaltyStamps}
            data-testid="menuItem-tile-orderable"
          />
        ) : (
          <MenuTitleViewable
            menuItem={menuItem}
            selectedPortion={selectedPortion}
            languageCode={languageCode}
            label={label}
            site={{ id: site.id, currency: site.currency as SiteCurrency }}
            data-testid="menuItem-tile-viewable"
          />
        )}
      </>
    ) : null,
    imageNode: <MenuImage menuItem={menuItem} data-testid="menuItem-image" />,
  };

  const mainColumn = menu ? (
    <>
      <MenuDescription menuItem={menuItem} data-testid="menuItem-description" />
      {(selectedPortion?.alcoholContent ?? 0) > 0 && (
        <Notification
          look={NOTIFICATION_LOOK.DETAILS}
          title={label('Ref: age verification required on delivery')}
          children={label('Ref: alcohol content notification')}
          data-testid="portion-alcohol-notification"
        />
      )}
      <MenuNutritions
        portion={selectedPortion}
        label={label}
        selectedModifiers={selectedModifiers}
        data-testid="portion-nutritions"
      />
      <MenuModifiers
        menuId={parsedMenuId}
        menuItemId={menuItem?.menuItemId as number}
        portionId={selectedPortion?.portionId as string}
        modifiers={selectedPortion?.modifiers}
        onChange={onModifiersSelected}
        selectedModifiers={selectedModifiers}
        modifierRefs={cardRefs}
        modifierErrors={modifierErrors}
        languageCode={languageCode}
        isoCode={(site.currency as SiteCurrency).isoCode}
        selectedAllergens={selectedAllergens}
        data-testid="portion-modifiers"
      />
      <Allergens
        portion={selectedPortion}
        label={label}
        selectedAllergens={selectedAllergens ?? []}
        selectedModifiers={selectedModifiers}
        data-testid="portion-allergens"
      />
      <div className={styles.twoCardsInRow}>
        <MenuEmissions portion={selectedPortion} label={label} data-testid="portion-emissions" />
        <MenuAdditives portion={selectedPortion} label={label} data-testid="portion-additives" />
      </div>
      <MenuIngredients portion={selectedPortion} label={label} data-testid="portion-ingridients" />
      {areSuggestionsEnabled && isRrMenu && isOrderableMenu && isSuggestionsOnProductDetails ? (
        <SuggestionContainer
          id={'suggestion_product_details'}
          pageType={PageType.productDetails}
          isLoading={isLoading}
          suggestions={suggestionsToDisplay}
          date={date}
          menus={[menu]}
          highlightAllergens={!hideAllergens}
          selectedAllergens={selectedAllergens}
          onScanButtonClick={() => setIsScanOpen(true)}
          label={label}
          data-testid="suggestions-product-details"
        />
      ) : null}
    </>
  ) : null;

  const portionSizes =
    menuItem?.productPortions.map((portion) => {
      const portionFreeItemsInfo = portionsFreeItemsInfo.find(
        (item) =>
          item.portion.uomId === portion.uomId && item.portion.foodItemId === portion.foodItemId
      );
      const portionNumberOfFreeItems = portionFreeItemsInfo?.numberOfFreeItems ?? 0;

      return {
        value: portion.portionId,
        label: `${portion.portion ?? label('Ref: default')} ${portion.gramWeight}g ${portionPrice(
          portion,
          languageCode,
          (site.currency as SiteCurrency).isoCode,
          portionNumberOfFreeItems,
          label
        )}`,
        customLabel: (
          <>
            <b>{portion.portion ?? label('Ref: default')} </b>
            <span>
              {portion.gramWeight}g{' '}
              {isLoadingStamps || isLoadingLoyaltyStamps ? (
                <span className={classNames(styles.loading)} />
              ) : (
                portionPrice(
                  portion,
                  languageCode,
                  (site.currency as SiteCurrency).isoCode,
                  portionNumberOfFreeItems,
                  label
                )
              )}
            </span>
          </>
        ),
      };
    }) ?? [];

  const actions = menuItem && (
    <div className={styles.cartActions}>
      <Dropdown
        buttonLook={BUTTON_LOOK.SECONDARY}
        className={styles.dropdown}
        labelText="portions-dropdown"
        value={selectedPortion?.portionId}
        options={portionSizes}
        onChange={onPortionSelected}
        isDisabled={portionSizes.length === 1}
        hasCustomLabel
        openUp
      />
      {isOrderableMenu && (
        <>
          <MenuCartActions
            menuItem={menuItem}
            facilityId={facilityId}
            date={toLocalDate(date)}
            menuId={parsedMenuId}
            selectedPortion={selectedPortion ?? undefined}
            selectedModifiers={selectedModifiers}
            pageType={PageType.productDetails}
            handleValidation={handleValidation}
            cartMenuItemId={selectedCartItem?.id}
            disableIncrement={isScanAndGo && !isPortionInCart}
            disableChange={orderDraftLock}
            discountPrice={discountPrice?.discount}
            redeemableQuantity={selectedPortionNumberOfFreeItems}
            isLoading={isLoadingLoyaltyStamps}
          />
          {isScanAndGo && (
            <ProductScanner
              className={styles.scan}
              facilityId={facilityId}
              date={toLocalDate(date)}
              menuId={parsedMenuId}
              site={site}
              menuItems={menuItemsAvailableForScan}
              languageCode={languageCode}
              isScanOpen={isScanOpen}
              cartModificationDisabled={orderDraftLock}
              onDismiss={() => setIsScanOpen(false)}
            />
          )}
        </>
      )}
    </div>
  );

  const secondaryActions: JSX.Element[] = [];
  if (isRrMenu && !isKioskApp() && foodFeedbackEnabled)
    secondaryActions.push(
      <Button
        data-testid="product-details-review"
        look={BUTTON_LOOK.TERTIARY}
        onClick={handleReview}
        affix={CommentIcon}
        isColumn
        key="review"
      >
        {label('Ref: Review')}
      </Button>
    );

  if (isFFProductFavouriteEnabled() && !isGuest && !isKioskApp()) {
    secondaryActions.push(
      <Fragment key="favorite">
        <Button
          data-testid="product-details-favorite"
          look={BUTTON_LOOK.TERTIARY}
          onClick={handleStarActionClick}
          affix={productPortion?.isFavorite ? StarFilledIcon : StarOutlinedIcon}
          isColumn
          srOnlyText={
            productPortion?.isFavorite ? label('Ref: In favorites') : label('Ref: Not in favorites')
          }
        >
          {label('Favorite')}
        </Button>
        <p className="sr-only" aria-live="polite">
          {liveMessage}
        </p>
      </Fragment>
    );
  }

  if (isRrMenu && !isKioskApp() && isShareEnabled)
    secondaryActions.push(
      <Button
        data-testid="share-product-details-link"
        look={BUTTON_LOOK.TERTIARY}
        onClick={handleShare}
        affix={Share20Icon}
        key="share"
      >
        {label('Ref: Share')}
      </Button>
    );

  const sideActions =
    secondaryActions.length > 0 ? (
      <>
        {secondaryActions.length > 0 && (
          <SecondaryActions>{secondaryActions.map((x) => x)}</SecondaryActions>
        )}
      </>
    ) : undefined;

  const sideBottomSection = !isLoadingLoyaltyStamps &&
    stamps &&
    isProvidingLoyaltyStamps &&
    !isKioskApp() && (
      <LoyaltyCard
        label={label}
        loyaltySchemes={stamps}
        menuItem={menuItem}
        menuId={parsedMenuId}
        pageType={PageType.productDetails}
        modalOpen={isLoyaltyInfoModalOpen}
        openModal={openLoyaltyInfoModal}
        closeModal={closeLoyaltyInfoModal}
      />
    );

  return (
    <ContentDetailsPage.WithAdditionalSections
      title={label('Ref: Page title')}
      header={header}
      actions={actions}
      sideChildren={sideActions}
      notificationContent={alergensNotification}
      hideDefaultTitleBarWidgets={isKioskTitleBar}
      tabTitle={menuItem?.name}
      sideBottomSection={sideBottomSection}
    >
      {mainColumn}
      {shareOptionsModal}
    </ContentDetailsPage.WithAdditionalSections>
  );
};

export default ProductDetails;
