import React, { useEffect, useMemo, useState } from 'react';
import { ReactReduxContext } from 'react-redux';

import withSite from '../../../_deprecated/context/withSite';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { moduleId, pagePaths } from '../config';
import { getAll } from '../redux/actions';
import { Facility } from '../redux/actions.types';
import { WithFacilitiesComponentProps, WrappedComponentProps } from '../types/types';

const facilitiesFunctions = {
  getFacilityDetailsLink: (facilityId: string) =>
    pagePaths['FacilityDetails'].replace(':id', facilityId),
};

/**
 * @deprecated The HOC should not be used, used only in Orders module
 */
const withFacilities = (WrappedComponent: React.ComponentType<WrappedComponentProps>) => {
  return withSite(({ ...props }) => {
    return (
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <WithFacilitiesComponent
            {...props}
            site={props.site}
            store={store}
            wrappedComponent={WrappedComponent}
          />
        )}
      </ReactReduxContext.Consumer>
    );
  });
};

const WithFacilitiesComponent = ({
  site,
  store,
  wrappedComponent: WrappedComponent,
  ...props
}: WithFacilitiesComponentProps) => {
  const [facilitiesId, setFacilitiesId] = useState(store.getState()[moduleId]?.list?.[site?.id]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await store.dispatch(getAll({ siteId: site?.id }));
      if (!result.ok) return;

      setFacilitiesId(result.responseData.facilities.map((el: Facility) => el.id));
    };

    if (!facilitiesId) {
      fetchData();
    }
  }, [facilitiesId, site?.id, store]);

  const facilities = useMemo(
    () =>
      (facilitiesId || []).reduce((filteredFacilities: Facility[], candidateFacilityId: string) => {
        let facility = store.getState()[moduleId]?.data[candidateFacilityId];
        if (facility) filteredFacilities.push(facility);
        return filteredFacilities;
      }, []),
    [facilitiesId, store]
  );

  if (!facilitiesId) {
    return <LoadingPage />;
  }

  return (
    <WrappedComponent
      facilities={facilities}
      facilitiesFunctions={facilitiesFunctions}
      site={site}
      store={store}
      {...props}
    />
  );
};

export default withFacilities;
