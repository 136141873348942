import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { APP_DISPLAY_NAME } from '../../../../constants';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { SERVICE } from '../../../config';
import { useGetMappedFacilitiesQuery } from '../../../Review/api';
import { ISite } from '../../../Sites/types/sites.types';
import { useGetFacilityDetailsQuery } from '../../api/index';

import FacilityDetails from './FacilityDetails';

import { useTrackPageView } from '@/helpers/hooks/Analytics/useTrackPageView';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';

const siteId = '123';
jest.mock('../../../Review/api', () => ({
  ...jest.requireActual('../../../Review/api'),
  useGetMappedFacilitiesQuery: jest.fn(),
}));

jest.mock('@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled', () => ({
  useIsSetupOptionEnabled: jest.fn(),
}));

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () =>
    ({
      id: siteId,
      currency: {
        isoCode: 'EUR',
      },
    } as ISite),
}));

const mockSelector = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
}));

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useRouteMatch: () => ({
    url: '/id',
    params: { id: 1 },
  }),
}));

jest.mock('../../../config', () => {
  return {
    ...jest.requireActual('../../../config'),
    serviceTypes: {
      Facilities: {
        path: '/facilities',
        defaultModule: 'Facilities',
        label: 'Facilities',
      },
      'Food.Menus': {
        path: '/menus',
        defaultModule: 'Shop',
        label: 'Menus',
      },
      'Food.Menu': {
        path: '/menu',
        defaultModule: 'Menu',
        label: 'Menu',
      },
      'Food.Order': {
        path: '/order',
        excludeSubPaths: ['/history'],
        defaultModule: 'Order',
        label: 'Order',
      },
      Review: {
        path: '/review',
        defaultModule: 'Review',
        label: 'Feedback',
      },
      Stamps: {
        path: '/stamps',
        defaultModule: 'Stamps',
        label: 'FreeCoffee',
      },
      LoyaltyStamps: {
        path: '/loyaltystamps',
        defaultModule: 'LoyaltyStamps',
        label: 'Stamps',
      },
      Content: {
        path: '/content',
        defaultModule: 'Content',
        label: 'Content',
      },
      Events: {
        path: '/events',
        defaultModule: 'Events',
        label: 'Events',
      },
      Surveys: {
        path: '/surveys',
        defaultModule: 'Surveys',
        label: 'Surveys',
      },
      ServiceRequests: {
        path: '/service_request',
        defaultModule: 'ServiceRequest',
        label: 'Requests',
      },
      AccommodationRequest: {
        path: '/accommodation_request',
        defaultModule: 'AccommodationRequest',
        label: 'Accommodation Request',
      },
    },
    modulesConfig: {
      Facilities: {
        serviceType: 'Facilities',
      },
      Order: {
        serviceType: 'Food.Order',
      },
      Menus: {
        serviceType: 'Food.Menus',
      },
      Shop: {
        serviceType: 'Food.Menus',
      },
      Menu: {
        serviceType: 'Food.Menu',
      },
      Review: {
        serviceType: 'Review',
      },
      Stamps: {
        serviceType: 'Stamps',
      },
      LoyaltyStamps: {
        serviceType: 'LoyaltyStamps',
      },
      Content: {
        serviceType: 'Content',
      },
      Events: {
        serviceType: 'Events',
      },
      Surveys: {
        serviceType: 'Surveys',
      },
      ServiceRequest: {
        serviceType: 'ServiceRequests',
      },
      AccommodationRequest: {
        serviceType: 'AccommodationRequest',
      },
    },
  };
});

const facility = {
  id: '1',
  title: 'Mindful Dining',
  menuNote: 'Hi!',
  email: 'mindful@dining.com',
  phone: '111222555',
  openingHours: [
    { closeTime: '15:00', openTime: '11:00', isOpenAllDay: false, day: ['Monday', 'Tuesday'] },
  ],
  description: 'Eating healthy does not have to be about depriving yourself...',
  facilityType: { name: 'Food - Non-retail' },
};

const data = { [facility.id]: facility };
const facilitiesList = { [siteId]: [facility.id] };
const viewCounts = { [facility.id]: 10 };

const mockState = {
  Facilities: { data, list: facilitiesList, viewCounts },
  Shared: {
    language: {
      currentLanguageCode: 'en-US',
    },
    geographies: {
      list: [],
      defaultGeoCode: 'PT',
    },
  },
  Core: {
    access: {},
    services: {
      list: [
        {
          id: '1',
          name: SERVICE.REVIEW,
        },
        {
          id: '2',
          name: SERVICE.FOOD_ORDER,
        },
        {
          id: '3',
          name: SERVICE.FACILITY,
        },
      ],
    },
  },
};

jest.mock('../../api/index', () => ({
  ...jest.requireActual('../../api/index'),
  useGetFacilityDetailsQuery: jest.fn().mockReturnValue({
    data: facility,
    isLoading: false,
  }),
}));

jest.mock('@/helpers/hooks/Analytics/useTrackPageView', () => ({
  useTrackPageView: jest.fn(),
}));

const mockUseTrackPageView = useTrackPageView as jest.Mock;

describe('FacilityDetails', () => {
  beforeEach(() => {
    mockSelector.mockReturnValue(mockState);
  });
  const env = global.process.env;

  afterAll(() => cleanup());

  describe('on initial render', () => {
    let primaryButton: HTMLElement;

    beforeEach(async () => {
      (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(true);
      (useGetMappedFacilitiesQuery as jest.Mock).mockReturnValue({
        data: ['111'],
        isLoading: false,
      });
      (useGetFacilityDetailsQuery as jest.Mock).mockReturnValue({
        data: facility,
        isLoading: false,
      });
    });

    renderComponent(FacilityDetails, {}, mockState);

    it('should log page view to Piano Analytics', () => {
      expect(mockUseTrackPageView).toHaveBeenCalledTimes(1);
    });

    it('should display', () => {
      primaryButton = screen.getByTestId('button-action-primary');

      const openTime = facility.openingHours[0].openTime;
      const closeTime = facility.openingHours[0].closeTime;
      const days = facility.openingHours[0].day.join(', ');

      expect(screen.getAllByText(facility.title)).toBeTruthy();
      expect(screen.getByText(facility.menuNote)).toBeTruthy();
      expect(screen.getByText('Contact us')).toBeTruthy();
      expect(screen.getByText('Email')).toBeTruthy();
      expect(screen.getByText(facility.email)).toBeTruthy();
      expect(screen.getByText('Mobile Number')).toBeTruthy();
      expect(screen.getByText(facility.phone)).toBeTruthy();
      expect(screen.getByText('Opening hours')).toBeTruthy();
      expect(screen.getByText(days)).toBeTruthy();
      expect(screen.getByText(`${openTime}-${closeTime}`)).toBeTruthy();
      expect(screen.getByText('More')).toBeTruthy();
      expect(screen.getByText(facility.description)).toBeTruthy();
      expect(primaryButton).toBeTruthy();
      expect(screen.getByText('See the offering')).toBeTruthy();
    });
  });

  describe('on clicking primary button', () => {
    describe('when is MyVillage', () => {
      beforeAll(() => {
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE };
      });
      let primaryButton: HTMLElement;
      const history = createMemoryHistory();
      beforeEach(async () => {
        (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(true);
        (useGetMappedFacilitiesQuery as jest.Mock).mockReturnValue({
          data: ['111'],
          isLoading: false,
        });
        (useGetFacilityDetailsQuery as jest.Mock).mockReturnValue({
          data: facility,
          isLoading: false,
        });
      });
      renderComponent(FacilityDetails, {}, mockState, history);
      it("should redirect to facility's products list", () => {
        primaryButton = screen.getByTestId('button-action-primary');
        fireEvent.click(primaryButton);
        expect(history.location.pathname).toBe(`/menus/${facility.id}/products`);
      });
    });
    describe('when is MyWay', () => {
      beforeAll(() => {
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
      });
      let primaryButton: HTMLElement;
      const history = createMemoryHistory();
      beforeEach(async () => {
        (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(true);
        (useGetMappedFacilitiesQuery as jest.Mock).mockReturnValue({
          data: ['111'],
          isLoading: false,
        });
        (useGetFacilityDetailsQuery as jest.Mock).mockReturnValue({
          data: facility,
          isLoading: false,
        });
      });
      renderComponent(FacilityDetails, {}, mockState, history);
      it('should redirect to Menu module', () => {
        primaryButton = screen.getByTestId('button-action-primary');
        fireEvent.click(primaryButton);
        expect(history.location.pathname).toBe(`/menu`);
      });
    });
  });
});
