import withSite from '../../../../_deprecated/context/withSite';
import MiniWidget from '../../../../components/organisms/MiniWidget';
import { orderConfig } from '../../config';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';

import { PromotionsMiniWidgetProps } from './PromotionsMiniWidget.types';
import { usePromotionsMiniWidget } from './usePromotionsMiniWidget';

const PromotionsMiniWidget = ({ site }: PromotionsMiniWidgetProps) => {
  const { label } = useOrderTranslation(__filename);
  const { promoTiles = [] } = usePromotionsMiniWidget(site, label);
  const { arePromotionsEnabled } = orderConfig();
  if (promoTiles?.length === 0 || !arePromotionsEnabled) {
    return null;
  }
  return (
    <MiniWidget
      id="PromotionsMiniWidget"
      data-testid="promotions-mini-widget"
      title={label('Ref: Title')}
    >
      {promoTiles}
    </MiniWidget>
  );
};

export default withSite(PromotionsMiniWidget);
