import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { WomanIllustration as TopImage } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import { pagePaths } from '../../config';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

import { AppUpdateFlags } from './AppUpdatePage.types';

import { State } from '@/types/state.types';

import styles from './AppUpdatePage.module.css';

const AppUpdatePage = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);

  const dispatch = useDispatch();
  const appInfo = useSelector((state: State) => state.Core.appVersionInfo);

  const unblock = useRef<() => void>();

  useEffect(() => {
    unblock.current = history.block() as unknown as () => void;

    return () => {
      if (unblock.current) unblock.current();
    };
  }, [history]);

  useEffect(() => {
    if (!appInfo || !appInfo?.type) {
      if (unblock.current) unblock.current();
      history.push(pagePaths.Home);
    }
  }, [appInfo, history]);

  return (
    <Container.Centered>
      <Column.Main>
        <div className={styles.mainContainer}>
          <Title
            tag={TITLE_TAG.H2}
            size={TITLE_SIZE.HEADLINES}
            className={classNames('mb-M', styles.titleCentered)}
          >
            {appInfo?.title ?? ''}
          </Title>
          <Title size={TITLE_SIZE.BODYMBOLD}>{appInfo?.message ?? ''}</Title>
          <ActionsBar className={styles.actionButton}>
            {appInfo?.type === AppUpdateFlags.FORCE ? (
              <Button
                data-testid="app-update-go-to-store"
                onClick={() => window.open(appInfo?.storeUrl)}
              >
                {label('Ref: GoToStore')}
              </Button>
            ) : (
              <>
                <Button
                  data-testid="app-update-go-to-store-continue"
                  onClick={() => {
                    if (unblock.current) unblock.current();
                    dispatch({ type: 'GET_APP_VERSION_FAILURE' });
                    history.push(pagePaths.Home);
                  }}
                >
                  {label('Continue')}
                </Button>
                <Button
                  data-testid="app-update-go-to-store"
                  look={BUTTON_LOOK.SECONDARY}
                  onClick={() => window.open(appInfo?.storeUrl)}
                >
                  {label('Ref: GoToStore')}
                </Button>
              </>
            )}
          </ActionsBar>
        </div>
      </Column.Main>
      <Column.Complementary>
        <TopImage />
      </Column.Complementary>
    </Container.Centered>
  );
};

export default AppUpdatePage;
