import withSite from '../../../_deprecated/context/withSite';
import { BarcodeIcon } from '../../../assets/icons';
import Button from '../../../components/atoms/Button';
import { SIZE } from '../../../constants';
import { PageType } from '../types/menuCartActions.types';
import { Cart, MenuItem } from '../types/orderState.types';

import MenuCartActions from './MenuCartActions';

import { toLocalDate } from '@/helpers/dateTime';

const ProductCornerAction = ({
  menuItem,
  facilityId,
  date,
  menuId,
  saveScrollPosition,
  isScanAndGo,
  cart,
  scanButtonLabel,
  onScanButtonClick,
  onAddFirstItemToCart,
  onAddOrQuantityButtonClick,
  isSuggestion = false,
  pageType,
}: {
  menuItem: MenuItem;
  facilityId: string;
  date: string;
  menuId: number;
  saveScrollPosition?: () => void;
  isScanAndGo: boolean;
  cart?: Cart;
  scanButtonLabel: string;
  onScanButtonClick: () => void;
  onAddFirstItemToCart?: () => void;
  onAddOrQuantityButtonClick?: () => void;
  isSuggestion?: boolean;
  pageType: PageType;
}) => {
  const isMenuItemInCart = cart?.menuPortionItems?.some(
    (x) => x.menuItemId === menuItem?.menuItemId
  );

  if (!isScanAndGo || isMenuItemInCart) {
    return (
      <MenuCartActions
        menuItem={menuItem}
        facilityId={facilityId}
        date={toLocalDate(date)}
        menuId={menuId}
        saveScrollPosition={saveScrollPosition}
        onAddFirstItemToCart={onAddFirstItemToCart}
        onAddOrQuantityButtonClick={onAddOrQuantityButtonClick}
        isSuggestions={isSuggestion}
        pageType={pageType}
      />
    );
  } else if (isScanAndGo) {
    return (
      <Button
        look="primary"
        affix={BarcodeIcon}
        size={SIZE.SMALL}
        onClick={onScanButtonClick}
        data-testid={`product-corner-actions-scan-and-go-${
          menuItem?.menuItemId ?? 'no-menu-item-id'
        }`}
      >
        {scanButtonLabel}
      </Button>
    );
  }

  return null;
};

export default withSite(ProductCornerAction);
