import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { QRCodeReaderResponse } from '../../../components/molecules/QrCode/reader.types';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { Result } from '../../../types/common.types';
import { State } from '../../../types/state.types';
import { useGetPublicSitesQuery } from '../../Sites/api/api';
import LocationListPage from '../../Sites/components/LocationListPage/LocationListPage';
import { ISite } from '../../Sites/types/sites.types';
import { getSiteByCode, updateSiteAndContract } from '../actions';
import { pagePaths } from '../config';
import { useCoreTranslation } from '../hooks/useCoreTranslation';
import { ISiteWithContract } from '../types/accountOnboardingLocation.types';

export enum CodeErrors {
  UNAUTHORIZED = 'Ref: Unauthorized error',
  FORBIDDEN = 'Ref: Forbidden',
  NOT_FOUND = 'Ref: Not found',
  GENERIC_ERROR = 'Ref: Generic error',
}

const AccountOnboardingLocation = () => {
  const history = useHistory();

  const {
    locks: { getSiteByCode: isGetSiteLocked, onboarding: isOnboarding },
    user,
  } = useSelector((state: State) => state.Core);

  const contractId = user?.contract?.id;

  const dispatch = useDispatch();

  const [codeError, setCodeError] = useState<CodeErrors | undefined>();
  const { data: sites = [], isFetching: isSitesLocked } = useGetPublicSitesQuery({});

  const checkIfOnboarded = useCallback(() => {
    if (!!contractId && !isOnboarding) history.replace(pagePaths.Home);
  }, [history, contractId, isOnboarding]);

  const { label } = useCoreTranslation(__filename);

  const siteIdFromShare = localStorage.getItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);

  useEffect(() => {
    if (siteIdFromShare) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);
      history.replace(`${pagePaths.Share.replace(':siteCode', siteIdFromShare)}`);
    }
  }, [history, siteIdFromShare]);

  useEffect(() => {
    checkIfOnboarded();
  }, [checkIfOnboarded]);

  const handleSiteSelection = useCallback(
    (site: ISite) => {
      if (site.id) {
        dispatch(updateSiteAndContract({ siteId: site.id }));
      }
    },
    [dispatch]
  );

  const handleValidateCode = useCallback(
    async (siteCode: string, closeModal: Function) => {
      const result: Result<ISiteWithContract> = await dispatch(getSiteByCode({ code: siteCode }));
      const { responseStatus, responseData } = result;

      switch (responseStatus) {
        case 200:
          closeModal();
          await dispatch(updateSiteAndContract({ siteId: responseData.site.id }));
          break;
        case 401:
          setCodeError(CodeErrors.UNAUTHORIZED);
          break;
        case 403:
          setCodeError(CodeErrors.FORBIDDEN);
          break;
        case 404:
          setCodeError(CodeErrors.NOT_FOUND);
          break;
        default:
          setCodeError(CodeErrors.GENERIC_ERROR);
      }
    },
    [dispatch]
  );

  const handleScanCode = useCallback(
    async ({ response }: QRCodeReaderResponse, openErrorModal: () => void) => {
      if (response) {
        const result: Result<ISiteWithContract> = await getSiteByCode({ code: response });
        const { responseStatus, responseData } = result;

        switch (responseStatus) {
          case 200:
            await updateSiteAndContract({ siteId: responseData.site.id });
            break;
          case 401:
            setCodeError(CodeErrors.UNAUTHORIZED);
            openErrorModal();
            break;
          case 403:
            setCodeError(CodeErrors.FORBIDDEN);
            openErrorModal();
            break;
          case 404:
            setCodeError(CodeErrors.NOT_FOUND);
            openErrorModal();
            break;
          default:
            setCodeError(CodeErrors.GENERIC_ERROR);
        }
      }
    },
    []
  );

  if (isSitesLocked || isGetSiteLocked || isOnboarding || siteIdFromShare) {
    return <LoadingPage />;
  }

  return (
    <LocationListPage
      withNavBar={false}
      hideAllWidgets={true}
      title={label('Ref: Page title')}
      sites={sites}
      siteCodeError={codeError}
      pagePaths={pagePaths}
      label={label}
      layoutOptions={{ withStepper: true, withNavBar: false }}
      isSiteCodeLocked={isGetSiteLocked}
      onSiteSelection={handleSiteSelection}
      onValidateSiteCode={handleValidateCode}
      onScanSiteCode={handleScanCode}
      handleCodeError={setCodeError}
      enableUserLocation
    />
  );
};

export default AccountOnboardingLocation;
