import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Accordion from '../../../components/atoms/Accordion/Accordion';
import ImageWithFallback from '../../../components/atoms/ImageWithFallback/ImageWithFallback';
import MultiCardSelect from '../../../components/atoms/MultiCardSelect/MultiCardSelect';
import { MultiCardSelectParams } from '../../../components/atoms/MultiCardSelect/MultiCardSelect.types';
import { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title';
import Card from '../../../components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '../../../components/molecules/Notification';
import List from '../../../components/organisms/List/List';
import { ListItemProps } from '../../../components/organisms/List/List.types';
import { isValidHttpUrl } from '../../../helpers/urlValidator';
import { isSmallViewport } from '../../../helpers/windowSize';
import { TranslationProps } from '../../../localization/localization.types';
import { emissionsCarTripFactor, menuModuleId, menuModulePath, moduleId } from '../config';
import {
  buildAllergenListFromModifiers,
  buildAllergenList,
  getPortionAndSelectedModifiersAllergens,
  filterBySelectedAllergens,
} from '../helpers/allergens.helper';
import { calculateNutritionSum } from '../helpers/nutritions.helper';
import {
  buildAdditives,
  calculateDivisor,
  getCompleteNutritionList,
  getModifierDisplayText,
} from '../helpers/productDetails.helper';
import { MenuType } from '../types/menuSelector.types';
import { MenuModifiersProps } from '../types/modifiers.types';
import { AllergenType, FacilityMenu, MenuItem, ProductPortion } from '../types/orderState.types';
import { ModifierSelections } from '../types/productDetails.types';

import ModifierCard from './ModifierCard/ModifierCard';
import { getProductIllustration } from './ProductsList/productList.helper';

import { formatDateAsIS08601 } from '@/helpers/dateTime';
import { getRedirectionPathWithSiteIdentifier } from '@/modules/Core/components/ModuleRedirectPage/ModuleRedirectPage.helper';

import styles from './ProductDetails.module.css';

import { DefaultComponentWithoutChildrenPropsV2 } from 'src/types';

export const DEFAULT_NUTRITION_CALCULATION = 'DefaultNutritionCalculation';
export const defaultNutritionValue = 100;

type MenuImageProps = {
  menuItem: MenuItem | undefined;
} & DefaultComponentWithoutChildrenPropsV2;

export const MenuImage = ({ menuItem, 'data-testid': dataTestId }: MenuImageProps) => {
  const imgInfo =
    menuItem?.detailsImage && isValidHttpUrl(menuItem.detailsImage)
      ? {
          src: menuItem.detailsImage,
          alt: menuItem.name,
        }
      : undefined;
  return (
    <div className={classNames(styles.imgContent)} data-testid={dataTestId}>
      <ImageWithFallback
        data-testid="product-details"
        imgInfo={imgInfo}
        imgElement={getProductIllustration(menuItem?.genericCategory as string)}
      />
    </div>
  );
};
export const MenuDescription = ({
  menuItem,
  'data-testid': dataTestId,
}: { menuItem: MenuItem | undefined } & DefaultComponentWithoutChildrenPropsV2) =>
  menuItem?.description ? (
    <p className={styles.menuDescription} data-testid={dataTestId}>
      {menuItem.description}
    </p>
  ) : null;

type MenuNutritionsProps = {
  portion: ProductPortion | null;
  selectedModifiers: ModifierSelections[];
} & DefaultComponentWithoutChildrenPropsV2 &
  TranslationProps;
export const MenuNutritions = ({
  portion,
  label,
  selectedModifiers,
  'data-testid': dataTestId,
}: MenuNutritionsProps) => {
  const [nutritionCalculation, setNutritionCalculation] = useState(DEFAULT_NUTRITION_CALCULATION);

  const [nutritionDivisor, setNutritionDivisor] = useState<number | undefined>(
    calculateDivisor(portion?.gramWeight)
  );

  useEffect(() => setNutritionCalculation(DEFAULT_NUTRITION_CALCULATION), [portion]);

  const onNutritionCalculationSelected = (params: MultiCardSelectParams) => {
    if (!!params.value && !!portion?.gramWeight) {
      setNutritionCalculation(params.value);
      const portionWeight = Number.parseInt(portion.gramWeight, 10);
      if (portionWeight !== 0) {
        const value =
          params.value === DEFAULT_NUTRITION_CALCULATION
            ? defaultNutritionValue
            : Number.parseInt(params.value, 10);
        setNutritionDivisor(value / portionWeight);
      } else {
        setNutritionDivisor(0);
      }
    } else {
      setNutritionDivisor(undefined);
    }
  };

  const modifiersNutritionsSum = calculateNutritionSum(selectedModifiers, portion?.modifiers);
  const nutritionList =
    portion && !!nutritionDivisor ? (
      <>
        <MultiCardSelect
          data-testid="product-details-portions"
          options={[
            {
              label: label('Ref: Per 100g'),
              value: DEFAULT_NUTRITION_CALCULATION,
              'data-testid': 'per-100-g',
            },
            {
              label: `(${portion.gramWeight}g)`,
              value: portion.gramWeight,
              icon: (
                <p className={classNames(styles.portionSizeWrapper)}>
                  <span className={classNames(styles.portionSize)}>{portion.portion}</span>
                </p>
              ),
              'data-testid': 'per-portion',
            },
          ]}
          isMultiple={false}
          onChange={onNutritionCalculationSelected}
          label={label}
          values={[nutritionCalculation]}
          isBoldLabel={true}
          preventUnselect={true}
          columns={2}
        />
        <List
          data-testid="product-details-nutritions-list"
          items={getCompleteNutritionList(portion, label, modifiersNutritionsSum, nutritionDivisor)}
          twoColumn
        />
      </>
    ) : null;

  return nutritionList ? (
    <Card className="inlineContainer" data-testid={dataTestId}>
      <Accordion
        defaultOpenedSections={isSmallViewport() ? [] : ['nutrition']}
        titleCustomClass={classNames(styles.nutritionTitle)}
        data-testid="productDetails-nutritions"
        sections={[
          {
            id: 'nutrition',
            title: label('Ref: Nutrition'),
            'data-testid': `productDetails-nutritions-${portion?.uomId}`,
            content: (
              <div className={classNames(styles.nutritionWrapper)}>
                <p className={classNames(styles.nutritionTopText)}>
                  {label('Ref: Nutrition adults')}
                </p>
                {nutritionList}
              </div>
            ),
          },
        ]}
      />
    </Card>
  ) : null;
};

type MenuAdditivesProps = {
  portion: ProductPortion | null;
} & DefaultComponentWithoutChildrenPropsV2 &
  TranslationProps;

export const MenuAdditives = ({
  portion,
  label,
  'data-testid': dataTestId,
}: MenuAdditivesProps) => {
  const additivesList = portion ? buildAdditives(portion, label) : null;

  return additivesList ? (
    <Card
      title={{
        tag: TITLE_TAG.H5,
        children: label('Ref: Additives'),
        size: TITLE_SIZE.HEADLINEXS,
      }}
      data-testid={dataTestId}
    >
      <p className={classNames(styles.additivesWrapper)}>{additivesList}</p>
    </Card>
  ) : null;
};

type AllergensProps = {
  portion: ProductPortion | null;
  selectedAllergens: AllergenType[];
  selectedModifiers: ModifierSelections[];
} & DefaultComponentWithoutChildrenPropsV2 &
  TranslationProps;

export const Allergens = ({
  portion,
  label,
  selectedAllergens,
  selectedModifiers,
  'data-testid': dataTestId,
}: AllergensProps) => {
  const allergensListFromMenu = portion
    ? buildAllergenList(portion.allergens, selectedAllergens, label('Ref: Allergen selected'))
    : null;

  const allergesListFromModifiers = selectedModifiers
    ? buildAllergenListFromModifiers(
        portion,
        selectedModifiers,
        selectedAllergens,
        label('Ref: Allergen selected')
      )
    : null;

  return (
    <>
      {allergensListFromMenu?.length || allergesListFromModifiers?.length ? (
        <Card
          title={{
            tag: TITLE_TAG.H5,
            children: label('Ref: Allergens'),
            size: TITLE_SIZE.HEADLINEXS,
          }}
          className="inlineContainer"
          data-testid={dataTestId}
        >
          {allergensListFromMenu?.length ? (
            <div className={styles.modifierSection}>
              <p className={styles.allergenText}>{label('Ref: Always there')}</p>
              <List data-testid="allergens-list" items={allergensListFromMenu} twoColumn />
            </div>
          ) : null}
          {allergesListFromModifiers?.length ? (
            <div className={styles.modifierSection}>
              <div className={styles.allergenText}>{label('Ref: Based on customization')}</div>
              <List
                data-testid="allergens-modifiers-list"
                items={allergesListFromModifiers}
                twoColumn
              />
            </div>
          ) : null}
        </Card>
      ) : null}
    </>
  );
};

type AllergensNotificationProps = {
  portion: ProductPortion | null;
  selectedAllergens: AllergenType[];
  selectedModifiers: ModifierSelections[];
} & TranslationProps;

export const AllergensNotification = ({
  portion,
  label,
  selectedAllergens,
  selectedModifiers,
}: AllergensNotificationProps) => {
  const allergens = getPortionAndSelectedModifiersAllergens(portion, selectedModifiers);
  const warningAllergens = filterBySelectedAllergens(allergens, selectedAllergens);

  return warningAllergens?.length ? (
    <Notification
      look={NOTIFICATION_LOOK.ERROR}
      title={`${warningAllergens?.length} ${label('Ref: Allergens selected')}${warningAllergens
        ?.map((x) => x.name)
        .join(', ')}`}
      data-testid="allergens-warning"
    />
  ) : (
    <></>
  );
};

type MenuIngredientsProps = {
  portion: ProductPortion | null;
} & DefaultComponentWithoutChildrenPropsV2 &
  TranslationProps;
export const MenuIngredients = ({
  portion,
  label,
  'data-testid': dataTestId,
}: MenuIngredientsProps) =>
  portion?.ingredients ? (
    <Card
      title={{
        tag: TITLE_TAG.H5,
        children: label('Ref: Ingredients'),
        size: TITLE_SIZE.HEADLINEXS,
      }}
      data-testid={dataTestId}
    >
      <p className={classNames(styles.ingredientsWrapper)}>{portion.ingredients}</p>
    </Card>
  ) : null;

const calculateCarTripEmission = (portion: ProductPortion) =>
  Math.round(+portion?.co2Value.replace(/\D/g, '') / emissionsCarTripFactor / 10) / 100;

type MenuEmissionsProps = {
  portion: ProductPortion | null;
} & DefaultComponentWithoutChildrenPropsV2 &
  TranslationProps;

export const MenuEmissions = ({ portion, label, 'data-testid': dataTestId }: MenuEmissionsProps) =>
  portion?.co2Value && portion?.co2Value !== '0' ? (
    <Card
      title={{ tag: TITLE_TAG.H5, children: label('Ref: Emission'), size: TITLE_SIZE.HEADLINEXS }}
      data-testid={dataTestId}
    >
      {label('Ref: This product generates')}{' '}
      <span className={styles.emissionBolded}>
        {Math.round(+portion?.co2Value / 10) / 100}kg {label('Ref: of CO2')}
      </span>{' '}
      {label('Ref: emissions, which equals a')}
      <span className={styles.emissionBolded}>
        {' '}
        {calculateCarTripEmission(portion)}
        km {label('Ref: car trip')}
      </span>
    </Card>
  ) : null;

export const MenuModifiers = ({
  modifiers,
  selectedModifiers,
  selectedAllergens,
  modifierRefs,
  modifierErrors,
  onChange,
  languageCode,
  isoCode,
  'data-testid': dataTestId,
}: MenuModifiersProps) => {
  const handleChange = (
    modifierId: number,
    modifierName: string,
    selectedItems: ListItemProps[],
    changedItemId: string
  ) => {
    const changedModifierIndex = selectedModifiers.findIndex(
      (mod) => mod.modifierId === modifierId
    );
    const selectedModifier = selectedModifiers.find(({ modifierId: id }) => id === modifierId);

    const displayText = selectedItems?.length
      ? getModifierDisplayText({
          modifierName,
          selectedItems: selectedItems.map((element) => {
            const modifierItemName = element.label ? element.label.toString() : '';
            return { name: modifierItemName, quantity: element.quantity ?? 0 };
          }),
        })
      : '';

    const selectedItemIds = selectedItems.map(({ id }) => id);
    const itemIds = [...new Set([...(selectedModifier?.itemIds || []), changedItemId])].filter(
      (value) => selectedItemIds.includes(value)
    );
    const selectedModifierData = {
      modifierId: modifierId,
      displayText,
      itemIds,
      itemQuantities: selectedItems.reduce(
        (acc, { id, quantity = 1 }) => ({ [id]: quantity, ...acc }),
        {}
      ),
    };
    if (changedModifierIndex >= 0) {
      selectedModifiers[changedModifierIndex] = selectedModifierData;
    } else {
      selectedModifiers.push(selectedModifierData);
    }
    onChange(selectedModifiers);
  };

  if (!modifiers?.length) return <></>;

  return (
    <div className={styles.modifiersWrapper} data-testid={dataTestId}>
      {modifiers?.map((modifier, i) => {
        return (
          <div
            key={modifier.id}
            ref={(el) => {
              if (el) modifierRefs.current[i] = { index: i, id: modifier.id, element: el };
            }}
          >
            <ModifierCard
              key={`modifier-card-${modifier.id}`}
              modifier={modifier}
              handleChange={handleChange}
              isoCode={isoCode}
              languageCode={languageCode}
              selectedModifiers={selectedModifiers}
              modifierErrors={modifierErrors}
              selectedAllergens={selectedAllergens}
              data-testid={`modifier-card-${modifier.id}`}
            />
          </div>
        );
      })}
    </div>
  );
};

export const computeShareableLink = (
  url: string,
  shareSiteIdentifier: string | null,
  menuType: MenuType,
  currentGeoCode: string,
  facilityId: string,
  menu: FacilityMenu | undefined,
  menuItem: MenuItem | undefined
) => {
  let link = '';
  if (shareSiteIdentifier) {
    const isMenuModule =
      menuType === MenuType.NonOrderable ||
      (menuType === MenuType.All && url.includes(`${menuModulePath}/`));

    const routeParams = {
      facilityId,
      menuId: menu?.id?.toString() ?? 'no-menu',
      date: formatDateAsIS08601(new Date(menu?.date ?? 'no-date')),
      id: menuItem?.menuItemId?.toString() ?? 'no-menu-item',
      cartItem: 'no-cart',
    };

    const redirectionPathForProductDetails = getRedirectionPathWithSiteIdentifier(
      isMenuModule ? menuModuleId : moduleId,
      shareSiteIdentifier,
      currentGeoCode,
      routeParams
    );

    link = redirectionPathForProductDetails;
  }
  return link;
};

export function getRedirectURL(prefix: string, facilityId: string, menuId: number, date: string) {
  const newURL = `/${prefix}/${facilityId}/${menuId}/${date}/products`;

  return newURL;
}
