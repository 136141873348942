import { ListItemProps } from '../../../components/organisms/List/List.types';
import { TranslationProps } from '../../../localization/localization.types';
import { setupOptionReturnType } from '../../../types/common.types';
import { ISite } from '../../Sites/types/sites.types';

import { PageType } from './menuCartActions.types';
import {
  FacilityMenu,
  CartMenuItem,
  PromotionalDiscountForItem,
  RedeemedFoodItem,
} from './orderState.types';

export interface PaymentSetupOptions {
  merchantIdSetupOption: setupOptionReturnType;
  paymentOnPickupSetupOption: setupOptionReturnType;
  disableContinueFlowFromKioskOnMobileSetupOption: setupOptionReturnType;
}

export type PickupInformationFormProps = {
  menu: FacilityMenu;
  paymentOptions: ListItemProps[];
  setPaymentMethod: (id: string) => void;
  isGuestOrdering?: boolean;
} & TranslationProps;

export enum DeliveryOptionType {
  None = 'None',
  PickupOption = 'PickupOption',
  DecisionTreeDelivery = 'DecisionTreeDelivery',
  OpenAddressDelivery = 'OpenAddressDelivery',
  DineIn = 'DineIn',
  ScanAndGo = 'ScanAndGo',
  AiFi = 'AiFi',
  Delivery = 'Delivery',
}

export type CartItemTileProps = {
  date: string;
  pageType: PageType;
  site: ISite;
  promotionalDiscounts?: PromotionalDiscountForItem[];
  redeemedFoodItems?: RedeemedFoodItem[];
  itemModifierIds?: number[];
  languageCode: string;
  menu: FacilityMenu;
  isLoyaltyReward: boolean;
  isProvidingLoyaltyStamps: boolean;
  isKiosk: boolean;
} & CartMenuItem;

export enum PaymentModality {
  FreeOfCharge = 'FreeOfCharge',
  PayNow = 'PayNow',
  PayOnPickup = 'PayOnPickup',
  ChargeToMyDepartment = 'ChargeToMyDepartment',
}

export enum OrderType {
  Normal = 'Normal',
  ConferenceCatering = 'ConferenceCatering',
}

export type PickupInfoUpdate =
  | {
      [x: string]: any;
    }
  | undefined;
