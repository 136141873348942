import { TranslationProps } from '../../../../localization/localization.types';
import {
  buildAllergens,
  getDishes,
} from '../../../Order/components/ProductsList/productListFilters.helper';
import { defaultValues } from '../../../Order/config';
import { Allergen, AllergenType } from '../../../Order/types/orderState.types';
import { HightlightAllergenFilterValues } from '../../../Order/types/productList.types';
import { DietaryType, FiltersModel } from '../../api/account/accountApi.types';

import {
  Filter,
  FilterPosition,
  FilterType,
  Filtering,
} from '@/components/atoms/Filters/Filters.types';
import { isKiosk } from '@/helpers/misc';
import { isFFProductFavouriteEnabled } from '@/modules/Order/helpers/feature.flags.helper';

export const mapResponseToFilters = (savedFilters: FiltersModel): Filtering => {
  let allergens: Record<string, boolean> = {};
  if (!!savedFilters.selectedAllergens) {
    savedFilters.selectedAllergens.forEach((element: AllergenType) => (allergens[element] = true));
  }

  let dietaries: Record<string, boolean> = {};
  if (!!savedFilters.selectedDietaries) {
    savedFilters.selectedDietaries.forEach(
      (element: DietaryType) => (dietaries[element.toLowerCase()] = true)
    );
  }

  return {
    filter_allergens: allergens,
    filter_dishes: dietaries,
    filter_calories: !!savedFilters.maxCalories
      ? { [savedFilters.maxCalories.toString()]: true }
      : {},
    filter_allergens_highlight: savedFilters.hideFilteredDishes
      ? { hide: true }
      : { highlight: true },
    filter_favorites: savedFilters.displayFavoritesOnly
      ? { favorites: true }
      : { favorites: false },
  };
};
export const mapFilteringToSaveModel = (filtering: Filtering) => {
  return {
    selectedAllergens: !!filtering['filter_allergens']
      ? Object.entries(filtering['filter_allergens'])
          .filter((item) => item[1] === true)
          .map((x) => x[0] as AllergenType)
      : null,
    selectedDietaries: !!filtering['filter_dishes']
      ? Object.entries(filtering['filter_dishes'])
          .filter((item) => item[1] === true)
          .map((x) => x[0] as DietaryType)
      : null,
    maxCalories: !!filtering['filter_calories']
      ? Number(Object.keys(filtering['filter_calories'])[0])
      : null,
    hideFilteredDishes: !!filtering['filter_allergens_highlight']
      ? Object.keys(filtering['filter_allergens_highlight']).includes('hide')
      : false,
    displayFavoritesOnly: !!filtering['filter_favorites']
      ? Object.values(filtering['filter_favorites'])[0]
      : false,
  };
};
export const definePreferenceFilters = (
  label: TranslationProps['label'],
  allergens: Allergen[],
  isGuest: boolean
): Filter[] => {
  const filters: Filter[] = [
    {
      id: 'filter_dishes',
      position: FilterPosition.ALWAYS_IN_MODAL,
      name: label('Ref: filter label: dishes', { textTransform: 'capitalize' }),
      displayType: FilterType.MULTIPLE_SELECT,
      options: getDishes(label),
      isMultipleSelect: true,
      multiple: true,
      columns: 3,
      seeMoreLabel: label('Ref: see more', { textTransform: 'capitalize' }),
      seeLessLabel: label('Ref: see less', { textTransform: 'capitalize' }),
    },
    {
      id: 'filter_calories',
      position: FilterPosition.ALWAYS_IN_MODAL,
      name: label('Ref: filter label: max calories'),
      min: defaultValues.minCalories,
      max: defaultValues.maxCalories,
      displayType: FilterType.RANGE,
      defaultValue: defaultValues.maxCalories,
      customMaxLabel: `${defaultValues.maxCalories}+`,
      multiple: false,
      unit: 'kcal',
    },
    {
      options: buildAllergens(allergens),
      displayType: FilterType.MULTIPLE_SELECT,
      id: 'filter_allergens',
      multiple: true,
      position: FilterPosition.ALWAYS_IN_MODAL,
      name: label('Ref: filter label: allergens', { textTransform: 'capitalize' }),
    },
    {
      id: 'filter_allergens_highlight',
      position: allergens?.length ? FilterPosition.ALWAYS_IN_MODAL : FilterPosition.INVISIBLE,
      name: label('Ref: filter label: highlight', { textTransform: 'capitalize' }),
      displayType: allergens?.length ? FilterType.MULTIPLE_SELECT : FilterType.NONE,
      options: [
        {
          value: HightlightAllergenFilterValues.Hightlight,
          label: label('Ref: Highlight'),
        },
        { value: HightlightAllergenFilterValues.Hide, label: label('Ref: Hide') },
      ],
      isMultipleSelect: false,
      multiple: false,
      preventUnselect: true,
      columns: 2,
      excludeCount: true,
      text: label('Ref: Highlight text'),
    },
  ];

  const isFavoriteEnabled = isFFProductFavouriteEnabled() && !isGuest && !isKiosk;

  if (isFavoriteEnabled) {
    filters.unshift({
      id: 'filter_favorites',
      position: FilterPosition.ALWAYS_IN_MODAL,
      name: label('Ref: filter label: favorites'),
      displayType: FilterType.CHECKBOX_SINGLE,
      options: [{ value: 'favorites', label: 'filter' }],
      withTitle: false,
      multiple: false,
    });
  }

  return filters;
};
