import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { Device } from '@capacitor/device';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import usePrevious from '../../../components/hooks/usePrevious';
import { AlertComponentProps } from '../../../components/molecules/Alert/Alert.types';
import { IN_APP_BROWSER_FRAMELESS, SETUP_OPTIONS } from '../../../constants';
import useToggle from '../../../helpers/hooks/useToggle';
import { TranslationProps } from '../../../localization/localization.types';
import { store } from '../../../store';
import { getMenus, postOrder, removeItemsFromCart, setLastOrder } from '../actions';
import { pagePaths } from '../config';
import {
  buildProductUnavailablePopup,
  canPayOnline,
  getUnavailableCartItemId,
} from '../helpers/cart.helper';
import { PaymentModality, PaymentSetupOptions } from '../types/cart.types';
import { Cart, OrderDraft, OrderState, StateWithOrder } from '../types/orderState.types';

import { useCanPayOnline } from './useCanPayOnline/useCanPayOnline';
import useDraftOrder from './useDraftOrder';

import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { SERVICE } from '@/modules/config';
import { acknowledgeLegalDoc } from '@/modules/Core/actions';
import { legalDocType } from '@/modules/Core/config';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import { ISite } from '@/modules/Sites/types/sites.types';
import { UserSteps } from '@/types/userSteps.types';

type UseCheckoutOrderProps = {
  site: ISite;
  cart?: Cart;
  orderDraftLock: boolean | undefined;
  orderDraft: OrderDraft | undefined;
  paymentSetupOptions: PaymentSetupOptions;
  submissionTrackingId?: string;
} & TranslationProps;

const goBackMessageType = 'goback';

const useCheckoutOrder = ({
  site,
  cart,
  label,
  orderDraftLock,
  orderDraft,
  paymentSetupOptions,
  submissionTrackingId,
}: UseCheckoutOrderProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { logUserSteps } = useUserStepsInsightsLogging();
  const [popup, setPopup] = useState<AlertComponentProps | null>(null);
  const {
    state: isPayingOnline,
    toggleOn: toggleOnIsPayingOnline,
    toggleOff: toggleOffIsPayingOnline,
  } = useToggle(false);
  const canUserPayOnline = canPayOnline(paymentSetupOptions);
  const [isSubmitInProgress, setSubmitInProgress] = useState(false);
  const useCheckProductAvailabilityBeforeSubmit =
    useIsSetupOptionEnabled(SETUP_OPTIONS.FOOD_ORDER.useGetMenuBeforeSubmit, SERVICE.FOOD_ORDER) ||
    'false';

  const { termsOfSaleToAcknowledge } = useSelector((state: StateWithOrder) => state.Core.access);
  const { errors, locks: orderLocks } = useSelector((state: StateWithOrder) => state.Order);
  const { payForOrder: isSubmittingOrder } = orderLocks;

  const prevIsSubmittingOrder = usePrevious(isSubmittingOrder);

  const [submitDraftOrder] = useDraftOrder({
    siteId: site.id,
    cart,
    orderDraft,
  });

  const {
    checkIfOnlinePaymentPossible,
    isLoading: canPayOnlineLoading,
    isMinimumAmountError,
    minimumAmount,
    clearMinimumAmountError,
  } = useCanPayOnline();

  const isPostOrderError = errors?.payForOrder;
  const { currentLanguageCode: languageCode } = useLanguage();

  const createOrder = useCallback(
    async (payAtPickup: boolean) => {
      if (cart && !orderDraftLock) {
        await dispatch(
          postOrder({
            OrderId: orderDraft?.orderId,
            FacilityId: cart.facilityId,
            PaymentOnPickup: payAtPickup,
          })
        );
      }
    },
    [cart, orderDraft?.orderId, orderDraftLock, dispatch]
  );

  const payOnline = useCallback(async () => {
    const orderId = orderDraft?.orderId.toString() || '';
    const host = process.env.REACT_APP_HOST;
    const returnUrl = `${host}${pagePaths.Checkout.replace(':id/:callbackType', orderId)}`;
    const paymentUrl = `${
      !!process.env.REACT_APP_NEW_PAYMENT_HOST
        ? process.env.REACT_APP_NEW_PAYMENT_HOST
        : process.env.REACT_APP_PAYMENT_HOST
    }/?trackingId=${submissionTrackingId}&locale=${languageCode}&returnUrl=${returnUrl}`;
    if (canUserPayOnline) {
      toggleOnIsPayingOnline();
      const { platform } = await Device.getInfo();
      const isMobile = platform !== 'web';
      const browserTarget = isMobile ? '_blank' : '_self';
      const browserRef = InAppBrowser.create(paymentUrl, browserTarget, IN_APP_BROWSER_FRAMELESS);

      browserRef.on('loadstart')?.subscribe((evt: any) => {
        if (evt.url.match(/order\/checkout\/\d*\/.+/)) {
          browserRef.close();
          const splitRegex = new RegExp('https://.*?/');
          const redirectUrl = evt.url.split(splitRegex)[1];
          history.replace(`/${redirectUrl}`);
        }
      });
      browserRef.on('exit')?.subscribe((_) => toggleOffIsPayingOnline());
      browserRef.on('message')?.subscribe((message) => {
        if (message?.data?.type === goBackMessageType) {
          toggleOffIsPayingOnline();
          browserRef.close();
        }
      });
    } else {
      history.push(pagePaths.Checkout.replace(':id', orderId));
    }
  }, [
    canUserPayOnline,
    history,
    languageCode,
    orderDraft?.orderId,
    submissionTrackingId,
    toggleOnIsPayingOnline,
    toggleOffIsPayingOnline,
  ]);

  const submitOrder = useCallback(
    async ({
      paymentMethod,
      facilityId,
    }: {
      paymentMethod: PaymentModality;
      facilityId: string;
    }) => {
      if (!!termsOfSaleToAcknowledge)
        await dispatch(
          acknowledgeLegalDoc({
            documentId: termsOfSaleToAcknowledge,
            documentTypeCode: legalDocType.terms_of_sales,
          })
        );

      const isSuccess = await submitDraftOrder({ paymentMethod });
      if (isSuccess) {
        if (paymentMethod === PaymentModality.PayNow) {
          const canPayOnline = await checkIfOnlinePaymentPossible({
            orderTotal: orderDraft?.total || 0,
          });
          if (!canPayOnline) {
            setSubmitInProgress(false);
            return;
          }

          await dispatch(setLastOrder());
          await payOnline();
        } else {
          const payOnPickup = paymentMethod === PaymentModality.PayOnPickup;
          await createOrder(payOnPickup);
        }
      }

      setSubmitInProgress(false);
      logUserSteps({ event: UserSteps.OrderSubmitted, facilityId });
    },
    [
      dispatch,
      submitDraftOrder,
      logUserSteps,
      payOnline,
      createOrder,
      termsOfSaleToAcknowledge,
      checkIfOnlinePaymentPossible,
      orderDraft?.total,
    ]
  );

  const checkCartAndPay = useCallback(
    async ({ paymentMethod }: { paymentMethod: PaymentModality }) => {
      setSubmitInProgress(true);
      const shouldCheckMenuAvailability = useCheckProductAvailabilityBeforeSubmit !== 'false';

      await dispatch(
        getMenus({
          siteId: site.id,
          date: moment(cart?.date || '').format('YYYY-MM-DD'),
          useCache: shouldCheckMenuAvailability ? false : true,
        })
      );

      const orderStoreState: OrderState = store.getState()['Order'];

      const freshMenu = orderStoreState.menus.find(
        (menu) => menu.facilityId === cart?.facilityId && menu.id === cart?.menuId
      );

      if (freshMenu && orderStoreState.cart) {
        const unavailableCartIds = getUnavailableCartItemId(orderStoreState.cart, freshMenu);
        if (unavailableCartIds.length > 0) {
          setSubmitInProgress(false);
          setPopup(buildProductUnavailablePopup(label, setPopup));
          await removeItemsFromCart({ menuItemsIds: unavailableCartIds, dispatch });
        } else {
          await submitOrder({ paymentMethod, facilityId: freshMenu.facilityId });
        }
      }
    },
    [
      dispatch,
      site.id,
      cart?.date,
      cart?.facilityId,
      cart?.menuId,
      label,
      submitOrder,
      useCheckProductAvailabilityBeforeSubmit,
    ]
  );

  useEffect(() => {
    const wePostAnOrder = !!prevIsSubmittingOrder && !isSubmittingOrder;
    if (wePostAnOrder) {
      if (isPostOrderError) {
        history.push(pagePaths.OrderFail);
      } else {
        history.push(pagePaths.OrderSuccess);
      }
    }
  }, [history, isPostOrderError, isSubmittingOrder, prevIsSubmittingOrder]);

  useEffect(() => clearMinimumAmountError(), [cart?.menuPortionItems, clearMinimumAmountError]);

  return {
    popup,
    checkCartAndPay,
    isPayingOnline: isPayingOnline || canPayOnlineLoading,
    isSubmitInProgress,
    mustAcknowledgeTermsOfSale: !!termsOfSaleToAcknowledge,
    isMinimumAmountError,
    minimumAmount,
  };
};

export default useCheckoutOrder;
