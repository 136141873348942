import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withSite from '../../../../_deprecated/context/withSite';
import { ShoppingBagIcon } from '../../../../assets';
import { BUTTON_LOOK } from '../../../../components/atoms/Button';
import { SelectOption } from '../../../../components/atoms/Select';
import Title from '../../../../components/atoms/Title';
import Dropdown from '../../../../components/molecules/Dropdown';
import { TileSkeleton } from '../../../../components/molecules/Tile';
import WidgetPlaceholder from '../../../../components/molecules/WidgetPlaceholder';
import Widget from '../../../../components/organisms/Widget';
import BigWithSmallElementRow from '../../../../components/templates/BigWithSmallElementRow';
import { Result } from '../../../../types/common.types';
import { getAll as fetchFacilities } from '../../../Facilities/redux/actions';
import { FacilityDataItem } from '../../../Facilities/types/types';
import { useGetOrderHistoryQuery } from '../../api';
import OrderHistoryTile from '../../components/OrderHistoryTile/OrderHistoryTile';
import { pagePaths } from '../../config';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { HistoricalOrderType } from '../../types/orderHistory.types';
import { FacilitiesResponse, OrderHistoryWidgetProps } from '../../types/orderHistoryWidget.types';
import { OrderHistory, StateWithOrder } from '../../types/orderState.types';

import { WarningOutlinedIcon } from '@/assets/icons';
import { BackgroundWidgetOrderHistoryIllustration } from '@/assets/illustrations';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';

const OrderHistoryWidget = ({ site }: OrderHistoryWidgetProps) => {
  const dispatch = useDispatch();
  const {
    data: orders,
    isError: fetchingOrderHistoryError,
    isLoading: isOrderHistoryLoading,
  } = useGetOrderHistoryQuery({ siteId: site.id, useErrorBoundary: false });

  const { logError } = useUserStepsInsightsLogging();
  const { label } = useOrderTranslation(__filename);

  const filters = [
    { value: HistoricalOrderType.All, label: label('Ref: All orders') },
    { value: HistoricalOrderType.CurrentAndFuture, label: label('Ref: Not past orders') },
    { value: HistoricalOrderType.Past, label: label('Ref: Past orders') },
  ];

  const [selectedFilter, setSelectedFilter] = useState<HistoricalOrderType>(filters[0].value);
  const [orderHistory, setOrderHistory] = useState<OrderHistory[]>([]);
  const [facilities, setFacilities] = useState<FacilityDataItem[]>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { currentLanguageCode } = useSelector((state: StateWithOrder) => state.Shared.language);

  const handleFilterChange = useCallback((option: SelectOption) => {
    setSelectedFilter(option.value as HistoricalOrderType);
  }, []);

  const filterOrders = (filter: HistoricalOrderType): OrderHistory[] => {
    if (filter === HistoricalOrderType.CurrentAndFuture) {
      return orderHistory?.filter((x) => moment(x.pickupDate).isAfter(moment())).slice(0, 3) ?? [];
    }
    if (filter === HistoricalOrderType.Past) {
      return orderHistory?.filter((x) => moment(x.pickupDate).isBefore(moment())).slice(0, 3) ?? [];
    }
    return orderHistory?.slice(0, 3) ?? [];
  };

  const widgetPlaceholder = (filter: string): string => {
    if (fetchingOrderHistoryError) {
      logError(new Error('Error occured when fetching Order history'));
      return label('Something went wrong, data not loaded');
    }
    if (filter === HistoricalOrderType.CurrentAndFuture) {
      return label('Ref: You have no future orders');
    }
    if (filter === HistoricalOrderType.Past) {
      return label('Ref: You have no past orders');
    }

    return label('Ref: You have no orders');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responses = await Promise.all([
          dispatch(fetchFacilities({ siteId: site.id }, { useErrorBoundary: false })),
        ]);

        const facilitiesResponse: Result<FacilitiesResponse> = responses[0];

        if (facilitiesResponse.ok && !fetchingOrderHistoryError) {
          setFacilities(facilitiesResponse.responseData.facilities);
          if (orders) {
            const ordersHistory = [...orders]?.sort(
              (a, b) => new Date(b.pickupDate).getTime() - new Date(a.pickupDate).getTime()
            );
            if (ordersHistory) setOrderHistory(ordersHistory);
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, site.id, orders?.length, fetchingOrderHistoryError]);

  const filteredOrders = filterOrders(selectedFilter);

  return (
    <Widget data-testid="orderHistoryWidget">
      <Widget.Header illustration={<BackgroundWidgetOrderHistoryIllustration />}>
        <Widget.Title>
          <Title>{label('Ref: Widget Title')}</Title>
          <Widget.Link to={pagePaths.OrderHistory}>
            {label('see all (as in see all items)')}
          </Widget.Link>
          <BigWithSmallElementRow>
            <Dropdown
              value={selectedFilter}
              onChange={handleFilterChange}
              buttonLook={BUTTON_LOOK.TERTIARY}
              options={filters}
            />
          </BigWithSmallElementRow>
        </Widget.Title>
      </Widget.Header>
      <Widget.Body>
        {isLoading || isOrderHistoryLoading ? (
          <>
            <TileSkeleton
              withoutActions
              className={classNames('mb-M')}
              data-testid="skeleton-placeholder"
            />
            <TileSkeleton
              withoutActions
              className={classNames('mb-M')}
              data-testid="skeleton-placeholder"
            />
            <TileSkeleton
              withoutActions
              className={classNames('mb-M')}
              data-testid="skeleton-placeholder"
            />
          </>
        ) : filteredOrders.length > 0 ? (
          filteredOrders.map((order) => (
            <OrderHistoryTile
              key={order.orderId}
              order={order}
              facilities={facilities}
              currentLanguageCode={currentLanguageCode}
            ></OrderHistoryTile>
          ))
        ) : (
          <WidgetPlaceholder
            icon={fetchingOrderHistoryError ? <WarningOutlinedIcon /> : <ShoppingBagIcon />}
            title={widgetPlaceholder(selectedFilter)}
          />
        )}
      </Widget.Body>
    </Widget>
  );
};

export default withSite(OrderHistoryWidget);
