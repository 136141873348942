import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { getIdpAccessToken } from '../actions';
import { pagePaths } from '../config';
import useLanguage from '../hooks/useLanguage';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

const IdpLoginPage = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentLanguageCode } = useLanguage();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    if (queryParams.has('username') && queryParams.has('geocode')) {
      idpLogin(queryParams);
    }
  });

  const idpLogin = async (queryParams: URLSearchParams) => {
    await dispatch(
      getIdpAccessToken({
        username: queryParams.get('username')!,
        geocode: queryParams.get('geocode')!,
        type: 'SAML',
        currentLanguageCode,
      })
    );
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_EMAIL, queryParams.get('username')!);

    const siteIdFromShare = localStorage.getItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);
    dispatch(baseApi.util.resetApiState());
    dispatch(dspApi.util.resetApiState());

    if (siteIdFromShare) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);
      history.replace(`${pagePaths.Share.replace(':siteCode', siteIdFromShare)}`);
    } else {
      history.replace('/home');
    }
  };

  return <LoadingPage />;
};

export default IdpLoginPage;
