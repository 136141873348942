import { useHistory } from 'react-router';

import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import { isKiosk } from '../../../helpers/misc';
import KioskOrder from '../../Order/components/KioskOrder';
import { KioskOrderVariant } from '../../Order/types/KioskOrder.types';
import { useCoreTranslation } from '../hooks/useCoreTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

function GenericFailurePage() {
  const { label } = useCoreTranslation(__filename);
  const history = useHistory();

  if (isKiosk) {
    return <KioskOrder variant={KioskOrderVariant.FAIL} label={label} />;
  }
  return (
    <NoticePage
      hideAllWidgets
      type={NOTICEPAGE_TYPE.ERROR}
      title={label('Ref: Title')}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('ok', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
      ]}
    />
  );
}

export default GenericFailurePage;
