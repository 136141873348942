import momentjs from 'moment';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import { AlertComponentProps } from '../../../components/molecules/Alert/Alert.types';
import { toLocalDate } from '../../../helpers/dateTime';
import {
  addItemToCart,
  decreaseCartItemQuantity,
  increaseCartItemQuantity,
  removeItemsFromCart,
  updateItemInCart,
} from '../actions';
import { pagePaths } from '../config';
import {
  CartContextInfo,
  CartDifferentContextTypeEnum,
  ItemPrices,
  SelectedModifiers,
} from '../types/menuCartActions.types';
import {
  Cart,
  CartMenuItem,
  ICartModifier,
  ICartModifierValue,
  MenuItem,
  Modifier,
  ProductPortion,
  RedeemedFoodItem,
} from '../types/orderState.types';
import { ModifierSelections } from '../types/productDetails.types';

import { mergeCartItemsDuplicates } from './cart.helper';
import { getPrice } from './order.helper';

export const buildCartModifiers = (
  selectedModifiers: ModifierSelections[],
  allModifiers: Modifier[]
): ICartModifier[] => {
  let cartModifiers: ICartModifier[] = [];

  for (const modifier of selectedModifiers) {
    const modifierDefinition = allModifiers.find((x) => x.id === modifier.modifierId);

    const modifierValues: ICartModifierValue[] = modifier.itemIds.map((itemId) => {
      return {
        valueId: +itemId,
        quantity: modifier.itemQuantities[itemId],
        price: modifierDefinition?.modifierItems.find((x) => x.id === +itemId)?.price,
        firstFree: modifierDefinition?.modifierItems.find((x) => x.id === +itemId)?.firstFree,
        isFirstSelected: modifier.itemIds[0] === itemId,
      };
    });

    let cartModifier: ICartModifier = {
      modifierId: modifier.modifierId,
      values: modifierValues,
      firstFree: modifierDefinition?.firstFree,
      displayText: modifier.displayText,
    };

    if (cartModifier) cartModifiers.push(cartModifier);
  }

  return cartModifiers;
};

export const decrementItem = async ({
  cartMenuItems,
  dispatch,
}: {
  cartMenuItems: CartMenuItem[];
  dispatch: Dispatch<any>;
}) => {
  if (cartMenuItems[0]?.quantity === 1) {
    await removeItemsFromCart({ menuItemsIds: [cartMenuItems[0].id], dispatch });
  } else {
    await decreaseCartItemQuantity({ menuItemId: cartMenuItems[0].id, dispatch });
  }
};

export const redirectWhenPortionsOrModifiers = ({
  productQuantity,
  redirectToProductDetails,
  setPopup,
  label,
  redirectToCart,
}: {
  productQuantity: number;
  redirectToProductDetails: Function;
  setPopup: (popup: AlertComponentProps | null) => void;
  label: Function;
  redirectToCart: Function;
}) => {
  if (productQuantity > 0) {
    const popup = buildPopupForCustomizableItems({
      label,
      redirectToProductDetails,
      setPopup,
      redirectToCart,
    });
    setPopup(popup);
  } else {
    redirectToProductDetails();
  }
};

const buildPopupForCustomizableItems = ({
  label,
  redirectToProductDetails,
  setPopup,
  redirectToCart,
}: {
  label: Function;
  redirectToProductDetails: Function;
  setPopup: Function;
  redirectToCart: Function;
}): AlertComponentProps => {
  return {
    message: label(
      'Ref: Popup body for customizable item qty having to be changed from the cart or new version created'
    ),
    'data-testid': 'menu-cart-actions-customizable-item-qty-update-popup',
    header: label('Ref: Popup header for customizable item qty update'),
    onDismiss: () => setPopup(null),
    buttons: [
      label('Ref: cancel'),
      {
        text: label('Ref: change in cart'),
        handler: () => redirectToCart(),
        'data-testid': 'menu-cart-actions-customizable-item-qty-update-popup-change-in-cart-action',
      },
      {
        text: label('Ref: add new version'),
        handler: () => redirectToProductDetails(),
        'data-testid':
          'menu-cart-actions-customizable-item-qty-update-popup-add-new-version-action',
      },
    ],
  };
};

export const buildPopupForRemoveCustomizableItems = ({
  label,
  setPopup,
  redirectToCart,
}: {
  label: Function;
  setPopup: Function;
  redirectToCart: Function;
}): AlertComponentProps => {
  return {
    'data-testid': 'menu-cart-actions-customizable-item-remove-popup',
    message: label('Ref: Popup body for customizable item qty having to be changed from the cart'),
    header: label('Ref: Popup header for customizable item qty update'),
    onDismiss: () => setPopup(null),
    buttons: [
      label('Ref: cancel'),
      {
        text: label('Ref: change in cart'),
        handler: () => redirectToCart(),
        'data-testid': 'menu-cart-actions-customizable-item-remove-popup-change-in-cart-action',
      },
    ],
  };
};

export const getMenuItemQuantity = (cartMenuItems: CartMenuItem[]): number =>
  cartMenuItems.reduce((prev, next) => prev + next.quantity, 0);

export const getCartMenuItems = (
  menuItem: MenuItem,
  cart: Cart,
  cartMenuItemId?: string,
  selectedPortion?: ProductPortion,
  selectedModifiers?: ModifierSelections[],
  hasPortionsOrModifiers?: boolean,
  posssibleToAddNotOnlyDefaultPortion?: boolean
): CartMenuItem[] => {
  if (!menuItem) return [];
  if (cartMenuItemId) return cart.menuPortionItems?.filter((x) => x.id === cartMenuItemId) ?? [];
  if (hasPortionsOrModifiers && posssibleToAddNotOnlyDefaultPortion) {
    const modifierDisplayText = buildModifierDisplayText(selectedModifiers);

    return (
      cart.menuPortionItems?.filter(
        (x) =>
          x.uomId === selectedPortion?.uomId &&
          x.menuItemId === menuItem.menuItemId &&
          ((!x.modifiersDisplayText && !modifierDisplayText) ||
            x.modifiersDisplayText === modifierDisplayText)
      ) || []
    );
  } else {
    return cart.menuPortionItems?.filter((x) => x.menuItemId === menuItem.menuItemId) ?? [];
  }
};

export const buildProductDetailsPath = ({
  menuId,
  menuItemId,
  facilityId,
  date,
}: {
  menuId: string;
  menuItemId: string;
  facilityId: string;
  date: Date;
}) => {
  return pagePaths.ProductDetails.replace(':id', menuItemId)
    .replace(':facilityId', facilityId)
    .replace(':menuId', menuId)
    .replace(':cartItem', 'no-cart')
    .replace(':date', momentjs(date).format('YYYY-MM-DD'));
};

export const increaseItem = async ({
  cartMenuItems,
  menuItem,
  portion,
  cartItemPrice,
  onAddFirstItemToCart,
  dispatch,
}: {
  cartMenuItems: CartMenuItem[];
  menuItem: MenuItem;
  portion?: ProductPortion;
  cartItemPrice: number;
  onAddFirstItemToCart?: () => void;
  dispatch: Dispatch<any>;
}) => {
  if (!cartMenuItems.length) {
    await addItemToCart({
      menuItem: {
        id: uuidv4(),
        img: menuItem.listImage,
        menuItemId: menuItem.menuItemId,
        name: menuItem.name,
        price: cartItemPrice ?? menuItem.price,
        quantity: 1,
        uomId: portion?.uomId || 0,
        foodItemId: portion?.foodItemId || 0,
        description: portion?.description || '',
        isVegan: portion?.isVegan ?? false,
        isVegetarian: portion?.isVegetarian ?? false,
        genericCategory: menuItem?.genericCategory ?? '',
      },
      dispatch,
    });
    onAddFirstItemToCart && onAddFirstItemToCart();
  } else {
    await increaseCartItemQuantity({ menuItemId: cartMenuItems[0].id, dispatch });
  }
};

export const buildModifierDisplayText = (selectedModifiers?: ModifierSelections[]) => {
  if (!selectedModifiers) return undefined;

  return selectedModifiers?.reduce((prev, modifier) => prev + modifier.displayText, '');
};

export const increaseItemWithModifiersOrPortions = async ({
  cartMenuItems,
  menuItem,
  selectedPortion,
  selectedModifiers,
  cartItemPrice,
  dispatch,
}: {
  cartMenuItems: CartMenuItem[];
  menuItem: MenuItem;
  selectedPortion?: ProductPortion;
  selectedModifiers?: ModifierSelections[];
  cartItemPrice: number;
  dispatch: Dispatch<any>;
}) => {
  const modifierDisplayText = buildModifierDisplayText(selectedModifiers);

  const portionModifiersCartMenuItem = cartMenuItems.find(
    (x) =>
      x.uomId === selectedPortion?.uomId &&
      (x.modifiersDisplayText || modifierDisplayText
        ? x.modifiersDisplayText === modifierDisplayText
        : true)
  );

  const allModifiers = selectedPortion?.modifiers;

  if (!portionModifiersCartMenuItem) {
    await addItemToCart({
      menuItem: {
        id: uuidv4(),
        img: menuItem.listImage,
        menuItemId: menuItem.menuItemId,
        name: menuItem.name,
        price: cartItemPrice,
        quantity: 1,
        uomId: selectedPortion?.uomId || 0,
        foodItemId: selectedPortion?.foodItemId || 0,
        modifiers:
          selectedModifiers && allModifiers && buildCartModifiers(selectedModifiers, allModifiers),
        modifiersDisplayText: selectedModifiers && modifierDisplayText,
        description: selectedPortion?.description || '',
        isVegan: selectedPortion?.isVegan ?? false,
        isVegetarian: selectedPortion?.isVegetarian ?? false,
        genericCategory: menuItem?.genericCategory ?? '',
      },
      dispatch,
    });
  } else {
    await increaseCartItemQuantity({ menuItemId: portionModifiersCartMenuItem.id, dispatch });
  }
};

export const updateCartItem = async ({
  cartMenuItem,
  selectedPortion,
  selectedModifiers,
  cartItemPrice,
  dispatch,
  cart,
}: {
  cartMenuItem: CartMenuItem;
  selectedPortion?: ProductPortion;
  selectedModifiers?: ModifierSelections[];
  cartItemPrice: number;
  dispatch: Dispatch<any>;
  cart: Cart | undefined;
}) => {
  if (cart) {
    mergeCartItemsDuplicates({ cart, dispatch });
  }

  const allModifiers = selectedPortion?.modifiers;
  const cartModifiers =
    selectedModifiers && allModifiers && buildCartModifiers(selectedModifiers, allModifiers);
  const modifiersDisplayText = selectedModifiers && buildModifierDisplayText(selectedModifiers);

  if (
    (modifiersDisplayText !== undefined &&
      modifiersDisplayText !== cartMenuItem.modifiersDisplayText) ||
    (selectedPortion?.uomId && cartMenuItem.uomId && selectedPortion?.uomId !== cartMenuItem.uomId)
  ) {
    const newCartItem: CartMenuItem = {
      ...cartMenuItem,
      price: cartItemPrice,
      uomId: selectedPortion?.uomId || 0,
      modifiers: cartModifiers,
      modifiersDisplayText: modifiersDisplayText,
    };

    await updateItemInCart({ menuItem: newCartItem, dispatch });
  }
};

export const isCartAnotherContext = ({
  menuItem,
  menuId,
  date,
  cart,
  isCartView,
}: {
  menuItem: MenuItem;
  menuId: number;
  date: Date;
  cart?: Cart;
  isCartView: boolean;
}): CartContextInfo => {
  if (isCartView) return { isAnotherOrderContext: false };

  const cartHasMenuPortions = cart?.menuPortionItems && cart.menuPortionItems.length > 0;
  if (!cartHasMenuPortions) return { isAnotherOrderContext: false };

  const differentDate = toLocalDate(cart.date).toISOString() !== date.toISOString();
  if (differentDate)
    return { isAnotherOrderContext: true, reason: CartDifferentContextTypeEnum.DifferentDate };

  const differentMenu = cart?.menuId !== menuId;
  if (differentMenu)
    return { isAnotherOrderContext: true, reason: CartDifferentContextTypeEnum.DifferentMenu };

  const differentMoment = cart?.moment !== menuItem?.mealName;
  if (differentMoment)
    return { isAnotherOrderContext: true, reason: CartDifferentContextTypeEnum.DifferentMoment };

  return { isAnotherOrderContext: false };
};

export const buildProductTotalPrice = ({
  portion,
  selectedModifiers,
  languageCode,
  isoCode,
  productQuantity,
  redeemedFoodItems,
}: {
  portion?: ProductPortion;
  selectedModifiers: SelectedModifiers[];
  languageCode?: string;
  isoCode?: string;
  productQuantity?: number;
  redeemedFoodItems?: RedeemedFoodItem[];
}) => {
  if (!portion)
    return {
      totalPrice: 0,
      ...(languageCode && isoCode && { totalPriceLabel: getPrice(0, languageCode, isoCode) }),
    };

  const { modifiers, price } = portion;

  const redeemedQuantity =
    redeemedFoodItems?.find((r) => r.foodItemId === portion.foodItemId && r.uomId === portion.uomId)
      ?.quantity ?? 0;

  productQuantity = Math.max(0, (productQuantity || 1) - redeemedQuantity);
  /**
   * Calculates the total price of each modifier
   * based on it's selected items and quantities
   */
  const modifierPrices = selectedModifiers.map(({ modifierId, itemQuantities, itemIds }) => {
    const modifierDetails = modifiers.find(({ id }) => modifierId === id);
    const { modifierItems = [] } = modifierDetails || {};
    const freeModifiersOnGroupLevel = modifierDetails?.firstFree ?? 0;

    /**
     * Returns total prices and price per unit
     * for each item in the selected modifier
     */
    const itemPrices = Object.entries(itemQuantities).reduce((acc, [key, quantity]) => {
      const itemId = parseInt(key);
      const itemDetails = modifierItems.find(({ id }) => id === itemId);

      for (let i = 0; i < quantity; i++) {
        const item = {
          id: itemId,
          unitPrice: itemDetails?.price ?? 0,
          isFreeOnItemLevel: itemDetails?.firstFree ? i < itemDetails?.firstFree : false,
        };

        acc.push(item);
      }

      return acc.sort((a, b) => {
        return a.unitPrice - b.unitPrice;
      });
    }, [] as ItemPrices[]);

    const markModifiersAsFree = (itemPrices: ItemPrices[], freeModifiersOnGroupLevel: number) => {
      const finalItemPrices = itemPrices.map((item) => {
        if (item.unitPrice > 0 && freeModifiersOnGroupLevel > 0 && item.isFreeOnItemLevel) {
          freeModifiersOnGroupLevel--;
          return { ...item, isFree: true };
        } else {
          return { ...item, isFree: false };
        }
      });

      return finalItemPrices;
    };

    const finalItemPrices = markModifiersAsFree(itemPrices, freeModifiersOnGroupLevel);

    const totalPrice = finalItemPrices
      .filter((item) => !item.isFree)
      .reduce((acc, { unitPrice }) => acc + unitPrice, 0);

    return {
      id: modifierId,
      itemPrices,
      totalPrice,
    };
  });

  const totalModifiersPrice = modifierPrices.reduce((acc, { totalPrice }) => acc + totalPrice, 0);
  const totalPrice = ((price ?? 0) + totalModifiersPrice) * productQuantity;

  return {
    totalPrice,
    ...(languageCode &&
      isoCode && { totalPriceLabel: getPrice(totalPrice, languageCode, isoCode) }),
  };
};

export const getDefaultPortion = (menuItem: MenuItem) =>
  menuItem?.productPortions.find((x) => x.isDefault);

export const hasPortionsOrModifiers = (menuItem: MenuItem): boolean => {
  const defaultPortion = getDefaultPortion(menuItem);
  return (
    (menuItem?.productPortions?.length > 1 ||
      (defaultPortion?.modifiers && defaultPortion?.modifiers?.length > 0)) ??
    false
  );
};

export const hasMandatoryModifiers = (defaultPortion: ProductPortion): boolean =>
  defaultPortion?.modifiers?.some((modifier) => modifier.min > 0);
