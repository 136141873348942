import classNames from 'classnames';
import momentjs from 'moment';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Calendar2Icon, Restaurant } from '../../../../assets/icons';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title from '../../../../components/atoms/Title';
import Calendar from '../../../../components/molecules/Calendar';
import { TileSkeleton } from '../../../../components/molecules/Tile';
import WidgetPlaceholder from '../../../../components/molecules/WidgetPlaceholder';
import Modal from '../../../../components/organisms/Modal';
import WidgetComponent from '../../../../components/organisms/Widget';
import { DATE_FORMAT, SIZE } from '../../../../constants';
import { formatDate } from '../../../../helpers/dateTime';
import useToggle from '../../../../helpers/hooks/useToggle';
import { SERVICE } from '../../../config';
import { getMenus } from '../../actions';
import DiscountTiersModal from '../../components/DiscountTiersModal/DiscountTiersModal';
import MenuTile from '../../components/MenuTile/MenuTile';
import { pagePaths } from '../../config';
import {
  cacheSelectedFilters,
  convertToMenuItems,
  getDefaultFiltersFromCache,
  menuSelectorReducer,
} from '../../helpers/menuSelector.helper';
import { getFacilityMenuPath } from '../../helpers/order.helper';
import useDiscountTiersModal from '../../hooks/useDiscountTiersModal';
import { useFacilityMenuImage } from '../../hooks/useFacilityMenuImage/useFacilityMenuImage';
import useFacilityMenuList from '../../hooks/useFacilityMenuList';
import useMenusVisits from '../../hooks/useMenusVisits/useMenusVisits';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { MenuSelectorListItem, MenuType } from '../../types/menuSelector.types';
import { StateWithOrder } from '../../types/orderState.types';

import { sortFacilitiesByMenuTypeAndVisits } from './MenusWidget.helper';

import { BackgroundWidgetMenusIllustration } from '@/assets/illustrations';
import { hasNavigationEnabled } from '@/modules/Core/helpers/availableServices.helper';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { getAll } from '@/modules/Facilities/redux/actions';

import styles from './MenusWidget.module.css';

const MenusWidget = () => {
  const [selectedDate, setSelectedDate] = useState<string>(
    Object.keys(getDefaultFiltersFromCache()?.filter_date || {})[0] ??
      momentjs().format(DATE_FORMAT)
  );
  const { label } = useOrderTranslation(__filename);

  const {
    state: isCalendarModalOpen,
    toggleOn: openCalendarModal,
    toggleOff: closeCalendarModal,
  } = useToggle(false);

  const [state, localDispatch] = useReducer(menuSelectorReducer, {
    hasMenuFetched: false,
    setDiscountTierStatus: false,
  });

  const { id: siteId } = useSite()!;
  const { currentLanguageCode } = useLanguage();

  const {
    services: { list: services },
  } = useSelector((state: StateWithOrder) => state.Core);

  const hasMenuModule = hasNavigationEnabled(services, SERVICE.FOOD_MENU);
  const hasOrderModule = hasNavigationEnabled(services, SERVICE.FOOD_ORDER);

  const { locks, discountTiers } = useSelector((state: StateWithOrder) => state.Order);
  const lockGetMenusForFacility = locks?.getMenusForFacility;
  const discountTiersList = discountTiers?.pricingTiers || [];

  const dispatch = useDispatch();
  const handleGettingMenus = useCallback(() => {
    dispatch(
      getMenus(
        { siteId, date: selectedDate, useCache: true, menuType: MenuType.All },
        { useErrorBoundary: false }
      )
    );
  }, [dispatch, selectedDate, siteId]);

  const { isTiersModalOpen, closeTiersModal } = useDiscountTiersModal({
    siteId,
    localDispatch,
    postSelectionAction: handleGettingMenus,
  });

  const discountTiersModal = (
    <DiscountTiersModal
      siteId={siteId}
      closeTiersModal={closeTiersModal}
      discountTiersList={discountTiersList}
      isTiersModalOpen={isTiersModalOpen}
      postSelecitonAction={handleGettingMenus}
      tier={state.tier}
    />
  );

  useEffect(() => {
    dispatch(getAll({ siteId: siteId }, { useErrorBoundary: false }));
  }, [dispatch, siteId]);

  const facilities = useFacilityMenuList();
  const facilityImagesInfo = useMemo(
    () => facilities.map((facility) => ({ facilityId: facility.id, imageId: facility.imageId })),
    [facilities]
  );
  const { getMenuImage } = useFacilityMenuImage(facilityImagesInfo);
  const { menusVisits } = useMenusVisits(siteId);

  const listElements: MenuSelectorListItem[] = useMemo(
    () =>
      convertToMenuItems({
        facilities,
        menuType: MenuType.All,
        getMenuImage,
      }),
    [facilities, getMenuImage]
  );

  const filteredFacilities =
    !hasOrderModule && hasMenuModule
      ? listElements.filter((facility) => facility.menu.isOrderable === false)
      : listElements;

  const sortedFacilities = useMemo(() => {
    return filteredFacilities && sortFacilitiesByMenuTypeAndVisits(filteredFacilities, menusVisits);
  }, [filteredFacilities, menusVisits]);

  const isDateToday = selectedDate === momentjs().format(DATE_FORMAT);

  const hasOrderableMenu = sortedFacilities.some((facility) => facility.menu.isOrderable === true);

  const seeAllPath =
    hasOrderableMenu || (hasOrderModule && !hasMenuModule)
      ? pagePaths.Module
      : pagePaths.MenuModule;

  return (
    <>
      <WidgetComponent>
        <WidgetComponent.Header illustration={<BackgroundWidgetMenusIllustration />}>
          <WidgetComponent.Title>
            <Title>{label('Menu')}</Title>
            <WidgetComponent.Link to={seeAllPath} className={'mb-M'}>
              {label('see all (as in see all items)')}
            </WidgetComponent.Link>
            <div className={styles.calendarWrapper}>
              <Button
                look={BUTTON_LOOK.PRIMARY}
                size={SIZE.SMALL}
                contentCenterAlign={true}
                className={styles.calendar}
                affix={() => <Calendar2Icon />}
                onClick={() => openCalendarModal()}
                data-testid="menus-widget-calendar-button"
              >
                {isDateToday
                  ? label('today')
                  : formatDate(momentjs(selectedDate, DATE_FORMAT).toDate(), currentLanguageCode)}
              </Button>
            </div>
          </WidgetComponent.Title>
        </WidgetComponent.Header>
        <WidgetComponent.Body>
          {lockGetMenusForFacility ? (
            <>
              <TileSkeleton
                withoutActions
                className={classNames('mb-M')}
                data-testid="skeleton-placeholder"
              />
              <TileSkeleton
                withoutActions
                className={classNames('mb-M')}
                data-testid="skeleton-placeholder"
              />
              <TileSkeleton
                withoutActions
                className={classNames('mb-M')}
                data-testid="skeleton-placeholder"
              />
            </>
          ) : facilities?.length > 0 ? (
            [...sortedFacilities]?.slice(0, 3).map((fac) => {
              return (
                <MenuTile
                  key={fac.id}
                  id={fac.id}
                  facilityTitle={fac.facilityTitle}
                  facilityId={fac.facilityId}
                  menuImageSource={fac.menuImageSource}
                  menu={fac.menu}
                  selectedDate={selectedDate}
                  facilityMenuPath={getFacilityMenuPath}
                  data-testid={`menu-tile-${fac.id}`}
                />
              );
            })
          ) : (
            <WidgetPlaceholder icon={<Restaurant />} title={label('Ref: no menu')} />
          )}
        </WidgetComponent.Body>
      </WidgetComponent>

      <Modal
        title={label('Ref: Modal title')}
        isOpen={isCalendarModalOpen}
        onDismiss={() => closeCalendarModal()}
      >
        <Calendar
          value={momentjs(selectedDate).toDate()}
          minDetail="month"
          minDate={momentjs().startOf('day').toDate()}
          maxDate={momentjs().add(10, 'days').startOf('day').toDate()}
          onChange={(date: Date) => {
            const pickedDate = momentjs(date).format(DATE_FORMAT);
            setSelectedDate(pickedDate);
            cacheSelectedFilters({
              filter_date: {
                [pickedDate]: true,
              },
            });
            closeCalendarModal();
          }}
        />
      </Modal>
      {discountTiersModal}
    </>
  );
};

export default MenusWidget;
