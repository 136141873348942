import classNames from 'classnames';
import { useHistory } from 'react-router';

import { ShoppingBagIcon } from '../../../../assets';
import { PaymentStatusIcon } from '../../../../assets/icons';
import { TAG_COLOR, Tile } from '../../../../components/molecules/Tile';
import { formatDateTime, toLocalDate } from '../../../../helpers/dateTime';
import MM from '../../../../services/ModulesManager';
import { ApiType } from '../../../Core/api';
import { FacilityDataItem } from '../../../Facilities/types/types';
import { pagePaths } from '../../config';
import { getFulfillmentTypeLabel } from '../../helpers/order.info.helper';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { OrderHistoryTileProps } from '../../types/orderHistory.types';
import { OrderHistory } from '../../types/orderState.types';

import styles from './OrderHistoryTile.module.css';

const OrderHistoryTile = ({
  order,
  facilities,
  currentLanguageCode,
  image,
}: OrderHistoryTileProps) => {
  const history = useHistory();
  const { label } = useOrderTranslation(__filename);

  const priceFormatter = (
    MM<ApiType>().getApi('Core', 'createPriceFormatter') as ApiType['createPriceFormatter']
  )();

  const isPaidTag = {
    name: label('Ref: Not Paid'),
    icon: (
      <span className={classNames(styles.paymentIcon)}>
        <PaymentStatusIcon />
      </span>
    ),
    color: TAG_COLOR.DANGER,
  };

  const isPaymentStatusAvailable = order.isPaid !== null && order.isPaid !== undefined;

  const buildTagsForOrder = (order: OrderHistory) => {
    let tags = [
      {
        name: label(`Ref: ${order.status}`),
        icon: (
          <span className={classNames(styles.paymentIcon)}>
            <ShoppingBagIcon />
          </span>
        ),
        color: TAG_COLOR.DARK,
      },
    ];

    if (!order.isPaid) {
      tags.push(isPaidTag);
    }

    return tags;
  };

  const getTitle = (order: OrderHistory, facilities: FacilityDataItem[] | undefined = []) => {
    let title = `#${order.orderId.toString()}`;

    const orderFacility = facilities.find((x) => x.id === order.facilityId)?.title;

    if (!!order.deliveryOptionType) {
      const fulfillmentTypeLabel = getFulfillmentTypeLabel(order.deliveryOptionType, order.orderId);
      title = fulfillmentTypeLabel ? title + ` • ${label(fulfillmentTypeLabel)}` : title;
    }

    if (!!orderFacility) {
      title = title + ` • ${orderFacility}`;
    }
    return title;
  };

  return (
    <Tile
      id={order.orderId.toString()}
      title={getTitle(order, facilities)}
      description={`${
        order.pickupDate
          ? formatDateTime(toLocalDate(order.pickupDate, true), currentLanguageCode)
          : ''
      }`}
      tags={isPaymentStatusAvailable ? buildTagsForOrder(order) : undefined}
      onClick={() =>
        history.push(pagePaths.OrderHistoryDetails.replace(':id', order.orderId.toString()))
      }
      data-testid={`order-history-tile-${order.orderId}`}
      image={image}
      fullChildrenHeight
      childText={
        <div className={classNames(styles.totalTitle)}>
          {`${label('Ref: Total:')} ${
            priceFormatter ? priceFormatter.format(order.totalAmount) : ''
          }`}
        </div>
      }
    >
      {' '}
    </Tile>
  );
};

export default OrderHistoryTile;
