const labels = {
  PrivacyPolicy: '개인 정보 정책',
  TermsOfSale: '판매 조건',
  TermsOfUse: '이용 약관',
  home: '집',
  anytime: '언제든지',
  anywhere: '어딘가에',
  Download: '다운로드',
  scrollRightButton: '오른쪽으로 범주를 스크롤합니다',
  scrollLeftButton: '왼쪽으로 스크롤 카테고리',
  calendar: '달력',
  'See more': '더보기',
  'See less': '덜보기',
  'from (followed by a date, or date and time)': '~에서',
  'to (followed by a date, or date and time)': '에게',
  'on (followed by a date)': '에',
  'at (followed by time)': '~에',
  'from (followed by a location)': '~에서',
  'to (followed by a location)': '에게',
  'on (followed by a site name)': '에',
  'from (followed by a person)': '~에서',
  previous: '이전의',
  next: '다음',
  success: '성공',
  failure: '실패',
  today: '오늘',
  tomorrow: '내일',
  Monday: '월요일',
  Tuesday: '화요일',
  Wednesday: '수요일',
  Thursday: '목요일',
  Friday: '금요일',
  Saturday: '토요일',
  Sunday: '일요일',
  everyday: '매일',
  'open all day': '하루 종일 개점',
  'opening hours': '개관 시간',
  status: '상태',
  'to be confirmed': '확인해야합니다',
  'only (as in filter only this)': '오직',
  edit: '편집하다',
  submit: '제출하다',
  cancel: '취소',
  confirm: '확인하다',
  reset: '초기화',
  Delete: '삭제',
  select: '고르다',
  switch: '스위치',
  close: '닫다',
  clear: '분명한',
  remove: '제거하다',
  logout: '로그 아웃',
  go: '가다',
  'see (as in consult this page or document)': '보다',
  'see all (as in see all items)': '모두보기',
  'see more (as in see more details for this item)': '더보기',
  retry: '다시 해 보다',
  'Ref: ok': '확인',
  'yes (as in yes I accept)': '예',
  'no (as in no I do not accept)': '아니요',
  you: '너',
  'thank you': '감사합니다',
  'sorry (standalone)': '죄송합니다',
  'to home (as in navigate to the homepage)': '홈페이지로',
  map: '지도',
  list: '목록',
  'Loading...': '...에 적재 ...',
  'Refreshing...': '업데이트 중...',
  'Please select': '선택 해주세요',
  'Switch site': '여기에서 사이트를 전환하십시오',
  'File size cannot exceed {max file size}': '파일 크기는 {max file size}를 초과할 수 없습니다.',
  Filters: '필터',
  'Add attachment': '첨부파일 추가',
  Search: '검색',
  'More (as in: see more info)': '더',
  'You can attach a maximum of {max files number} files.':
    '최대 {max files number}개의 파일을 첨부할 수 있습니다.',
  'There are no messages to display.': '표시할 메시지가 없습니다.',
  'Your selection did not return any result.': '선택한 결과가 반환되지 않았습니다.',
  'Ref: Popup header for missing required fields in a form': '추가 정보를 제공하십시오',
  'Ref: Required field': '{ref}는 필수 필드입니다.',
  email: '이메일',
  phone: '휴대 전화 번호',
  Account: '계정',
  Contacts: '콘택트 렌즈',
  Content: '콘텐츠',
  Events: '이벤트',
  Facilities: '시설',
  Shop: '가게',
  Menu: '메뉴',
  Menus: '메뉴',
  Order: '주문하다',
  Orders: '명령',
  Info: '정보',
  FAQ: '자주하는 질문',
  Feedback: '피드백',
  Review: '검토',
  Requests: '요청',
  'Request Helpdesk': '헬프 데스크 요청',
  Location: '위치',
  Safety: '안전',
  FreeCoffee: '무료 커피',
  Newsfeed: '뉴스 피드',
  MyOrders: '내 주문',
  LoyaltyStamps: '로열티 스탬프',
  Stamps: '우표',
  Stays: '체재',
  Suppliers: '공급 업체',
  Surveys: '조사',
  notifications: '알림',
  cart: '카트',
  'Ref: Reset your order': '주문을 재설정하십시오',
  'Ref: My language': '내 언어',
  Validate: '확인',
  'contact us': '문의하기',
  'Ref: Start typing': '타이핑을 시작하십시오',
  Occupancy: '점유율',
  'see less': '덜보십시오',
  'see more': '더보기',
  contacts: '콘택트 렌즈',
  Imprint: '날인',
  'Ref: camera access request title': '카메라에 액세스 할 수 있습니다',
  'Ref: camera access request body':
    'QR 코드 스캐너를 사용하려면이 사이트에서 카메라를 사용할 수 있어야합니다.',
  or: '또는',
  'Ref: Input required': '{input_name}이 필요합니다',
  'Enter a valid email address': '유효한 이메일 주소를 입력하십시오',
  'Ref: Input error E-mail already exists':
    '입력 한이 이메일과 이미 계정이 있습니다. 대신 로그인하십시오.',
  'Password must contain': '비밀번호는 다음을 포함해야합니다.',
  'Ref: Input password error strength':
    '비밀번호에는 8 자 이상, 1 개의 숫자, 1 글자 및 1 개의 특수 문자가 포함되어야합니다.',
  Password: '비밀번호',
  'New password': '새 비밀번호',
  'Confirm password': '비밀번호 확인',
  'Ref: Password does not match': '비밀번호와 암호 확인 비밀번호가 일치하지 않습니다',
  'Ref: Old password does not match': '오래된 암호는 일치하지 않습니다',
  'Ref: Password validation min8Characters': '최소 8 자',
  'Ref: Password validation min1Number': '최소 1 자리',
  'Ref: Password validation min1Letter': '최소 1 편',
  'Ref: Password validation min1SpecialChar': '적어도 하나의 특수 캐릭터',
  Continue: '계속하다',
  'Enter a valid phone number': '유효한 전화 번호를 입력하십시오',
  'Phone is required': '전화가 필요합니다',
  'Go to home': '집에 가십시오',
  'Something went wrong': '뭔가 잘못되었습니다',
  'Something went wrong, data not loaded': '무언가 잘못되었고 데이터가로드되지 않았습니다',
  'Change password': '비밀번호를 변경하십시오',
  'remove one': '하나를 제거하십시오',
  'add one': '하나를 추가하십시오',
  'Confirm mobile phone': '휴대폰 확인',
  'Confirm Filters': '필터를 확인하십시오',
  'Reset Filters': '필터를 재설정합니다',
  '{selectedNum} active filters': '{selectedNum} 활성 필터',
  'Wrong input icon': '잘못된 입력 아이콘',
  'Correct input icon': '올바른 입력 아이콘',
  'Show input text icon': '입력 텍스트 아이콘을 표시합니다',
  'Hide input text icon': '입력 텍스트 아이콘을 숨 깁니다',
  'Required fields info': '* 필수 입력 사항',
  'Ref: Select site on the map': '지도에서 사이트를 선택하십시오',
  'Ref: Geolocation disabled':
    '지리적 위치가 비활성화되었습니다. 근처 사이트를 보려면 활성화하십시오.',
  'Ref: Reaction - LIKE': '처럼',
  'Ref: Reaction - LOVE': '사랑',
  'Ref: Reaction - INTERESTING': '흥미로운',
  'Ref: Reaction - CONGRATE': '축하합니다',
  'Ref: Share': '공유하다',
  'Ref: Send by Email': '이메일로 보내십시오',
  'Ref: Copy the link': '링크를 복사하십시오',
  'Ref: Link was copied': '링크가 복사되었습니다',
  'Ref: unable to copy url': 'URL을 복사 할 수 없습니다',
  'Ref: External link': '외부 링크',
  'Ref: Step': '단계',
  'input field': '입력 필드',
  'Ref: Zoom': '줌',
  'Increase hours': '시간을 늘리십시오',
  'Decrease hours': '시간을 줄입니다',
  Hours: '시간',
  Minutes: '분',
  'Ref: filter label: favorites': '내가 좋아하는 것만 보여주십시오',
  'Ref: filter label: max calories': '최대 칼로리',
  'Ref: filter label: dishes': '그릇',
  'Ref: filter label: allergens': '알레르기 항원',
  'Ref: filter label: highlight': '요리로 필터를 강조 표시하거나 숨 깁니다',
  'Ref: Highlight': '가장 밝은 부분',
  'Ref: Hide': '숨다',
  'Ref: Vegan': '채식주의 자의',
  'Ref: Vegeterian': '채식주의 자',
  'Ref: Mindful': '마음에 들었다',
  'Ref: Highlight text':
    '여기에서는 특정 알레르겐을 포함하는 메뉴에서 숨기거나 요리를 숨길 수 있습니다.',
  'Ref: Just now': '방금',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} 몇 분 전',
  'Ref: 1 hour ago': '1 시간 전',
  'Ref: {amountHours} hours ago': '{amountHours} 몇 시간 전',
  'Ref: Yesterday at {time}': '어제 {time}',
  'Ref: {date} at {time}': '{date} at {time}',
  'Ref: filter label: date': '날짜',
  'Ref: expand menu': '메뉴를 확장하십시오',
  'Ref: collapse menu': '붕괴 메뉴',
  'Ref: My site': '내 사이트',
  'Ref: Recent': '최근의',
  'Ref: Services': '서비스',
  'Ref: products in cart': '카트의 제품 수',
  'Ref: no product in cart': '카트에 제품이 없습니다',
  'Ref: Survey': '조사',
  'Access to camera was denied. Please allow access and try again':
    '카메라 접근이 거부되었습니다. 액세스를 허용하고 다시 시도하십시오',
  'Add an attachment': '부착물을 추가하십시오',
  'Take a picture': '사진을 찍다',
  'Add a file from your device': '장치에서 파일을 추가하십시오',
  'Ref: Loading page content, please wait': '페이지 콘텐츠로드, 기다려주세요',
  Star: '별',
  Stars: '별',
  'Accommodation Request': '숙박 요청',
  'Ref: Guest Accomodation Request': '게스트 요청',
  'Ref: Search a menu, product or facility': '메뉴, 제품 또는 시설을 검색하십시오',
  ConnectionLost: '네트워크 연결이 손실되었습니다',
  ConnectionRestored: '네트워크 연결 복원',
  'Ref: Skip to main content': '주요 콘텐츠로 건너 뜁니다',
  'Ref: No options': '옵션이 없습니다',
  'Ref: Global search modal': '글로벌 검색 모달',
  'Ref: Search a facility': '시설을 검색하십시오',
  'Ref: Search a menu or product': '메뉴 나 제품을 검색하십시오',
  'Ref: Reaction': '반응',
  'Ref: times': '타임스',
  'Ref: reaction. Change reaction': '반응. 반응을 변경하십시오',
  'Ref: No reaction. React': '반응 없음. 반응',
  'Ref: LIKE': '좋다',
  'Ref: LOVE': '사랑',
  'Ref: INTERESTING': '흥미로운',
  'Ref: CONGRATE': '축하합니다',
  'Ref: Open search': '검색 창을 엽니 다',
  'Ref: Close search': '검색 창을 닫습니다',
  'Ref: Total file size msg': '총 파일 크기는 {max file size}를 초과 할 수 없습니다.',
  'Ref: Oops Title': '죄송합니다!',
  'Ref: Well Done': '잘하셨어요 {party_emoji}',
  'Ref: Navigation arrow - Continue': '내비게이션 화살표 - 계속하십시오',
  'Ref: Reload': '새로 고침',
  'Ref: All Dates': '모든 날짜',
  'Ref: Today': '오늘',
  'Ref: Validate': '확인',
  'Ref: toast error title': '죄송합니다!',
  'Ref: toast error subtitle': '에러가 발생했습니다. 다시 시도 해주세요.',
  'Ref: Calendar': '달력',
  'Ref: Selected': '선택된',
  'Ref: Previous Month': '지난달',
  'Ref: Next Month': '다음 달',
  'This module has been retired': '이 모듈은 폐기되었습니다',
};
export default labels;
