import { shade, tint, lighten, darken, transparentize, readableColor } from 'polished';

import { APP_ERRORS, PERMISSION_ERRORS, APP_NAME } from '../../../constants';
import { TranslationProps } from '../../../localization/localization.types';
import { defaultThemeSettings } from '../../../styles/theme.helper';
import { legalDocType, legalDocTypeNamesToCodeMap } from '../config';
import { LegalDocument, ThemeSetting } from '../types/State.types';

import { getAppName } from '@/helpers/misc';
import { Acknowledgement } from '@/types/user.types';

/* 
document type codes:
    224960000: Terms and Conditions
    224960001: Privacy Policy
    224960002: Terms of Sales

returned values:
    undefined: waiting for the data to be fetched
    null: no document found
    true: user should acknowledge
    false: user doesn't need to acknowledge
*/

const ERRORS_LABELS = {
  [APP_ERRORS.INDEX_DB_SUPPORT]: 'Ref: Missing IndexDb support',
  [APP_ERRORS.NOT_FOUND]: 'Ref: Resource not found',
  [APP_ERRORS.SITE_LINK_INVALID]: 'Ref: Site link invalid',
  [APP_ERRORS.FORBIDDEN]: 'Ref: Access Denied',
  [PERMISSION_ERRORS.SITE_HIDDEN]: 'Ref: Permission denied - SITE_HIDDEN',
  [PERMISSION_ERRORS.CONTRACT_DIFFERENT]: 'Ref: Permission denied - CONTRACT_DIFFERENT',
};

export function shouldAcknowledge(
  documentTypeCode: number,
  docList: LegalDocument[],
  acknowledgements: Acknowledgement[]
) {
  if (docList === undefined || acknowledgements === undefined) return undefined;

  let doc = docList.find((el) => el.type.value === documentTypeCode);
  if (doc === undefined) return null;

  let acknowledged = acknowledgements.find((el) => el.documentId === doc?.id);
  if (acknowledged === undefined) return doc.id;

  return false;
}

export function checkDocsToAcknowledge({
  documents,
  acknowledgements,
}: {
  documents: LegalDocument[];
  acknowledgements: Acknowledgement[];
}) {
  const termsOfUseToAcknowledge = shouldAcknowledge(
    legalDocTypeNamesToCodeMap[legalDocType.terms_of_use],
    documents,
    acknowledgements
  );
  const privacyPolicyToAcknowledge = shouldAcknowledge(
    legalDocTypeNamesToCodeMap[legalDocType.privacy_policy],
    documents,
    acknowledgements
  );
  const termsOfSaleToAcknowledge = shouldAcknowledge(
    legalDocTypeNamesToCodeMap[legalDocType.terms_of_sales],
    documents,
    acknowledgements
  );
  return {
    termsOfUseToAcknowledge,
    privacyPolicyToAcknowledge,
    termsOfSaleToAcknowledge,
  };
}

export const checkIfAcknowledgeAfterLegalDocModification = (
  acknowledgements: Acknowledgement[] = [],
  type: string
) => {
  if (!acknowledgements) {
    return false;
  }

  if ([legalDocType.terms_of_use, legalDocType.privacy_policy].includes(type))
    return acknowledgements.some(
      (acknowledge) => acknowledge.type.value === legalDocTypeNamesToCodeMap[type]
    );

  return false;
};

export function isLight(color: string): boolean {
  return readableColor(color) === '#000';
}

// Color keys sent by backend to override theme
// For now, only 3 colors are considered
export const themeKeys = {
  primaryColor: ['mainColor'],
  secondaryColor: ['secondaryColorA', 'secondaryColor'],
  tertiaryColor: ['secondaryColorB', 'tertiaryColor'],
  tertiaryContrastTextColor: ['tertiaryContrastTextColor'],
};

const getThemeTargetElement = (target?: HTMLElement) => target ?? null;

export function applyTheme(settings: ThemeSetting[] = [], target?: HTMLElement) {
  const findColor = (
    themeKeys: string[],
    defaultColor: string | undefined,
    settings: ThemeSetting[] | { key: string; value: string }[] = []
  ) => {
    const themeKey = themeKeys.find((key) => settings.some((el) => el.key === key));
    return themeKey ? settings.find((el) => el.key === themeKey)?.value : defaultColor;
  };

  // Get colors from default env variable set for the app
  const defaultMainColor = findColor(themeKeys.primaryColor, undefined, defaultThemeSettings);
  const defaultSecondaryColor = findColor(
    themeKeys.secondaryColor,
    undefined,
    defaultThemeSettings
  );
  const defaultTertiaryColor = findColor(themeKeys.tertiaryColor, undefined, defaultThemeSettings);

  const element = getThemeTargetElement(target);

  // Get requested theme colors
  const primaryColor = findColor(themeKeys.primaryColor, defaultMainColor, settings);
  const secondaryColor = findColor(themeKeys.secondaryColor, defaultSecondaryColor, settings);
  const tertiaryColor = findColor(themeKeys.tertiaryColor, defaultTertiaryColor, settings);

  // Get tertiary contrast text color
  let tertiaryContrastTextColor = findColor(
    themeKeys.tertiaryContrastTextColor,
    isLight(tertiaryColor || '#FFFFFF') ? '#000000' : '#FFFFFF',
    settings
  );
  if (!tertiaryContrastTextColor) {
    tertiaryContrastTextColor = '#000000';
  }

  if (primaryColor) {
    setCssProperty('--ion-color-primary', primaryColor, element);
    setCssProperty('--ion-color-primary-darker', darken(0.2, primaryColor), element);
    setCssProperty('--ion-color-primary-light', lighten(0.3, primaryColor), element);
    setCssProperty('--ion-color-primary-lighter', lighten(0.5, primaryColor), element);
    setCssProperty('--ion-color-primary-ultralight', lighten(0.55, primaryColor), element);
    setCssProperty('--ion-color-primary-lightest', lighten(0.6, primaryColor), element);
    setCssProperty('--ion-color-primary-shade', adjustToShade(primaryColor), element);
    setCssProperty('--ion-color-primary-tint', adjustToTint(primaryColor), element);
    setCssProperty(
      '--default-shadow',
      `0 2px 4px ${transparentize(0.76, lighten(0.3, primaryColor))}`,
      element
    );
    setCssProperty('--default-background-color', lighten(0.6, primaryColor), element);
  }
  if (secondaryColor) {
    setCssProperty('--ion-color-secondary', secondaryColor, element);
    setCssProperty('--ion-color-secondary-shade', adjustToShade(secondaryColor), element);
    setCssProperty('--ion-color-secondary-tint', adjustToTint(secondaryColor), element);
  }
  if (tertiaryColor) {
    setCssProperty('--ion-color-tertiary', tertiaryColor, element);
    setCssProperty('--ion-color-tertiary-shade', adjustToShade(tertiaryColor), element);
    setCssProperty('--ion-color-tertiary-tint', adjustToTint(tertiaryColor), element);
    setCssProperty('--ion-color-tertiary-light', lighten(0.3, tertiaryColor), element);
  }
  if (tertiaryContrastTextColor) {
    setCssProperty('--ion-color-tertiary-contrast-text', tertiaryContrastTextColor, element);
  }
}

export function removeTheme(target?: HTMLElement) {
  const element = getThemeTargetElement(target);
  unsetCssProperty('--ion-color-primary', element);
  unsetCssProperty('--ion-color-primary-darker', element);
  unsetCssProperty('--ion-color-primary-light', element);
  unsetCssProperty('--ion-color-primary-lighter', element);
  unsetCssProperty('--ion-color-primary-ultralight', element);
  unsetCssProperty('--ion-color-primary-lightest', element);
  unsetCssProperty('--ion-color-primary-shade', element);
  unsetCssProperty('--ion-color-primary-tint', element);

  unsetCssProperty('--default-shadow', element);
  unsetCssProperty('--default-background-color', element);

  unsetCssProperty('--ion-color-secondary', element);
  unsetCssProperty('--ion-color-secondary-shade', element);
  unsetCssProperty('--ion-color-secondary-tint', element);

  unsetCssProperty('--ion-color-tertiary', element);
  unsetCssProperty('--ion-color-tertiary-shade', element);
  unsetCssProperty('--ion-color-tertiary-tint', element);
  unsetCssProperty('--ion-color-tertiary-light', element);
  unsetCssProperty('--ion-color-tertiary-contrast-text', element);
}

export function adjustToShade(hexColor: string) {
  return shade(0.2, hexColor);
}

export function adjustToTint(hexColor: string) {
  return tint(0.2, hexColor);
}

const setCssProperty = (targetCssVar: string, cssValue: string, target?: HTMLElement | null) => {
  target
    ? target.style.setProperty(targetCssVar, cssValue)
    : document.documentElement.style.setProperty(targetCssVar, cssValue);
};

const unsetCssProperty = (targetCssVar: string, target?: HTMLElement | null) => {
  target
    ? target.style.removeProperty(targetCssVar)
    : document.documentElement.style.removeProperty(targetCssVar);
};

export const getLegalDocLabel = (type: string, label: TranslationProps['label']) => {
  switch (type) {
    case legalDocType.terms_of_use:
      return label('TermsOfUse');
    case legalDocType.privacy_policy:
      return label('PrivacyPolicy');
    case legalDocType.terms_of_sales:
      return label('TermsOfSale');
    case legalDocType.imprint:
      return label('Imprint');
    case legalDocType.geolocation:
      return label('Geolocation');
    default:
      return '';
  }
};

export const getErrors = (errors: string[] | undefined, label: TranslationProps['label']) => {
  if (errors?.length) {
    return errors.map((error) => label(ERRORS_LABELS[error] || 'Ref: Generic Error Body'));
  }
  return undefined;
};

export function isMyWay(): boolean {
  const appName = getAppName();
  return appName === APP_NAME.MYWAY;
}

export function isWando(): boolean {
  const appName = getAppName();
  return appName === APP_NAME.WANDO;
}

export function isMyWayAndWando(): boolean {
  const appName = getAppName();
  return appName === APP_NAME.MYWAY || appName === APP_NAME.WANDO;
}

export function isMyVillage(): boolean {
  const appName = getAppName();
  return appName === APP_NAME.MYVILLAGE;
}

export function isBiteKiosk(): boolean {
  const appName = getAppName();
  return appName === APP_NAME.BITEKIOSK;
}
