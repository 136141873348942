import { useMemo } from 'react';
import { useHistory } from 'react-router';

import withSite from '../../../../_deprecated/context/withSite';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import { Tag, Tile } from '../../../../components/molecules/Tile';
import { toLocalDate } from '../../../../helpers/dateTime';
import { isValidHttpUrl } from '../../../../helpers/urlValidator';
import { pagePaths } from '../../config';
import { getMenuItemById } from '../../helpers/productDetails.helper';
import {
  buildLoyaltyPointsForOrderingChip,
  buildLoyaltyRewardChip,
  buildVegeChip,
  getFormattedProductTotals,
} from '../../helpers/productTile.helpers';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { CartItemTileProps } from '../../types/cart.types';
import { PageType } from '../../types/menuCartActions.types';
import MenuCartActions from '../MenuCartActions';
import { getProductIllustration } from '../ProductsList/productList.helper';

const CartItemTile = ({
  id,
  date,
  menuItemId,
  uomId,
  name,
  description,
  img,
  price,
  quantity,
  menu,
  site,
  isVegan,
  isVegetarian,
  isLoyaltyReward,
  isProvidingLoyaltyStamps,
  promotionalDiscounts,
  redeemedFoodItems,
  languageCode,
  pageType,
  itemModifierIds,
  genericCategory,
  isKiosk = false,
}: CartItemTileProps) => {
  const history = useHistory();
  const { label } = useOrderTranslation(__filename);
  const imgInfo =
    img && isValidHttpUrl(img)
      ? {
          src: img,
          alt: name,
        }
      : undefined;
  const image = (
    <ImageWithFallback
      data-testid="cart-item-tile"
      imgInfo={imgInfo}
      imgElement={getProductIllustration(genericCategory as string)}
    />
  );

  const tags: Tag[] = [];
  const vegeChip = buildVegeChip({
    isVegan,
    isVegetarian,
    veganLabel: label('Ref: Vegan'),
    vegeterianLabel: label('Ref: Vegeterian'),
  });
  const isoCode = site?.currency?.isoCode ?? '';

  const loyaltyPointsForOrderingChip = buildLoyaltyPointsForOrderingChip({
    showLoyaltyPointsForOrderingChip: !isKiosk && isProvidingLoyaltyStamps,
    label: label('Ref: Equation for stamps per item'),
  });

  const loyaltyRewardChip = buildLoyaltyRewardChip({
    showLoyaltyRewardChip: !isKiosk && isLoyaltyReward,
    label: label('Ref: Loyalty Reward'),
    ariaLabel: label('Ref: Can be redeemed as a Reward in Loyalty Scheme'),
  });

  vegeChip && tags.push(vegeChip);
  loyaltyPointsForOrderingChip && tags.push(loyaltyPointsForOrderingChip);
  loyaltyRewardChip && tags.push(loyaltyRewardChip);

  const { priceWithoutDiscount, discountPrice } = getFormattedProductTotals({
    uomId,
    price,
    quantity,
    languageCode,
    promotionalDiscounts,
    redeemedFoodItems,
    isoCode,
    itemModifierIds,
  });

  const menuItemPath = useMemo(
    () =>
      pagePaths.ProductDetails.replace(':id', menuItemId.toString())
        .replace(':facilityId', menu.facilityId)
        .replace(':menuId', menu.id.toString())
        .replace(':cartItem', id)
        .replace(':date', date),
    [menuItemId, menu.facilityId, menu.id, id, date]
  );

  const isScannerTile = pageType === PageType.scanner;

  return (
    <Tile
      id={id}
      oneLineTile={isScannerTile}
      title={name}
      description={description}
      oneLineDescription={isScannerTile}
      image={image}
      childText={discountPrice ? discountPrice : priceWithoutDiscount}
      strikethroughChildText={discountPrice ? priceWithoutDiscount : undefined}
      children={
        <MenuCartActions
          menuItem={getMenuItemById(menuItemId, [menu])!}
          facilityId={menu.facilityId}
          date={toLocalDate(date)}
          menuId={menu.id}
          cartMenuItemId={id}
          pageType={pageType}
        />
      }
      chips={tags}
      onClick={() => history?.push(menuItemPath)}
      data-testid={`CartItemTile-${id}`}
    />
  );
};

export default withSite(CartItemTile);
