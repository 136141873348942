import classNames from 'classnames';

import Title, { TITLE_SIZE } from '../../../components/atoms/Title';
import { TranslationProps } from '../../../localization/localization.types';
import { getFormattedPricesForViewable } from '../helpers/productTile.helpers';
import { MenuItem, ProductPortion } from '../types/orderState.types';
import { SiteCurrency } from '../types/productList.types';

import styles from './ProductDetails.module.css';

import { DefaultComponentWithoutChildrenPropsV2 } from 'src/types';

type MenuTitleViewableProps = {
  menuItem: MenuItem | undefined;
  selectedPortion: ProductPortion | null;
  languageCode: string;
  site: { id: string; currency: SiteCurrency };
} & DefaultComponentWithoutChildrenPropsV2 &
  TranslationProps;

export const MenuTitleViewable = ({
  menuItem,
  selectedPortion,
  languageCode,
  site,
  label,
  'data-testid': dataTestId,
}: MenuTitleViewableProps) => {
  const priceFormatted = getFormattedPricesForViewable({
    price: selectedPortion?.price || 0,
    viewablePrices: selectedPortion?.viewablePrices,
    languageCode,
    label,
    isoCode: site.currency.isoCode,
  });

  return (
    <div data-testid={dataTestId}>
      <Title size={TITLE_SIZE.HEADLINES} className={classNames(styles.menuTitle)}>
        {menuItem?.name}
      </Title>
      <Title size={TITLE_SIZE.HEADLINEXS} className={classNames(styles.menuPrice)}>
        {priceFormatted.priceWithoutDiscount}
      </Title>
    </div>
  );
};
