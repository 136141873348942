import classNames from 'classnames';
import { Base64 } from 'js-base64';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { DownloadIcon } from '../../../../assets/icons';
import { TermsAndConditionsIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import { HtmlContent } from '../../../../components/atoms/RenderContent';
import { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { SIZE } from '../../../../constants';
import useGeoCode from '../../../../helpers/hooks/useGeoCode';
import useLoginStatus from '../../../../helpers/hooks/useLoginStatus';
import { saveHtmlAsPdf, updateRelativePathUrl } from '../../../../helpers/misc';
import { State } from '../../../../types/state.types';
import { getAllLegalDocuments, getOneLegalDocument, acknowledgeLegalDoc } from '../../actions';
import { legalDocumentsConfig, legalDocTypeNamesToCodeMap } from '../../config';
import { getLegalDocLabel } from '../../helpers/helpers';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';
import useLanguage from '../../hooks/useLanguage';

import { getLegalDocument } from './LegalDocPage.helpers';

import styles from './LegalDocPage.module.css';

const LegalDocPage = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);
  const { currentGeoCode: geoCode } = useGeoCode();
  const {
    isLoggedIn,
    user: { acknowledgements },
  } = useLoginStatus();

  const { version, document_type: type } = useParams<{ document_type: string; version: string }>();

  const dispatch = useDispatch();

  const { currentLanguageCode: languageCode } = useLanguage();

  const {
    access: { termsOfUseToAcknowledge, privacyPolicyToAcknowledge },
    legalDocs: { list: docsList, detailed: documents },
  } = useSelector((state: State) => state.Core);

  const { documentId, shouldAcknowledge, isAcknowledgeAfterLegalDocModification } =
    getLegalDocument({
      docsList,
      acknowledgements,
      type,
      termsOfUseToAcknowledge,
      privacyPolicyToAcknowledge,
      version,
    });

  console.log('documents', documents);

  const legalDocument = documents[documentId];

  useEffect(() => {
    if (!isLoggedIn) history.replace('/');
    const fetchLegalDoc = async () => {
      let applicableDocumentId = documentId;
      if (!applicableDocumentId && version === 'current') {
        const typeCode = legalDocTypeNamesToCodeMap[type];
        const allLegalDocsResult = await dispatch(getAllLegalDocuments({ geoCode, languageCode }));

        console.log('allLegalDocsResult', allLegalDocsResult);
        applicableDocumentId =
          allLegalDocsResult.responseData?.documents.find(
            (document: any) => document.type?.value === typeCode
          )?.id || '';
      }
      console.log('applicableDocumentId', applicableDocumentId);
      if (!legalDocument) dispatch(getOneLegalDocument({ id: applicableDocumentId }));
    };
    fetchLegalDoc();
  }, [
    dispatch,
    documentId,
    geoCode,
    history,
    isLoggedIn,
    languageCode,
    legalDocument,
    type,
    version,
  ]);

  const { shouldDisplayDownloadButton } = legalDocumentsConfig();

  console.log('legalDocument', legalDocument);

  if (!legalDocument) {
    return <LoadingPage />;
  }

  const notificationContent = (
    <Notification
      look={NOTIFICATION_LOOK.INFO}
      dismissable
      title={label('Ref: Legal changed notification title', {
        replace: { legal_doc_type: getLegalDocLabel(type, label) },
      })}
      inheritStyle={classNames(styles.notificationWrapper)}
    >
      {label('Ref: Legal changed notification content')}
    </Notification>
  );

  return (
    <SimpleFormPage
      hasBackLink={true}
      hideAccountWidget={shouldAcknowledge}
      title={getLegalDocLabel(type, label)}
    >
      <Container>
        <Column.Main>
          {isAcknowledgeAfterLegalDocModification && notificationContent}
          <Card
            overTitle={{
              tag: TITLE_TAG.H1,
              size: TITLE_SIZE.HEADLINES,
              children: getLegalDocLabel(type, label),
              className: styles.legalTitle,
              ...(shouldDisplayDownloadButton
                ? {
                    suffix: (
                      <Button
                        data-testid="legal-docs-save-as-pdf"
                        look="tertiary"
                        onClick={() => saveHtmlAsPdf(Base64.decode(legalDocument))}
                        size={SIZE.SMALL}
                        inheritStyle={styles.titleSuffix}
                      >
                        <DownloadIcon className={classNames(styles.titleIcon)} />
                      </Button>
                    ),
                  }
                : {}),
            }}
          >
            <div
              id="legalDocumentContainer"
              data-cy="legal-document-container"
              className={classNames(styles.wrapper)}
            >
              <HtmlContent data-testid="legal-doc-page-html-content">
                {updateRelativePathUrl(Base64.decode(legalDocument))}
              </HtmlContent>
            </div>
          </Card>
        </Column.Main>
        <Column.Complementary>
          <TermsAndConditionsIllustration />
        </Column.Complementary>
        {shouldAcknowledge ? (
          <ActionsBar>
            <Button
              data-testid="legal-docs-acknowledge"
              onClick={async () => {
                await dispatch(
                  acknowledgeLegalDoc({
                    documentId: documentId,
                    documentTypeCode: legalDocTypeNamesToCodeMap[type],
                  })
                );
                history.replace('/');
              }}
            >
              {label('Acknowledge')}
            </Button>
          </ActionsBar>
        ) : null}
      </Container>
    </SimpleFormPage>
  );
};

export default LegalDocPage;
