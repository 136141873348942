import {
  CONTROL_TYPE,
  ListItemIconProps,
  ListItemProps,
} from '../../../components/organisms/List/List.types';
import { TranslationProps } from '../../../localization/localization.types';
import { FirstFreeItem } from '../types/modifiers.types';
import { AllergenType, Modifier, ModifierItem } from '../types/orderState.types';
import { ModifierSelections, ModifierItemQuantities } from '../types/productDetails.types';

import { displayAllergen } from './allergens.helper';
import { getPrice } from './order.helper';

import styles from '../components/ProductDetails.module.css';

const AllergenIconSize: number = 24;

export enum ModifierType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  QUANTITY = 'quantity',
}

const defineModifierType = (modifier: Modifier): ModifierType => {
  if (
    modifier.isRequired &&
    modifier.selectionLimit === 1 &&
    modifier.modifierItems.every((modifierItem) => modifierItem.min === 0 && modifierItem.max === 1)
  ) {
    return ModifierType.RADIO;
  }
  if (
    modifier.selectionLimit > 1 &&
    modifier.modifierItems.every((modifierItem) => modifierItem.min === 0 && modifierItem.max === 1)
  ) {
    return ModifierType.CHECKBOX;
  }

  return ModifierType.QUANTITY;
};

export const getModifierItems = (
  label: Function,
  modifier: Modifier,
  selectedItems: ModifierSelections[],
  languageCode: string,
  isoCode: string,
  selectedAllergens: AllergenType[],
  usedFirstFreeItems: FirstFreeItem[]
): ListItemProps[] => {
  const modifierType = defineModifierType(modifier);
  const selectedItemIds = selectedItems.map((element) => element.itemIds).flat(1);
  const selectedItemQuantities: ModifierItemQuantities = selectedItems.reduce(
    (acc, { itemQuantities }) => ({ ...acc, ...itemQuantities }),
    {}
  );
  const { min: modifierMin, max: modifierMax } = modifier;

  const currentSelectedModifier = selectedItems.find(
    ({ modifierId }) => modifierId === modifier.id
  );

  const modifierSelectedQuantity = currentSelectedModifier
    ? Object.values(currentSelectedModifier.itemQuantities).reduce((acc, v) => acc + v, 0)
    : 0;

  const isModifierMaxLimit = modifierSelectedQuantity >= modifierMax && modifierMax > 0;
  const isModifierMinLimit = modifierSelectedQuantity <= modifierMin;

  const getPriceLabel = (item: ModifierItem) => {
    return getPrice(item.price, languageCode, isoCode);
  };

  switch (modifierType) {
    case ModifierType.RADIO:
      return createRadioButtonList(
        label,
        modifier,
        selectedItemIds,
        getPriceLabel,
        selectedAllergens,
        usedFirstFreeItems
      );
    case ModifierType.CHECKBOX:
      return createCheckboxList(
        label,
        modifier,
        selectedItemIds,
        isModifierMaxLimit,
        getPriceLabel,
        selectedAllergens,
        usedFirstFreeItems
      );
    case ModifierType.QUANTITY:
      return createQuantitySettersList(
        label,
        modifier,
        selectedItemQuantities,
        isModifierMinLimit,
        isModifierMaxLimit,
        getPriceLabel,
        selectedAllergens,
        usedFirstFreeItems
      );
  }
};

const getFirstFreeLabel = (
  label: Function,
  modifier: Modifier,
  item: ModifierItem,
  usedFirstFree: number
) => {
  let firstFreeLabel = '';

  const hasUnusedFirstFree = modifier.usedFirstFree !== modifier.firstFree;
  const hasValidFirstFree = item.firstFree >= usedFirstFree;

  if (hasUnusedFirstFree && hasValidFirstFree) {
    const remainingFirstFreeModifiers = modifier.firstFree - (modifier.usedFirstFree || 0);
    const remainingFree = item.firstFree - usedFirstFree;
    const remainingAvailableFree = Math.min(remainingFirstFreeModifiers, remainingFree);

    if (remainingAvailableFree > 0) {
      firstFreeLabel = label('Ref: Modifier item number of free items', {
        replace: {
          number_of_free_items: remainingAvailableFree.toString(),
        },
      });
    }
  }

  return firstFreeLabel;
};

const createRadioButtonList = (
  label: Function,
  modifier: Modifier,
  selectedItemIds: string[],
  getPriceLabel: (item: ModifierItem) => string,
  selectedAllergens: AllergenType[],
  usedFirstFreeItems: FirstFreeItem[]
): ListItemProps[] => {
  return modifier.modifierItems.map((item) => {
    const allergenIcons: ListItemIconProps[] = getAllergenIcons(item, selectedAllergens);
    const hasAllergen = item.allergens.some((allergen) => selectedAllergens.includes(allergen.id));
    const matchingFirstFreeItem = usedFirstFreeItems.find((item) => item.id === Number(item.id));

    return {
      id: item.id.toString(),
      label: item.name,
      secondaryLabel: getFirstFreeLabel(
        label,
        modifier,
        item,
        matchingFirstFreeItem?.usedFirstFreeCount ?? 0
      ),
      value: getPriceLabel(item),
      labelClassName: hasAllergen ? styles.warningLabel : styles.nonWarningLabel,
      control: {
        type: CONTROL_TYPE.RADIO,
        props: {
          checked: selectedItemIds.includes(item.id.toString()),
          'data-testid': `modifier-item-${item.id}-radio-button`,
        },
      },
      icons: [...allergenIcons],
      quantity: 1,
      usedFirstFree: matchingFirstFreeItem?.usedFirstFreeCount,
      firstFree: item.firstFree,
      'data-testid': `modifier-item-radio-${item.id}`,
    };
  });
};

const createCheckboxList = (
  label: Function,
  modifier: Modifier,
  selectedItemIds: string[],
  isModifierMaxLimit: boolean,
  getPriceLabel: (item: ModifierItem, modifier: Modifier) => string,
  selectedAllergens: AllergenType[],
  usedFirstFreeItems: FirstFreeItem[]
): ListItemProps[] => {
  return modifier.modifierItems.map((item) => {
    const allergenIcons: ListItemIconProps[] = getAllergenIcons(item, selectedAllergens);
    const hasAllergen = item.allergens?.some((allergen) => selectedAllergens.includes(allergen.id));
    const itemId = item.id.toString();
    const isChecked = selectedItemIds.includes(itemId);
    const matchingFirstFreeItem = usedFirstFreeItems.find((item) => item.id === Number(itemId));

    return {
      id: itemId,
      label: item.name,
      secondaryLabel: getFirstFreeLabel(
        label,
        modifier,
        item,
        matchingFirstFreeItem?.usedFirstFreeCount ?? 0
      ),
      value: getPriceLabel(item, modifier),
      labelClassName: hasAllergen ? styles.warningLabel : styles.nonWarningLabel,
      disabled: isModifierMaxLimit && !isChecked,
      control: {
        type: CONTROL_TYPE.CHECKBOX,
        props: {
          checked: isChecked,
          'data-testid': `modifier-item-${item.id}-checkbox`,
        },
      },
      icons: [...allergenIcons],
      quantity: 1,
      usedFirstFree: matchingFirstFreeItem?.usedFirstFreeCount,
      firstFree: item.firstFree,
      'data-testid': `modifier-item-checkbox-${item.id}`,
    };
  });
};

const createQuantitySettersList = (
  label: Function,
  modifier: Modifier,
  selectedItemQuantities: ModifierItemQuantities,
  isModifierMinLimit: boolean,
  isModifierMaxLimit: boolean,
  getPriceLabel: (item: ModifierItem, modifier: Modifier) => string,
  selectedAllergens: AllergenType[],
  usedFirstFreeItems: FirstFreeItem[]
): ListItemProps[] => {
  return modifier.modifierItems.map((item) => {
    const allergenIcons: ListItemIconProps[] = getAllergenIcons(item, selectedAllergens);
    const hasAllergen = item.allergens?.some((allergen) => selectedAllergens.includes(allergen.id));
    const selectedQuantity = selectedItemQuantities[item.id] || item.min;
    const itemMax = item.max > 0 ? item.max : undefined;
    const groupOrItemMaxLimit = isModifierMaxLimit ? selectedQuantity : itemMax;
    const groupOrItemMinLimit = isModifierMinLimit ? selectedQuantity : item.min;
    const matchingFirstFreeItem = usedFirstFreeItems.find((el) => el.id === Number(item.id));

    return {
      id: item.id.toString(),
      label: item.name,
      secondaryLabel: getFirstFreeLabel(
        label,
        modifier,
        item,
        matchingFirstFreeItem?.usedFirstFreeCount ?? 0
      ),
      value: getPriceLabel(item, modifier),
      labelClassName: hasAllergen ? styles.warningLabel : styles.nonWarningLabel,
      quantity: selectedItemQuantities[item.id],
      control: {
        type: CONTROL_TYPE.QUANTITY,
        props: {
          min: groupOrItemMinLimit,
          max: groupOrItemMaxLimit,
          value: selectedQuantity,
          'data-testid': `modifier-item-${item.id}-quantity-setter`,
        },
      },
      icons: [...allergenIcons],
      usedFirstFree: matchingFirstFreeItem?.usedFirstFreeCount,
      firstFree: item.firstFree,
      'data-testid': `modifier-item-quantity-setter-${item.id}`,
    };
  });
};

const getAllergenIcons = (
  item: ModifierItem,
  selectedAllergens: AllergenType[]
): ListItemIconProps[] => {
  return item.allergens.map((allergen) => {
    const isSelectedAllergen = selectedAllergens.some(
      (selectedAllergen) => selectedAllergen === allergen.id
    );
    return {
      icon: () =>
        displayAllergen(
          allergen.id,
          AllergenIconSize,
          AllergenIconSize,
          isSelectedAllergen ? styles.warningLabelIcon : styles.nonWarningLabelIcon
        ),
      afterLabel: true,
    };
  });
};

export function validateModifiers(
  modifiers: Modifier[] = [],
  selectedModifiers: ModifierSelections[],
  label: TranslationProps['label']
) {
  const errors = modifiers.reduce((acc, modifier) => {
    const { id, min } = modifier;

    const selectedModifier = selectedModifiers.find(({ modifierId }) => modifierId === id);
    const selectedQuantity = Object.values(selectedModifier?.itemQuantities || 0).reduce(
      (acc, value) => acc + value,
      0
    );

    const hasError = selectedQuantity < min;
    return {
      ...acc,
      ...(hasError && {
        [id]: {
          min,
          selectedQuantity,
          errorMessage: `${label('Ref: you have to add at least')} ${min} ${
            min > 1 ? label('Ref: ingredients') : label('Ref: Ingredient')
          }`,
        },
      }),
    };
  }, {});

  return errors;
}
