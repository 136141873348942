import { act, cleanup, fireEvent, screen, waitFor } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { toLocalDate } from '../../../helpers/dateTime';
import { renderedComponent } from '../../../helpers/tests/renderComponent';
import { UserSteps } from '../../../types/userSteps.types';
import {
  cartWithPortion,
  defaultCart,
  defaultCartMenuItem,
  defaultMenuItem,
  defaultProductPortion,
  menuItemWithPortions,
} from '../__mocks/mock';
import { addItemToCart, cleanCart, increaseCartItemQuantity, initializeCart } from '../actions';
import {
  buildProductTotalPrice,
  decrementItem,
  getCartMenuItems,
  getDefaultPortion,
  getMenuItemQuantity,
  hasPortionsOrModifiers,
  increaseItem,
  increaseItemWithModifiersOrPortions,
  isCartAnotherContext,
  redirectWhenPortionsOrModifiers,
  updateCartItem,
} from '../helpers/menuCartActions.helper';
import { CartDifferentContextTypeEnum, PageType } from '../types/menuCartActions.types';

import MenuCartActions from './MenuCartActions';

const mockSelector = jest.fn();
const mockDispatch = jest.fn();
const label = jest.fn();
const mockLogUserSteps = jest.fn();
const mockLogAddingProductToCart = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
  useDispatch: () => mockDispatch,
}));

const mockHistory = createMemoryHistory();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
}));

jest.mock('@/modules/Core/hooks/useLanguage', () => {
  return {
    __esModule: true,
    default: () => 'en-US',
  };
});

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: mockLogUserSteps,
  }),
}));

jest.mock('@/modules/Order/hooks/useProductInsightsLogging/useProductInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logAddingProductToCart: mockLogAddingProductToCart,
  }),
}));

jest.mock('../actions', () => {
  return {
    initializeCart: jest.fn(),
    addItemToCart: jest.fn(),
    removeItemsFromCart: jest.fn(),
    increaseCartItemQuantity: jest.fn(),
    decreaseCartItemQuantity: jest.fn(),
    cleanCart: jest.fn(),
  };
});

jest.mock('../helpers/menuCartActions.helper', () => {
  return {
    decrementItem: jest.fn(),
    getDefaultPortion: jest.fn(),
    getCartMenuItems: jest.fn(),
    hasPortionsOrModifiers: jest.fn(),
    getMenuItemQuantity: jest.fn(),
    isCartAnotherContext: jest.fn(),
    buildProductTotalPrice: jest.fn(),
    increaseItem: jest.fn(),
    increaseCartItemQuantity: jest.fn(),
    redirectWhenPortionsOrModifiers: jest.fn(),
    increaseItemWithModifiersOrPortions: jest.fn(),
    updateCartItem: jest.fn(),
  };
});
jest.mock('../helpers//order.helper', () => ({
  ...jest.requireActual('../helpers//order.helper'),
  getPrice: (price: any) => price.toString(),
}));

const mockedMenus = [
  {
    id: 12,
    facilityId: '',
    name: '',
    isOrderable: true,
    isScanAndGo: false,
    date: new Date().toString(),
    menuItems: [defaultMenuItem, menuItemWithPortions],
  },
];

const mockedCart = defaultCart;
const currentLanguageCode = 'en-US';
const mockState = {
  Core: {
    context: {
      site: {
        id: '',
        name: '',
        currency: { isoCode: 'USD' },
      },
    },
  },
  Shared: {
    language: {
      currentLanguageCode,
    },
  },
  Order: { locks: { createOrderDraft: false }, menus: mockedMenus },
};

describe('MenuCartActions', () => {
  afterEach(() => cleanup());

  describe('empty cart, product without portions or modifiers', () => {
    const props = {
      menuItem: {
        ...defaultMenuItem,
        productPortions: [defaultProductPortion],
      },
      label: label,
      facilityId: '',
      date: new Date(),
      menuId: 12,
      pageType: PageType.productsList,
      isSuggestions: false,
    };

    beforeEach(async () => {
      initializeCart as jest.Mock;
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 0,
        totalPriceLabel: '$0.00',
      });

      mockSelector.mockReturnValue(mockState);
      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display add button', async () => {
      expect(
        screen.getByTestId(`menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`)
      ).toBeTruthy();
      expect(screen.getByText('Ref: Add')).toBeTruthy();
    });

    it('should initialize cart, when add button is clicked', async () => {
      const addButton = screen.getByTestId(
        `menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(addButton);
      });
      expect(initializeCart).toBeCalled();
    });

    it('should log increment, when add button is clicked', async () => {
      const addButton = screen.getByTestId(
        `menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(addButton);
      });
      expect(mockLogUserSteps).toHaveBeenCalledWith({
        event: UserSteps.AddedToCart,
        menuId: props.menuId,
      });
      expect(mockLogAddingProductToCart).toHaveBeenCalledWith(
        props.menuId,
        props.menuItem.productPortions[0].uomId,
        props.pageType,
        props.isSuggestions
      );
    });
  });

  describe('cart is not empty, product without portions or modifiers', () => {
    const props = {
      menuItem: {
        ...defaultMenuItem,
        menuItemId: 2,
        productPortions: [{ ...defaultProductPortion, foodItemId: 2 }],
      },
      label: label,
      facilityId: '',
      date: toLocalDate(mockedCart.date),
      menuId: 12,
      pageType: PageType.productsList,
    };

    beforeEach(async () => {
      increaseItem as jest.Mock;
      (getCartMenuItems as jest.Mock).mockReturnValue([]);
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 11,
        totalPriceLabel: '$11.00',
      });

      mockSelector.mockReturnValue({
        ...mockState,
        Order: { ...mockState.Order, cart: mockedCart },
      });
      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display add button', async () => {
      expect(
        screen.getByTestId(`menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`)
      ).toBeTruthy();
      expect(screen.getByText('Add')).toBeTruthy();
    });

    it('should add product to cart, when add button is clicked', async () => {
      const addButton = screen.getByTestId(
        `menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(addButton);
      });

      expect(mockLogUserSteps).toHaveBeenCalledWith({
        event: UserSteps.AddedToCart,
        menuId: props.menuId,
      });
      expect(increaseItem).toBeCalled();
    });

    it('should log increment, when add button is clicked', async () => {
      const addButton = screen.getByTestId(
        `menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(addButton);
      });

      expect(mockLogUserSteps).toHaveBeenCalledWith({
        event: UserSteps.AddedToCart,
        menuId: props.menuId,
      });
    });
  });

  describe('product without portions or modifiers added to cart', () => {
    const props = {
      menuItem: {
        ...defaultMenuItem,
        price: 20,
        minimalPrice: 20,
        productPortions: [{ ...defaultProductPortion, price: 20 }],
      },
      label: label,
      facilityId: '',
      date: toLocalDate(mockedCart.date),
      menuId: 12,
      pageType: PageType.productsList,
    };

    beforeEach(async () => {
      initializeCart as jest.Mock;
      addItemToCart as jest.Mock;
      cleanCart as jest.Mock;
      decrementItem as jest.Mock;
      (getDefaultPortion as jest.Mock).mockReturnValue(defaultProductPortion);
      (getCartMenuItems as jest.Mock).mockReturnValue([defaultCartMenuItem]);
      (hasPortionsOrModifiers as jest.Mock).mockReturnValue(false);
      (getMenuItemQuantity as jest.Mock).mockReturnValue(1);
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 20,
        totalPriceLabel: '$20.00',
      });
      increaseItem as jest.Mock;
      increaseCartItemQuantity as jest.Mock;

      mockSelector.mockReturnValue({
        ...mockState,
        Order: { ...mockState.Order, cart: mockedCart },
      });
      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display quantity setter', async () => {
      expect(screen.getByTestId('menu-cart-actions-quantity-setter-wrapper')).toBeTruthy();
      expect(screen.getByTestId('menu-cart-actions-quantity-setter-decrement-button')).toBeTruthy();
      expect(screen.getByTestId('menu-cart-actions-quantity-setter-increment-button')).toBeTruthy();
      expect(screen.getByText('1')).toBeTruthy();
    });

    it('should increase product quantity, when plus button is clicked', async () => {
      const plusButton = screen.getByTestId('menu-cart-actions-quantity-setter-increment-button');
      await act(async () => {
        fireEvent.click(plusButton);
      });
      expect(increaseItem).toBeCalled();
    });

    it('should log increment, when plus button is clicked', async () => {
      const plusButton = screen.getByTestId('menu-cart-actions-quantity-setter-increment-button');
      await act(async () => {
        fireEvent.click(plusButton);
      });

      expect(mockLogUserSteps).toHaveBeenCalledWith({
        event: UserSteps.AddedToCart,
        menuId: props.menuId,
      });
    });

    it('should remove product from cart, when minus button is clicked', async () => {
      const minusButton = screen.getByTestId('menu-cart-actions-quantity-setter-decrement-button');
      await act(async () => {
        fireEvent.click(minusButton);
      });
      expect(cleanCart).toBeCalled();
    });
  });

  describe('product with portions or modifiers, product list page', () => {
    const props = {
      menuItem: menuItemWithPortions,
      label: label,
      facilityId: '',
      date: new Date(),
      menuId: 12,
      pageType: PageType.productsList,
    };

    beforeEach(async () => {
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (hasPortionsOrModifiers as jest.Mock).mockReturnValue(true);
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 0,
        totalPriceLabel: '$0.00',
      });
      redirectWhenPortionsOrModifiers as jest.Mock;

      mockSelector.mockReturnValue(mockState);
      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display customize button', async () => {
      expect(
        screen.getByTestId(`menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`)
      ).toBeTruthy();
      expect(screen.getByText('Customize')).toBeTruthy();
    });

    it('should redirect to product details page, when customize button is clicked', async () => {
      const customizeButton = screen.getByTestId(
        `menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(customizeButton);
      });
      expect(redirectWhenPortionsOrModifiers).toBeCalled();
    });

    it('should not log increment, when customize button is clicked', async () => {
      const customizeButton = screen.getByTestId(
        `menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(customizeButton);
      });
      expect(mockLogUserSteps).not.toHaveBeenCalled();
    });
  });

  describe('product with different portions or modifiers added to cart, product list page', () => {
    const props = {
      menuItem: menuItemWithPortions,
      label: label,
      facilityId: '',
      date: toLocalDate(cartWithPortion.date),
      menuId: 12,
      pageType: PageType.productsList,
    };

    beforeEach(async () => {
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (hasPortionsOrModifiers as jest.Mock).mockReturnValue(true);
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 11,
        totalPriceLabel: '$11.00',
      });
      (getMenuItemQuantity as jest.Mock).mockReturnValue(2);
      (getCartMenuItems as jest.Mock).mockReturnValue([
        {
          ...defaultCartMenuItem,
          menuItemId: 2,
          uomId: 222,
          foodItemId: 2,
          price: 11,
          isVegan: true,
          isVegetarian: true,
        },
        {
          ...defaultCartMenuItem,
          menuItemId: 2,
          uomId: 333,
          foodItemId: 3,
          price: 11,
          isVegan: true,
          isVegetarian: true,
        },
      ]);
      redirectWhenPortionsOrModifiers as jest.Mock;

      mockSelector.mockReturnValue({
        ...mockState,
        Order: {
          ...mockState.Order,
          cart: {
            ...cartWithPortion,
            menuPortionItems: [
              cartWithPortion.menuPortionItems?.[0]!,
              cartWithPortion.menuPortionItems?.[1]!,
              {
                ...cartWithPortion.menuPortionItems?.[1]!,
                menuItemId: 2,
                uomId: 333,
                foodItemId: 3,
                price: 11,
                isVegan: true,
                isVegetarian: true,
              },
            ],
          },
        },
      });

      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display quantity setter with disabled decrement button', async () => {
      expect(screen.getByTestId('menu-cart-actions-quantity-setter-decrement-button')).toBeTruthy();
      expect(screen.getByTestId('menu-cart-actions-quantity-setter-increment-button')).toBeTruthy();
      expect(
        screen.getByTestId('menu-cart-actions-quantity-setter-decrement-button')
      ).toHaveAttribute('aria-disabled', 'true');
    });

    it('should display popup when plus button is clicked', async () => {
      const plusButton = screen.getByTestId('menu-cart-actions-quantity-setter-increment-button');
      await act(async () => {
        fireEvent.click(plusButton);
      });

      waitFor(() => expect(screen.getByTestId('menu-cart-actions-popup-warning')).toBeTruthy());
    });

    it('should not increase product quantity, when plus button is clicked', async () => {
      const plusButton = screen.getByTestId('menu-cart-actions-quantity-setter-increment-button');
      await act(async () => {
        fireEvent.click(plusButton);
      });

      waitFor(() => expect(increaseItem).not.toHaveBeenCalled());
    });

    it('should not log increment, when plus button is clicked', async () => {
      const plusButton = screen.getByTestId('menu-cart-actions-quantity-setter-increment-button');
      await act(async () => {
        fireEvent.click(plusButton);
      });

      expect(mockLogUserSteps).not.toHaveBeenCalled();
    });
  });

  describe('product with portions not added to cart, product details page', () => {
    const props = {
      menuItem: menuItemWithPortions,
      label: label,
      facilityId: '',
      date: new Date(),
      menuId: 12,
      pageType: PageType.productDetails,
    };

    beforeEach(async () => {
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (hasPortionsOrModifiers as jest.Mock).mockReturnValue(true);
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 11,
        totalPriceLabel: '$11.00',
      });
      increaseItemWithModifiersOrPortions as jest.Mock;

      mockSelector.mockReturnValue(mockState);
      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display add to cart button', async () => {
      expect(
        screen.getByTestId(`menu-cart-actions-cart-add-${props.menuItem.menuItemId}`)
      ).toBeTruthy();
      expect(screen.getByText('Add to cart - $11.00')).toBeTruthy();
    });

    it('should add item to cart, when button is clicked', async () => {
      const addToCartButton = screen.getByTestId(
        `menu-cart-actions-cart-add-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(addToCartButton);
      });
      expect(increaseItemWithModifiersOrPortions).toBeCalled();
    });

    it('should log increment, when button is clicked', async () => {
      const addToCartButton = screen.getByTestId(
        `menu-cart-actions-cart-add-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(addToCartButton);
      });

      expect(mockLogUserSteps).toHaveBeenCalledWith({
        event: UserSteps.AddedToCart,
        menuId: props.menuId,
      });
    });
  });

  describe('redeemable product with portions not added to cart, product details page', () => {
    const props = {
      menuItem: menuItemWithPortions,
      label: label,
      facilityId: '',
      date: new Date(),
      menuId: 12,
      pageType: PageType.productDetails,
      redeemableQuantity: 2,
    };

    beforeEach(async () => {
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (hasPortionsOrModifiers as jest.Mock).mockReturnValue(true);
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 11,
        totalPriceLabel: '$11.00',
      });
      increaseItemWithModifiersOrPortions as jest.Mock;

      mockSelector.mockReturnValue(mockState);
      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display add to cart button with free label', async () => {
      expect(
        screen.getByTestId(`menu-cart-actions-cart-add-${props.menuItem.menuItemId}`)
      ).toBeTruthy();
      expect(screen.getByText('Add to cart - Free')).toBeTruthy();
    });
  });

  describe('product with portions added to cart, product details page', () => {
    const props = {
      menuItem: menuItemWithPortions,
      label: label,
      facilityId: '',
      date: new Date(),
      menuId: 12,
      pageType: PageType.productDetails,
    };

    beforeEach(async () => {
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (hasPortionsOrModifiers as jest.Mock).mockReturnValue(true);
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 11,
        totalPriceLabel: '$11.00',
      });
      (getMenuItemQuantity as jest.Mock).mockReturnValue(1);
      (getCartMenuItems as jest.Mock).mockReturnValue([
        {
          ...defaultCartMenuItem,
          menuItemId: 2,
          uomId: 222,
          foodItemId: 2,
          price: 11,
          isVegan: true,
          isVegetarian: true,
        },
      ]);

      mockSelector.mockReturnValue({
        ...mockState,
        Order: { ...mockState.Order, cart: cartWithPortion },
      });

      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display quantity setter and cart button', async () => {
      expect(screen.getByTestId('menu-cart-actions-quantity-setter-decrement-button')).toBeTruthy();
      expect(screen.getByTestId('menu-cart-actions-quantity-setter-increment-button')).toBeTruthy();
      expect(
        screen.getByTestId(`menu-cart-actions-cart-redirect-${props.menuItem.menuItemId}`)
      ).toBeTruthy();
    });
  });

  describe('different portions of the same product are in the cart, coming from cart to PD page of one of the portions', () => {
    const props = {
      menuItem: menuItemWithPortions,
      selectedPortion: { ...defaultProductPortion, uomId: 333, foodItemId: 3 },
      selectedModifiers: [],
      cartMenuItemId: '12345',
      label: label,
      facilityId: '',
      date: new Date(),
      menuId: 12,
      pageType: PageType.productDetails,
    };

    const cartWithPortions = {
      ...cartWithPortion,
      menuPortionItems: [
        cartWithPortion.menuPortionItems?.[0]!,
        cartWithPortion.menuPortionItems?.[1]!,
        {
          ...cartWithPortion.menuPortionItems?.[1]!,
          menuItemId: 2,
          uomId: 333,
          foodItemId: 3,
          price: 15,
          isVegan: true,
          isVegetarian: true,
          quantity: 2,
        },
      ],
    };

    beforeEach(async () => {
      (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
      (hasPortionsOrModifiers as jest.Mock).mockReturnValue(true);
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 30,
        totalPriceLabel: '$30.00',
      });
      (getMenuItemQuantity as jest.Mock).mockReturnValue(1);
      (getCartMenuItems as jest.Mock).mockReturnValue([
        {
          ...defaultCartMenuItem,
          id: '12345',
          menuItemId: 2,
          uomId: 222,
          foodItemId: 2,
          price: 11,
          isVegan: true,
          isVegetarian: true,
        },
      ]);

      mockSelector.mockReturnValue({
        ...mockState,
        Order: {
          ...mockState.Order,
          cart: cartWithPortions,
        },
      });

      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should update cart item, when different portion is selected on PD page', async () => {
      waitFor(() =>
        expect(updateCartItem).toHaveBeenCalledWith({
          cartMenuItem: {
            ...defaultCartMenuItem,
            id: '12345',
            menuItemId: 2,
            uomId: 222,
            foodItemId: 2,
            price: 11,
            isVegan: true,
            isVegetarian: true,
          },
          selectedPortion: props.selectedPortion,
          selectedModifiers: props.selectedModifiers,
          cartItemPrice: 15,
          dispatch: mockDispatch,
          cart: cartWithPortions,
        })
      );
    });
  });

  describe('different cart context', () => {
    const props = {
      menuItem: { ...defaultMenuItem, menuItemId: 2 },
      label: label,
      facilityId: '',
      date: toLocalDate(new Date('202$-02-06')),
      menuId: 12,
      pageType: PageType.productsList,
    };

    beforeEach(async () => {
      increaseItem as jest.Mock;
      (getCartMenuItems as jest.Mock).mockReturnValue([]);
      (isCartAnotherContext as jest.Mock).mockReturnValue({
        isAnotherOrderContext: true,
        reason: CartDifferentContextTypeEnum.DifferentDate,
      });
      (buildProductTotalPrice as jest.Mock).mockReturnValue({
        totalPrice: 0,
        totalPriceLabel: '$0.00',
      });

      mockSelector.mockReturnValue({
        ...mockState,
        Order: { ...mockState.Order, cart: mockedCart },
      });
      await act(async () => {
        renderedComponent(MenuCartActions, props);
      });
    });

    it('should display popup, when add button is clicked', async () => {
      const addButton = screen.getByTestId(
        `menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(addButton);
      });
      expect(screen.getByTestId('cart-another-context-popup')).toBeTruthy();
    });

    it('should not log increment, when add button is clicked', async () => {
      const addButton = screen.getByTestId(
        `menu-cart-actions-add-to-cart-button-${props.menuItem.menuItemId}`
      );
      await act(async () => {
        fireEvent.click(addButton);
      });

      expect(mockLogUserSteps).not.toHaveBeenCalled();
    });
  });
});
