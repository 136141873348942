import { useMemo } from 'react';

import { TranslationProps } from '../../../../localization/localization.types';
import { SuggestedMenuItem } from '../../types/orderState.types';

import { defaultDropdownOption } from './SuggestionsWidget.helper';

export const useMenuBasedSuggestions = (
  selectedValue: string,
  suggestionsWithAvailableMenus: SuggestedMenuItem[],
  label: TranslationProps['label']
) => {
  return useMemo(() => {
    if (selectedValue === defaultDropdownOption(label).value) {
      return suggestionsWithAvailableMenus;
    } else {
      return suggestionsWithAvailableMenus.filter(
        (item) => item.menuId.toString() === selectedValue
      );
    }
  }, [selectedValue, suggestionsWithAvailableMenus, label]);
};
