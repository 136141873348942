import classNames from 'classnames';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FormLine from '../../../components/atoms/FormLine/FormLine';
import { PasswordInput, HookInput as Input } from '../../../components/atoms/Input';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title';
import Card from '../../../components/molecules/Card/Card';
import Form from '../../../components/molecules/Form';
import { requiredRule, trimFields } from '../../../components/molecules/Form/helpers';
import { BAR_POSITION } from '../../../components/templates/Layout/BottomBar';
import Layout, { MAIN_WIDTH } from '../../../components/templates/Layout/Layout';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import { INPUT_TYPE } from '../../../constants';
import useGeoCode from '../../../helpers/hooks/useGeoCode';
import useLoginStatus from '../../../helpers/hooks/useLoginStatus';
import { State } from '../../../types/state.types';
import { login, migrateOldBiteUser } from '../actions';
import { pagePaths } from '../config';
import { useCoreTranslation } from '../hooks/useCoreTranslation';
import useLanguage from '../hooks/useLanguage';
import { REDIRECTED_FROM } from '../types/accountValidation.types';
import { MIGRATION_STATUS } from '../types/passwordChange.types';
import { PasswordChangeFormFields } from '../types/passwordChange.types';

const formDefaultValues = {
  password: '',
  confirmPassword: '',
};

const PasswordChange = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);

  const dispatch = useDispatch();
  const {
    user: {
      preferredLanguage: { id: preferredLanguageId },
    },
  } = useLoginStatus();
  const { firstName, lastName, email, oldPassword } = useSelector(
    (state: State) =>
      state.Core.userMigration || { firstName: '', lastName: '', email: '', oldPassword: '' }
  );
  const userFullName = `${firstName} ${lastName}`;

  const { currentGeoCode } = useGeoCode();
  const { currentLanguageCode } = useLanguage();

  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    setError,
    formState: { isValid },
    control,
  } = useForm<PasswordChangeFormFields>({
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const onSubmit: SubmitHandler<PasswordChangeFormFields> = async (data) => {
    setSubmitButtonIsLoading(true);

    const trimmedData = trimFields<PasswordChangeFormFields>(data);

    if (trimmedData.password !== trimmedData.confirmPassword) {
      setError('confirmPassword', {
        type: 'custom',
        message: label('Ref: Password does not match'),
      });
      setSubmitButtonIsLoading(false);
      return;
    } else {
      const migrationResult = await dispatch(
        migrateOldBiteUser({
          email,
          password: oldPassword,
          newPassword: trimmedData.password,
          languageId: preferredLanguageId,
        })
      );

      if (
        migrationResult.responseStatus === 200 &&
        migrationResult.responseData.status === MIGRATION_STATUS.USER_MIGRATED
      ) {
        const secondLoginResult = await dispatch(
          login({
            geoCode: currentGeoCode,
            username: email,
            psw: trimmedData.password,
            currentLanguageCode,
            scope: 'offline_access',
          })
        );

        if (secondLoginResult.responseStatus === 200) {
          history.push({
            pathname: pagePaths['Validation'],
            search: `${new URLSearchParams({
              redirectedFrom: REDIRECTED_FROM.PASSWORD_CHANGE,
            })}`,
          });
          return;
        }
        return;
      }
      setSubmitButtonIsLoading(false);
      return;
    }
  };

  const firstColumn = (
    <>
      <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES} className="mb-M">
        {`${label('Welcome', {
          textTransform: 'capitalize',
        })} ${userFullName}`}
      </Title>
      <div className={classNames('bodyMDefault', 'mb-M')}>
        {label('Ref: Password message', {
          textTransform: 'capitalize',
        })}
      </div>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormLine data-testid="password-change">
            <PasswordInput
              control={control}
              name="password"
              inputLabel={label('Password')}
              labelFunc={label}
              data-cy="input-password"
              data-testid="password-change-new"
              autocomplete="new-password"
              rules={requiredRule(label('New password'), label)}
            />
          </FormLine>
          <FormLine data-testid="password-change-confirmation">
            <Input
              control={control}
              name="confirmPassword"
              inputLabel={label('Confirm password')}
              inputType={INPUT_TYPE.PASSWORD}
              data-cy="input-password-confirm"
              data-testid="password-change-confirmation"
              autocomplete="new-password"
              rules={requiredRule(label('Confirm password'), label)}
            />
          </FormLine>
        </Form>
      </Card>
    </>
  );
  const action = [
    {
      label: label('Continue', {
        textTransform: 'capitalize',
      }),
      action: () => {
        handleSubmit(onSubmit)();
      },
      look: isValid ? 'primary' : 'inactive',
      loading: submitButtonIsLoading,
    },
  ];

  return (
    <SimpleFormPage
      title={label('Change Password')}
      hasBackLink={false}
      hideAllWidgets
      actions={[]}
      actionsBarTopContent={null}
      withNavBar={false}
    >
      <Layout
        mainWidth={MAIN_WIDTH.WIDE}
        bottomBar={{
          type: 'actionsBar',
          actions: action,
          position: BAR_POSITION.DYNAMIC,
        }}
      >
        {firstColumn}
      </Layout>
    </SimpleFormPage>
  );
};

export default PasswordChange;
