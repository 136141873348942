import { Capacitor } from '@capacitor/core';
import { Dispatch } from 'redux';

import { WarningIcon } from '../../../assets/icons';
import { TranslationProps } from '../../../localization/localization.types';
import { store } from '../../../store';
import { initializeCart } from '../actions';
import { MenuItem, OrderState, ProductPortion } from '../types/orderState.types';

import {
  getCartMenuItems,
  hasPortionsOrModifiers,
  increaseItem,
  isCartAnotherContext,
} from './menuCartActions.helper';

import toast from '@/components/atoms/ModalToast';

export const onProductScanned = ({
  scannedProduct,
  addedToCart,
  onAddFirstItemToCart,
  label,
}: {
  scannedProduct?: MenuItem;
  addedToCart: boolean;
  onAddFirstItemToCart?: () => void;
} & TranslationProps) => {
  const isNativePlatform = Capacitor.isNativePlatform();
  if (scannedProduct) {
    if (addedToCart) {
      toast.success(
        `${scannedProduct.name} ${label(
          isNativePlatform ? 'Ref: Scan or adjust' : 'Ref: Product Scanned'
        )}`,
        {
          icon: <WarningIcon />,
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          closeButton: false,
        }
      );
      onAddFirstItemToCart && onAddFirstItemToCart();
    } else {
      if (isNativePlatform) {
        toast.error(label('Ref: Native error product cannot be added to cart'), {
          icon: <WarningIcon />,
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          closeButton: false,
        });
      }
    }
  } else {
    toast.error(label('Ref: Product Not Matched'), {
      icon: <WarningIcon />,
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  }
};

export const tryAddScannedItemToCart = async ({
  date,
  menuId,
  scannedProduct,
  scannedPortion,
  facilityId,
  siteId,
  dispatch,
}: {
  date: Date;
  menuId: number;
  scannedProduct: MenuItem;
  scannedPortion: ProductPortion;
  facilityId: string;
  siteId: string;
  dispatch: Dispatch;
}) => {
  const orderState = store.getState().Order as OrderState;
  const { cart } = orderState;

  const cartContextInfo = isCartAnotherContext({
    date,
    menuId,
    menuItem: scannedProduct,
    isCartView: false,
    cart,
  });

  if (cartContextInfo.isAnotherOrderContext) {
    return cartContextInfo;
  }

  const portionsOrModifiers = hasPortionsOrModifiers(scannedProduct);
  const cartMenuItems = cart
    ? getCartMenuItems(
        scannedProduct,
        cart,
        undefined,
        scannedPortion,
        undefined,
        portionsOrModifiers,
        true
      )
    : [];

  if (!cart?.menuPortionItems) {
    await dispatch(
      initializeCart({
        cart: {
          date: date,
          facilityId: facilityId,
          menuId: menuId,
          siteId: siteId,
          menuPortionItems: cartMenuItems,
          moment: scannedProduct.mealName,
        },
      })
    );
  }

  await increaseItem({
    cartMenuItems,
    menuItem: scannedProduct,
    portion: scannedPortion,
    cartItemPrice: scannedPortion?.price ?? scannedProduct.price ?? 0,
    dispatch,
  });
};
