import classNames from 'classnames';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import withSite from '../../../_deprecated/context/withSite';
import {
  DefaultFailIllustration,
  ThankYouLocalIllustration,
  ThankYouPickupIllustration,
} from '../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../components/atoms/Button';
import FormLine from '../../../components/atoms/FormLine/FormLine';
import { EmailInput } from '../../../components/atoms/Input';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title';
import Form from '../../../components/molecules/Form';
import List from '../../../components/organisms/List/List';
import { CONTROL_TYPE } from '../../../components/organisms/List/List.types';
import Modal from '../../../components/organisms/Modal';
import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import useToggle from '../../../helpers/hooks/useToggle';
import { pagePaths } from '../../Core/config';
import { useEmailReceiptMutation, usePrintReceiptMutation } from '../api';
import { orderConfig } from '../config';
import useResetKioskSession from '../hooks/useClearKioskSession/useResetKioskSession';
import { DeliveryOptionType } from '../types/cart.types';
import {
  EmailReceipt,
  GetReceiptType,
  KioskOrderProps,
  KioskOrderVariant,
} from '../types/KioskOrder.types';

import styles from './KioskOrder.module.css';

const KioskOrder = ({ variant, deliveryOption, orderId, site, label }: KioskOrderProps) => {
  const history = useHistory();
  const [resetSession] = useResetKioskSession();

  const {
    state: isReceiptModalOpened,
    toggleOn: openReceiptModal,
    toggleOff: hideReceiptModal,
  } = useToggle(false);

  const [getReceiptMethod, setReceiptMethod] = useState<GetReceiptType>(GetReceiptType.Print);
  const [printReceipt] = usePrintReceiptMutation();
  const [emailReceipt] = useEmailReceiptMutation();
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState<boolean>(false);

  const { isKioskTitleBar } = orderConfig();

  const isKioskReceiptSupported = process.env.REACT_APP_KIOSK_RECEIPT_ENABLED === 'true';

  const defineOrderActions = (variant: KioskOrderVariant) => {
    let orderActions = [];

    if (variant === KioskOrderVariant.SUCCESS && isKioskReceiptSupported) {
      orderActions.push({
        label: label('Ref: Get your receipt'),
        action: () => {
          openReceiptModal();
        },
        look: BUTTON_LOOK.PRIMARY,
      });
    }

    orderActions.push({
      label:
        variant === KioskOrderVariant.FAIL
          ? label('Ref: Kiosk Fail Action')
          : label('Ref: Success Order Action'),
      action: () => {
        resetSession();
      },
      look: BUTTON_LOOK.SECONDARY,
    });

    return orderActions;
  };

  const getReceiptOptions = [
    {
      id: GetReceiptType.Print,
      label: label('Ref: Print'),
      'data-testid': 'print-receipt',
      control: {
        type: CONTROL_TYPE.RADIO,
        props: {
          checked: getReceiptMethod === GetReceiptType.Print,
          'data-testid': 'print-receipt-radio-button',
        },
      },
    },
    {
      id: GetReceiptType.Mail,
      label: label('Ref: Email'),
      'data-testid': 'email',
      control: {
        type: CONTROL_TYPE.RADIO,
        props: {
          checked: getReceiptMethod === GetReceiptType.Mail,
          'data-testid': 'email-receipt-radio-button',
        },
      },
    },
  ];

  const handleReceipt = async (
    isPrint: boolean,
    siteId: string,
    orderId: string,
    email?: string
  ) => {
    let result;
    if (isPrint) {
      result = await printReceipt({ siteId: siteId, orderId: orderId });
    } else {
      if (!!email) {
        result = await emailReceipt({ siteId: siteId, orderId: orderId, email: email });
      } else {
        return;
      }
    }
    if (result && 'error' in result) {
      history.push(pagePaths.GenericErrorPage);
    } else {
      resetSession();
    }
  };

  const {
    handleSubmit,
    formState: { isValid },
    control,
    clearErrors,
  } = useForm<EmailReceipt>({
    mode: 'onChange',
    defaultValues: { emailAddress: '' },
  });

  const onRadioButtonChange = (id: GetReceiptType) => {
    if (id === GetReceiptType.Print) {
      clearErrors('emailAddress');
    }
    setReceiptMethod(id);
  };

  const onSubmit: SubmitHandler<EmailReceipt> = async (data) => {
    setSubmitButtonIsLoading(true);

    await handleReceipt(
      getReceiptMethod === GetReceiptType.Print,
      site.id,
      orderId!.toString(),
      data.emailAddress
    );
    hideReceiptModal();

    setSubmitButtonIsLoading(false);
  };

  return (
    <>
      <NoticePage
        withNavBar={false}
        withTitleBar={false}
        pageTitle={null}
        img={
          variant === KioskOrderVariant.FAIL ? (
            <DefaultFailIllustration />
          ) : deliveryOption === DeliveryOptionType.PickupOption ? (
            <ThankYouPickupIllustration />
          ) : (
            <ThankYouLocalIllustration />
          )
        }
        title={
          variant === KioskOrderVariant.FAIL
            ? label('Ref: Kiosk Fail Title')
            : label('Ref: Success Order Title')
        }
        children={
          <div className={styles.container}>
            <Title size={TITLE_SIZE.BODYSDEFAULT}>
              {variant === KioskOrderVariant.FAIL
                ? label('Ref: Kiosk Fail Description')
                : label('Ref: Success Order Description')}
            </Title>
            {variant !== KioskOrderVariant.FAIL && (
              <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINEXS}>
                {label('Ref: Success Order ID')}: {orderId}
              </Title>
            )}
          </div>
        }
        actions={defineOrderActions(variant)}
        hideAccountWidget={isKioskTitleBar}
        hideNotificationsWidget={isKioskTitleBar}
      />
      <Modal
        isOpen={isReceiptModalOpened}
        onDismiss={hideReceiptModal}
        title={label('Ref: Get your receipt')}
        footer={
          <Button
            inheritStyle={classNames(styles.actionButton)}
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
            loading={submitButtonIsLoading}
            data-testid={'receipt-modal-confirm-button'}
            disabled={!(getReceiptMethod === GetReceiptType.Print || isValid)}
          >
            {label('Ref: Confirm')}
          </Button>
        }
        data-testid={'receipt-modal'}
      >
        <Title size={TITLE_SIZE.BODYMBOLD} tag={TITLE_TAG.H2} className={classNames('mb-M')}>
          {label('Ref: Receipt Modal message')}
        </Title>
        <List
          key={''}
          data-testid="get-receipt-options-list"
          items={getReceiptOptions}
          onChange={(_, id) => {
            onRadioButtonChange(id as GetReceiptType);
          }}
        />
        {getReceiptMethod === GetReceiptType.Mail && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormLine data-testid="kiosk-order-email" className={classNames(styles.emailInput)}>
              <EmailInput
                control={control}
                name="emailAddress"
                labelFunc={label}
                data-cy="input-email-get-receipt"
                data-testid="kiosk-order-receipt"
                required={getReceiptMethod === GetReceiptType.Mail}
                placeholder={label('Ref: Your email address')}
                autoComplete="off"
              />
            </FormLine>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default withSite(KioskOrder);
