import { ArrowRightIcon } from '../../../assets';
import {
  ImageDefaultFoodNoRetailImg,
  ImageDefaultFoodRetailImg,
  ImageDefaultNoFoodNoRetailImg,
  ImageDefaultNoFoodRetailImg,
} from '../../../assets/images';
import { TranslationProps } from '../../../localization/localization.types';
import { pagePaths } from '../config';
import {
  FacilityImage,
  FacilityType,
  FACILITY_TYPE,
  IReviewFacility,
} from '../types/reviewFacility.types';
import { REDIRECTED_PAGES } from '../types/reviewForm.types';

const getImageFromType = (type: FacilityType) => {
  switch (type) {
    case FacilityType.FoodRetail: {
      return <ImageDefaultFoodRetailImg />;
    }
    case FacilityType.FoodNonRetail: {
      return <ImageDefaultFoodNoRetailImg />;
    }
    case FacilityType.NonFoodNonRetail: {
      return <ImageDefaultNoFoodNoRetailImg />;
    }
    case FacilityType.NonFoodRetail: {
      return <ImageDefaultNoFoodRetailImg />;
    }
    default: {
      return <></>;
    }
  }
};

export const convertToFacilitiesListItems = ({
  facilities,
  facilityImages,
  onFacilitySelect,
  label,
}: {
  facilities: IReviewFacility[];
  facilityImages?: FacilityImage[];
  onFacilitySelect: (facilityId: string) => void;
} & TranslationProps) => {
  return facilities.map((facility) => {
    const highlightedActions = [
      {
        element: (
          <>
            <ArrowRightIcon />
            <p className="sr-only">{`${label('Review', { textTransform: 'capitalize' })} ${
              facility.title
            }`}</p>
          </>
        ),
        action: () => onFacilitySelect(facility.id),
      },
    ];
    const linkPath =
      pagePaths.ReviewForFacility.replace(':facilityId', facility.id) +
      '?referer=%2Freview' +
      `&redirectedFrom=${REDIRECTED_PAGES.FACILITIES}`;
    const facilityType = FACILITY_TYPE[facility?.facilityType?.name] || FacilityType.Undefined;
    return {
      id: facility.id,
      title: facility.title,
      imgElement: getImageFromType(facilityType),
      imgInfo: facilityImages?.find((x) => x.key === facility.id)
        ? {
            src: facilityImages?.find((x) => x.key === facility.id)?.image,
            alt: '',
          }
        : undefined,
      highlightedActions,
      linkPath: linkPath,
      'data-testid': `review-facility-${facility.id}`,
    };
  });
};
