const labels = {
  PrivacyPolicy: 'プライバシーポリシー',
  TermsOfSale: '販売条件',
  TermsOfUse: '利用規約',
  home: 'ホーム',
  anytime: 'いつでも',
  anywhere: 'どこでも',
  Download: 'ダウンロード',
  scrollRightButton: '右側のカテゴリをスクロールします',
  scrollLeftButton: '左のカテゴリをスクロールします',
  calendar: 'カレンダー',
  'See more': '更に表示',
  'See less': 'あまり見ない',
  'from (followed by a date, or date and time)': 'から',
  'to (followed by a date, or date and time)': 'に',
  'on (followed by a date)': 'On',
  'at (followed by time)': 'で',
  'from (followed by a location)': 'から',
  'to (followed by a location)': 'に',
  'on (followed by a site name)': 'On',
  'from (followed by a person)': 'から',
  previous: '戻る',
  next: '次へ',
  success: '成功',
  failure: '失敗',
  today: '今日',
  tomorrow: '明日',
  Monday: '月曜日',
  Tuesday: '火曜日',
  Wednesday: '水曜日',
  Thursday: '木曜日',
  Friday: '金曜日',
  Saturday: '土曜日',
  Sunday: '日曜日',
  everyday: '毎日',
  'open all day': '終日',
  'opening hours': '営業時間',
  status: 'ステータス',
  'to be confirmed': '確認します',
  'only (as in filter only this)': 'それだけ',
  edit: '編集',
  submit: '送信',
  cancel: 'キャンセル',
  confirm: '確認',
  reset: 'リセット',
  Delete: '消去',
  select: '選択',
  switch: '切り替え',
  close: '閉じる',
  clear: 'クリア',
  remove: '削除する',
  logout: 'ログアウト',
  go: '行く',
  'see (as in consult this page or document)': '見る',
  'see all (as in see all items)': '全てを見る',
  'see more (as in see more details for this item)': '更に表示',
  retry: 'リトライ',
  'Ref: ok': 'Ok',
  'yes (as in yes I accept)': 'Yes',
  'no (as in no I do not accept)': 'No',
  you: 'あなた',
  'thank you': 'ありがとうございます',
  'sorry (standalone)': '申し訳ございません',
  'to home (as in navigate to the homepage)': 'ホームページへ',
  map: '地図',
  list: 'リスト',
  'Loading...': 'ロード中…',
  'Refreshing...': '更新中...',
  'Please select': '選択してください',
  'Switch site': 'ここにサイトを切り替えます',
  'File size cannot exceed {max file size}': 'ファイルサイズは{max file size}を超えてはいけません',
  Filters: 'フィルター',
  'Add attachment': '添付ファイルを追加する',
  Search: '探す',
  'More (as in: see more info)': '更に',
  'You can attach a maximum of {max files number} files.':
    '最大{max files number}ファイルを添付できます。',
  'There are no messages to display.': '表示するメッセージはありません',
  'Your selection did not return any result.': '選択に対するデータはありません',
  'Ref: Popup header for missing required fields in a form': '追加情報を提供してください',
  'Ref: Required field': '{ref}は必須フィールドです',
  email: 'Eメール',
  phone: '電話番号',
  Account: 'マイアカウント',
  Contacts: 'お問い合わせ',
  Content: 'コンテンツ',
  Events: 'イベント',
  Facilities: '施設・設備',
  Shop: '店',
  Menu: 'メニュー',
  Menus: 'メニュー',
  Order: '注文',
  Orders: '注文履歴',
  Info: '情報',
  FAQ: 'よくある質問',
  Feedback: 'フィードバック',
  Review: 'レビュー',
  Requests: 'サービスリクエスト',
  'Request Helpdesk': 'ヘルプデスクをリクエストする',
  Location: '場所',
  Safety: '安全性',
  FreeCoffee: '無料のコーヒー',
  Newsfeed: 'ニュースフィード',
  MyOrders: '私の注文',
  LoyaltyStamps: 'ロイヤルティスタンプ',
  Stamps: 'スタンプ',
  Stays: '継続',
  Suppliers: 'サプライヤー',
  Surveys: '調査',
  notifications: '通知',
  cart: 'カート',
  'Ref: Reset your order': 'ご注文をリセットします',
  'Ref: My language': '私の言語',
  Validate: '検証',
  'contact us': 'お問い合わせ',
  'Ref: Start typing': 'タイピングを開始します',
  Occupancy: '占有',
  'see less': '更に見る',
  'see more': '続きを見る',
  contacts: '連絡先',
  Imprint: 'インプリント',
  'Ref: camera access request title': 'カメラへのアクセスを許可します',
  'Ref: camera access request body':
    'QRコードスキャナーを使用するには、このサイトでカメラの使用を有効にする必要があります',
  or: 'もしくは',
  'Ref: Input required': '{input_name}が必要です',
  'Enter a valid email address': '有効なメールアドレスを入力してください',
  'Ref: Input error E-mail already exists':
    '入力したメールアドレスにはすでにアカウントがあります。',
  'Password must contain': 'パスワードには以下を含める必要があります。',
  'Ref: Input password error strength':
    'パスワードには、少なくとも8文字以上（1文字以上の数字、英字、記号）が含まれている必要があります',
  Password: 'パスワード',
  'New password': '新しいパスワード',
  'Confirm password': 'パスワードを認証する',
  'Ref: Password does not match': '入力したパスワードが一致しません',
  'Ref: Old password does not match': '古いパスワードが一致しません',
  'Ref: Password validation min8Characters': '少なくとも8文字',
  'Ref: Password validation min1Number': '少なくとも1桁',
  'Ref: Password validation min1Letter': '少なくとも1文字',
  'Ref: Password validation min1SpecialChar': '少なくとも1つの記号',
  Continue: '続ける',
  'Enter a valid phone number': '有効な電話番号を入力します',
  'Phone is required': '電話番号が必要です',
  'Go to home': 'ホームに戻る',
  'Something went wrong': 'エラーが発生しました',
  'Something went wrong, data not loaded':
    '何かがうまくいかなかったため、データはロードされませんでした',
  'Change password': 'パスワードを変更する',
  'remove one': '削除します',
  'add one': '追加します',
  'Confirm mobile phone': '携帯電話番号を確認する',
  'Confirm Filters': 'フィルターを確認します',
  'Reset Filters': 'フィルターをリセットします',
  '{selectedNum} active filters': '{selectedNum} アクティブフィルター',
  'Wrong input icon': '間違った入力アイコン',
  'Correct input icon': '正しい入力アイコン',
  'Show input text icon': '入力テキストアイコンを表示します',
  'Hide input text icon': '入力テキストアイコンを非表示にします',
  'Required fields info': '*必要なフィールド',
  'Ref: Select site on the map': 'マップでサイトを選択します',
  'Ref: Geolocation disabled':
    'ジオロケーションは無効です。近くのサイトを見るには、有効にしてください。',
  'Ref: Reaction - LIKE': '好き',
  'Ref: Reaction - LOVE': '愛している',
  'Ref: Reaction - INTERESTING': '面白い',
  'Ref: Reaction - CONGRATE': '祝福します',
  'Ref: Share': 'シェア',
  'Ref: Send by Email': 'メールで送る',
  'Ref: Copy the link': 'リンクをコピーします',
  'Ref: Link was copied': 'リンクがコピーされました',
  'Ref: unable to copy url': 'URLをコピーできません',
  'Ref: External link': '外部リンク',
  'Ref: Step': 'ステップ',
  'input field': '入力フィールド',
  'Ref: Zoom': 'ズーム',
  'Increase hours': '時間を増やします',
  'Decrease hours': '時間を短縮します',
  Hours: '時間',
  Minutes: '分',
  'Ref: filter label: favorites': '私のお気に入りのみを見せてください',
  'Ref: filter label: max calories': '最大カロリー',
  'Ref: filter label: dishes': '食器',
  'Ref: filter label: allergens': 'アレルギー',
  'Ref: filter label: highlight': '食器でフィルタ',
  'Ref: Highlight': 'ハイライト',
  'Ref: Hide': '隠す',
  'Ref: Vegan': 'ヴィーガン',
  'Ref: Vegeterian': 'ベジタリアン',
  'Ref: Mindful': 'マインドフル',
  'Ref: Highlight text': '特定のアレルゲンを含むメニューをフィルタリングします',
  'Ref: Just now': 'ちょうど今',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes}数分前',
  'Ref: 1 hour ago': '1時間前',
  'Ref: {amountHours} hours ago': '{amountHours}何時間も前',
  'Ref: Yesterday at {time}': '昨日{time}',
  'Ref: {date} at {time}': '{date} at {time}',
  'Ref: filter label: date': '日時',
  'Ref: expand menu': 'メニューを展開します',
  'Ref: collapse menu': '崩壊メニュー',
  'Ref: My site': '私のサイト',
  'Ref: Recent': '最近',
  'Ref: Services': 'サービス',
  'Ref: products in cart': 'カート内の製品の数',
  'Ref: no product in cart': 'カートに製品はありません',
  'Ref: Survey': '調査',
  'Access to camera was denied. Please allow access and try again':
    'カメラへのアクセスは拒否されました。アクセスを許可して、もう一度お試しください',
  'Add an attachment': '添付ファイルを追加します',
  'Take a picture': '写真を撮る',
  'Add a file from your device': 'デバイスからファイルを追加します',
  'Ref: Loading page content, please wait': 'ページのコンテンツの読み込み、お待ちください',
  Star: '星',
  Stars: '出演者',
  'Accommodation Request': '宿泊施設のリクエスト',
  'Ref: Guest Accomodation Request': 'ゲストのリクエスト',
  'Ref: Search a menu, product or facility': 'メニュー、製品、または施設を検索します',
  ConnectionLost: 'ネットワーク接続が失われました',
  ConnectionRestored: 'ネットワーク接続が復元されました',
  'Ref: Skip to main content': 'メインコンテンツにスキップします',
  'Ref: No options': 'オプションはありません',
  'Ref: Global search modal': 'グローバル検索モーダル',
  'Ref: Search a facility': '施設を検索します',
  'Ref: Search a menu or product': 'メニューまたは製品を検索します',
  'Ref: Reaction': '反応',
  'Ref: times': '時代',
  'Ref: reaction. Change reaction': '反応。反応を変えます',
  'Ref: No reaction. React': '反応なし。反応します',
  'Ref: LIKE': 'のように',
  'Ref: LOVE': '愛',
  'Ref: INTERESTING': '面白い',
  'Ref: CONGRATE': 'おめでとう',
  'Ref: Open search': '検索ボックスを開きます',
  'Ref: Close search': '検索ボックスを閉じます',
  'Ref: Total file size msg': '合計ファイルサイズは{max file size}を超えることはできません',
  'Ref: Oops Title': 'おっとっと！',
  'Ref: Well Done': 'よくやった {party_emoji}',
  'Ref: Navigation arrow - Continue': 'ナビゲーション矢印 - 続行します',
  'Ref: Reload': 'リロード',
  'Ref: All Dates': 'すべての日付',
  'Ref: Today': '今日',
  'Ref: Validate': '検証',
  'Ref: toast error title': 'おっとっと！',
  'Ref: toast error subtitle': 'エラーが発生しました。もう一度お試しください。',
  'Ref: Calendar': 'カレンダー',
  'Ref: Selected': '選択',
  'Ref: Previous Month': '前月',
  'Ref: Next Month': '来月',
  'This module has been retired': 'このモジュールは廃止されました',
};
export default labels;
