import classNames from 'classnames';
import { ReactNode } from 'react';

import { LabelOptions, TranslationProps } from '../../../../localization/localization.types';

import { GetLoyaltySchemesResponse } from '@/modules/LoyaltyStamps/api/api.types';
import { PageType } from '@/modules/Order/types/menuCartActions.types';
import { LoyaltyStampToBeEarned, MenuItem } from '@/modules/Order/types/orderState.types';

import styles from './LoyaltyCard.module.css';

export const getCardTitle = (numberOfStamps: number, label: TranslationProps['label']): string => {
  return label('Ref: Loyalty Card Title Stamp', {
    replace: {
      no_stamps: numberOfStamps.toString(),
      stamp_form: getStampLabelForm(numberOfStamps, label),
    },
  });
};

export const getCardDescription = (
  numberOfStamps: number,
  loyaltySchemesNames: string[],
  label: TranslationProps['label']
): ReactNode => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: label('Ref: Loyalty Card Description', {
          replace: {
            stamp_form: getStampLabelForm(numberOfStamps, label, {
              textTransform: 'lowercase',
            }),
            program_form: getProgramLabelForm(numberOfStamps, label, {
              textTransform: 'lowercase',
            }),
            scheme_names: getJoinedSchemeNames(loyaltySchemesNames, label('Ref: and')),
          },
        }),
      }}
    />
  );
};

export const getModalDescription = (
  numberOfStamps: number,
  pageType: PageType,
  loyaltySchemes: GetLoyaltySchemesResponse[],
  label: TranslationProps['label'],
  loyaltyStampsToBeEarned?: LoyaltyStampToBeEarned[],
  productName?: string
): ReactNode => {
  let modalList = <></>;
  let description = '';
  switch (pageType) {
    case PageType.productDetails:
      description = label('Ref: Loyalty Modal Description', {
        replace: {
          product_name: productName ?? '',
          scheme_names: getJoinedSchemeNames(
            loyaltySchemes.map((s) => s.name),
            label('Ref: and')
          ),
          program_form: getProgramLabelForm(numberOfStamps, label),
        },
      });

      break;
    case PageType.cart:
      description = label('Ref: Loyalty Modal List Description', {
        replace: {
          no_stamps: numberOfStamps.toString(),
          stamp_form: getStampLabelForm(numberOfStamps, label),
        },
      });

      const bulletListItems = loyaltyStampsToBeEarned?.map((item) => {
        return {
          label: label('Ref: Loyalty Modal List Item', {
            replace: {
              no_stamps: item.stampsCount.toString(),
              stamp_form: getStampLabelForm(item.stampsCount, label),
              scheme_names: getSchemeNameFromId(loyaltySchemes, item.loyaltySchemeId),
            },
          }),
          loyaltySchemeId: item.loyaltySchemeId,
        };
      });

      modalList = generateBulletPointList(bulletListItems);

      break;
  }

  return (
    <>
      <p>{label('Ref: About Loyalty stamps')} </p>
      <br />
      <p
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />

      <>{modalList}</>
    </>
  );
};

export const getActiveLoyaltySchemes = (
  menuId: number,
  loyaltySchemes: GetLoyaltySchemesResponse[],
  pageType: PageType,
  menuItem?: MenuItem,
  loyaltyStampToBeEarned?: LoyaltyStampToBeEarned[]
): GetLoyaltySchemesResponse[] => {
  switch (pageType) {
    case PageType.productDetails:
      return (
        loyaltySchemes?.filter(
          (s) =>
            s.menuIdsProvidingProductsProvidingStamp.some((m) => m === menuId) &&
            s.productsProvidingStamp.some((p) =>
              menuItem?.productPortions.some(
                (pp) => pp.foodItemId === p.foodItemId && pp.uomId === p.uomId
              )
            )
        ) ?? loyaltySchemes
      );
    case PageType.cart:
      return (
        loyaltySchemes?.filter((s) =>
          loyaltyStampToBeEarned?.some((i) => i.loyaltySchemeId === s.id)
        ) ?? loyaltySchemes
      );
    default:
      return loyaltySchemes;
  }
};

const getStampLabelForm = (
  numberOfStamps: number,
  label: TranslationProps['label'],
  labelOptions?: LabelOptions
): string => {
  return numberOfStamps > 1
    ? label('Ref: Stamps', labelOptions)
    : label('Ref: Stamp', labelOptions);
};
const getProgramLabelForm = (
  numberOfStamps: number,
  label: TranslationProps['label'],
  labelOptions?: LabelOptions
): string => {
  return numberOfStamps > 1
    ? label('Ref: Programs', labelOptions)
    : label('Ref: Program', labelOptions);
};

const getSchemeNameFromId = (
  loyaltySchemes: GetLoyaltySchemesResponse[],
  schemeId: number
): string => {
  return loyaltySchemes.find((s) => s.id === schemeId)?.name ?? '';
};

const getJoinedSchemeNames = (items: string[], joinLastBy?: string): string => {
  if (items.length === 0) {
    return '';
  } else if (items.length === 1) {
    return items[0];
  } else {
    const joinedItems = items.slice(0, -1).join(', ');
    const lastItem = items[items.length - 1];

    return `${joinedItems} ${joinLastBy} ${lastItem}`;
  }
};

const generateBulletPointList = (listItems?: { label: string; loyaltySchemeId: number }[]) => {
  return (
    <ul
      className={classNames(styles.bulletPointList)}
      data-testid={`loyalty-modal-bullet-point-list`}
    >
      {listItems?.map((listItem) => (
        <li
          key={listItem.loyaltySchemeId}
          dangerouslySetInnerHTML={{
            __html: listItem.label,
          }}
          data-testid={`loyalty-modal-bullet-point-list-item-${listItem.loyaltySchemeId}`}
        />
      ))}
    </ul>
  );
};
