export enum MIGRATION_STATUS {
  INVALID_CREDENTIALS = 'InvalidCredentials',
  MIGRATION_DISABLED = 'MigrationDisabled',
  USER_MIGRATED = 'UserMigrated',
  PASSWORD_WEAK = 'PasswordToWeek',
}

export type PasswordChangeFormFields = {
  password: string;
  confirmPassword: string;
};
