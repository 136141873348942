import { useCallback, useState } from 'react';

import RatingStar from '../../../../components/atoms/RatingStar';
import Modal from '../../../../components/organisms/Modal';
import { getPrice } from '../../helpers/order.helper';
import { getFulfillmentTypeLabel } from '../../helpers/order.info.helper';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';

import { TransactionalFeedbackModalProps } from './TransactionalFeedbackModal.types';

import { ImageDefaultFoodImg } from '@/assets/images';
import ImageWithFallback from '@/components/atoms/ImageWithFallback/ImageWithFallback';
import { Tile } from '@/components/molecules/Tile';
import { formatDateTime, toLocalDate } from '@/helpers/dateTime';
import { ResponseSingleValue, Responses } from '@/modules/Surveys/types/survey.types';

import styles from './TransactionalFeedbackModal.module.css';

const TransactionalFeedbackModal = ({
  order,
  isModalOpen,
  languageCode,
  isoCode,
  hideModal,
  facility,
  imageData,
}: TransactionalFeedbackModalProps) => {
  const [responses, setResponses] = useState<Responses>({});
  const { label } = useOrderTranslation(__filename);

  const onSetResponse = useCallback(
    (questionId: string, value: ResponseSingleValue) => {
      setResponses({
        ...responses,
        [questionId]: value as ResponseSingleValue,
      });
    },
    [responses]
  );

  const imgInfo = imageData?.image
    ? {
        src: imageData?.image,
        alt: facility.title,
      }
    : undefined;
  const image = (
    <ImageWithFallback
      data-testid="transactional-feedback-modal"
      imgInfo={imgInfo}
      imgElement={<ImageDefaultFoodImg />}
    />
  );

  const tileTile = `#${order.orderId} • ${label(getFulfillmentTypeLabel(order.fulfillmentType))}`;
  const orderPrice = (
    <b className={styles.price}>
      {label('Ref: Total')}: {getPrice(order?.total, languageCode, isoCode)}
    </b>
  );

  const starRatingResponse = `${order.orderId}-star`;
  const handleChange = (value: number) => {
    onSetResponse(starRatingResponse, String(value));
    // next US : {navigateToFeedbackFormPage}
  };

  const pickupDate = order.pickupDate
    ? formatDateTime(toLocalDate(order.pickupDate, true), languageCode)
    : undefined;

  return (
    <Modal
      isOpen={isModalOpen}
      id="transactional-feedback-modal"
      data-testid="transactional-feedback-modal"
      onDismiss={hideModal}
      title={label('Ref: How was facility name service', {
        replace: {
          facility_name: facility.title,
        },
      })}
      backdropDismiss={false}
      aria-modal="true"
      footer={
        <RatingStar
          groupName={order.orderId.toString()}
          value={Number(responses[starRatingResponse])}
          data-testid="transactional-feedback-modal-rating-star"
          onChange={handleChange}
          srOnlyLegend={label('Ref: How do you rate the service')}
        />
      }
    >
      <Tile
        title={tileTile}
        description={pickupDate}
        image={image}
        id="transactional-feedback-modal-tile"
        data-testid="transactional-feedback-modal-tile"
        children={orderPrice}
        fullChildrenHeight={true}
      />
    </Modal>
  );
};

export default TransactionalFeedbackModal;
