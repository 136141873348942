import classNames from 'classnames';
import { debounce } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { INPUT_TYPE } from '../../../../constants';
import { useGetSiteContactsQuery } from '../../api/index';
import { requestCategories } from '../../config';
import { renderAffectedPersonName, formatPossibleAffectedPersonsList } from '../../helpers/helpers';
import { useServiceRequestSetupOption } from '../../hooks/useServiceRequestSetupOption';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';

import { DescribeProps } from './Describe.types';

import { BuildingIcon, EditionPhotoIcon } from '@/assets/icons';
import Button from '@/components/atoms/Button';
import FormLine from '@/components/atoms/FormLine/FormLine';
import Input from '@/components/atoms/Input/Input';
import Select, { highlightSearchedWords } from '@/components/atoms/Select';
import Stepper from '@/components/atoms/Stepper';
import Textarea from '@/components/atoms/Textarea/Textarea';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import { Alert } from '@/components/molecules/Alert';
import Card from '@/components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import useToggle from '@/helpers/hooks/useToggle';
import { State } from '@/types/state.types';

const MAX_FILES_NUM = 5;
const MAX_FILES_SIZE = 1024 * 1024 * 10;
const MAX_TOTAL_FILE_SIZE = 1024 * 1024 * 21;

const MAX_PERSONS_LIST_OPTIONS_DISPLAYED = 10;
const Describe = ({
  raisedFor,
  title,
  description,
  category,
  phoneNumber,
  handleChange: parentHandleChange,
  hasLocation,
  myLocation,
  roomText,
  attachments,
  goToNext,
  withNavBar,
  previousPath,
  isSiteChanged,
}: DescribeProps) => {
  const { label } = useServiceRequestTranslation(__filename);
  const history = useHistory();
  const currentUser = useSelector((state: State) => state.Core?.user);
  const siteName = useSelector((state: State) => state.Core?.context.site?.name);
  const [search, setSearch] = useState<string>('');
  const { data: siteContacts, isFetching: contactsLoading } = useGetSiteContactsQuery({
    pageSize: MAX_PERSONS_LIST_OPTIONS_DISPLAYED,
    search,
  });

  const {
    state: showAlert,
    toggleOn: setShowAlertTrue,
    toggleOff: setShowAlertFalse,
  } = useToggle(false);
  const {
    state: isFormValid,
    toggleOn: setIsFormValidTrue,
    toggleOff: setIsFormValidFalse,
  } = useToggle(false);

  const [missingInfo, setMissingInfo] = useState<any>([]);
  const [formValues, setFormValues] = useState<any>({
    // TODO: define types
    raisedFor: raisedFor,
    title: title,
    description: description,
    category: category,
    phoneNumber: phoneNumber,
  });

  const popupMessage = missingInfo.map((k: any) => k.message).join('<br />'); //TODO: get type

  const displayCategory = useServiceRequestSetupOption('DisplayCategorizationField');
  const enableAffectedPerson = useServiceRequestSetupOption('enableAffectedPerson');
  const hidePhoneNumber = useServiceRequestSetupOption('hidePhoneNumber');

  const validateForm = useCallback(
    (callback?: Function) => {
      const missingInfoBag: any = [];
      const rules = [
        {
          target: 'title',
          validate: (val: any) => {
            if (!val.trim().length)
              missingInfoBag.push({
                field: 'title',
                message: label('Ref: Required field: title'),
              });
          },
        },
        {
          target: 'category',
          validate: (val: any) => {
            if (displayCategory) {
              if (!val.length) {
                return missingInfoBag.push({
                  field: 'category',
                  message: label('Ref: Required field: category'),
                });
              }
              if (requestCategories.indexOf(val) === -1) {
                return missingInfoBag.push({
                  field: 'category',
                  message: label('Ref: Invalid field: category'),
                });
              }
            }
          },
        },
        {
          target: 'description',
          validate: (val: any) => {
            if (!val.trim().length) {
              missingInfoBag.push({
                field: 'description',
                message: label('Ref: Required field: description'),
              });
              return;
            }
          },
        },
      ];

      for (const rule of rules) {
        rule.validate(formValues[rule.target]);
      }

      if (missingInfoBag.length) {
        setIsFormValidFalse();
      } else {
        setIsFormValidTrue();
      }

      setMissingInfo(missingInfoBag);

      if (callback) callback();
    },
    [displayCategory, formValues, label, setIsFormValidFalse, setIsFormValidTrue, setMissingInfo]
  );

  useEffect(() => {
    if (!enableAffectedPerson) {
      setSearch('');
    }

    validateForm();
  }, [enableAffectedPerson, setSearch, validateForm]);

  useEffect(() => {
    validateForm();
  }, [formValues, validateForm]);

  const performAffectedPersonSearch = debounce((search) => setSearch(search), 300);

  const getAffectedPersonNoOptionsMessage = ({ inputValue }: any) => {
    if (contactsLoading) {
      return label('Loading...');
    }

    if (inputValue === '') {
      return label('Ref: Start typing');
    }

    if (siteContacts && siteContacts.length < 1) {
      return label('Ref: No affected person results');
    }
  };

  const handleChange = (field: string, value: any) => {
    setFormValues((state: any) => ({ ...state, [field]: value }));
    parentHandleChange({ [field]: value });
  };

  //if previous step was not completed, redirect
  if (!hasLocation) {
    history.replace(previousPath);
    return <LoadingPage />;
  }

  const action = isFormValid ? goToNext : () => validateForm(setShowAlertTrue()!);

  return (
    <SimpleFormPage withNavBar={withNavBar} title={label('Ref: Page title')}>
      <Container>
        <Column.Main>
          {isSiteChanged && (
            <Notification
              look={NOTIFICATION_LOOK.SUCCESS}
              title={label('Ref: Notification title')}
              inheritStyle={classNames('mb-L')}
              dismissable={true}
            >
              {siteName &&
                label('Ref: Notification info', {
                  replace: { 'site name': siteName },
                })}
            </Notification>
          )}
          <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
            {label('your_request')}
          </Title>
          <Card
            icon={{ icon: BuildingIcon }}
            title={{
              tag: TITLE_TAG.SPAN,
              children: label('Ref: Your location'),
              size: TITLE_SIZE.BODYMDEFAULT,
            }}
          >
            <span className={classNames('fw-B')}>{myLocation?.title || roomText}</span>
          </Card>
          <Card>
            <FormLine data-testid="service-request-title">
              <Input
                data-testid="service-request-title"
                id="title"
                inputLabel={label('Ref: Title')}
                inputType={INPUT_TYPE.TEXT}
                required
                value={title}
                onInputChange={(val) => handleChange('title', val)}
              />
            </FormLine>
            {displayCategory && (
              <FormLine data-testid="service-request-describe-category">
                <Select
                  name="category"
                  label={label('Ref: Request category')}
                  required
                  placeholder={label('Please select')}
                  onChange={(option) => handleChange('category', option.value)}
                  value={category}
                  data-cy="category-select"
                  options={requestCategories.map((cat) => ({ label: label(cat), value: cat }))}
                  data-testid="category-select"
                />
              </FormLine>
            )}
            <FormLine data-testid="service-request-describe-description">
              <Textarea
                id="description"
                label={label('Ref: description field label')}
                required
                value={description}
                onChange={(e) => handleChange('description', e.detail.value)}
                comment={label('Ref: Description message')}
              />
            </FormLine>
            <FormLine data-testid="service-request-describe-attachments">
              <Attachments
                attachments={attachments}
                maxFilesNum={MAX_FILES_NUM}
                maxFileSize={MAX_FILES_SIZE}
                maxTotalFileSize={MAX_TOTAL_FILE_SIZE}
                attach={(items) => parentHandleChange({ attachments: items })}
                data-testid="service-request-describe-attachments"
                fileUploaderConfig={{
                  addButonIcon: EditionPhotoIcon,
                  addButtonLabel: label('Ref: Add photos'),
                }}
              />
            </FormLine>

            {enableAffectedPerson && (
              <FormLine data-testid="service-request-describe-affected-person">
                <Select
                  name="raisedFor"
                  label={label('Ref: Affected person')}
                  placeholder={label('Please select')}
                  onChange={(option) => handleChange('raisedFor', option)}
                  data-cy="raisedFor-select"
                  noOptionsMessage={getAffectedPersonNoOptionsMessage}
                  value={
                    raisedFor.value
                      ? raisedFor
                      : {
                          label: renderAffectedPersonName(
                            currentUser.firstName || '',
                            currentUser.lastName || '',
                            currentUser.email || '',
                            label,
                            true
                          ) as string,
                          value: currentUser?.contactId ?? '',
                        }
                  }
                  options={formatPossibleAffectedPersonsList(siteContacts, label, currentUser)}
                  onInputChange={(search) => {
                    performAffectedPersonSearch(search);
                    return search;
                  }}
                  formatOptionLabel={highlightSearchedWords}
                  data-testid="raised-for-select"
                />
              </FormLine>
            )}

            {!hidePhoneNumber && (
              <FormLine data-testid="service-request-describe-phone">
                <Input
                  data-testid="service-request-describe-phone"
                  id="phoneNumber"
                  inputLabel={label('phone_number')}
                  inputType={INPUT_TYPE.TEL}
                  inputmode="tel"
                  autocomplete="tel"
                  value={phoneNumber}
                  maxlength={24}
                  size={24}
                  onInputChange={(val) => handleChange('phoneNumber', val)}
                />
              </FormLine>
            )}

            <Title tag={TITLE_TAG.SPAN} size={TITLE_SIZE.BODYSDEFAULT}>
              {label('Required fields info')}
            </Title>
            {!isFormValid && (
              <Alert
                isOpen={showAlert}
                onDismiss={setShowAlertFalse}
                className="popup-warning data-cy-missing-info-popup"
                header={label('Ref: Required fields: header')}
                message={<div dangerouslySetInnerHTML={{ __html: popupMessage }}></div>}
                buttons={[label('ok', { textTransform: 'capitalize' })]}
                data-testid="service-request-describe-missing-info-popup"
              />
            )}
          </Card>
        </Column.Main>
        <Column.Side>
          <Stepper />
        </Column.Side>
        <ActionsBar>
          <Button
            onClick={action}
            data-testid="service-request-describe-next-action"
            disabled={!isFormValid}
          >
            {label('next', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default Describe;
