import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Dispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';

import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { getAppVersionInfo } from '../actions';
import {
  AppUpdateFlags,
  AppUpdatePageRequest,
  AppVersionInfo,
} from '../components/AppUpdatePage/AppUpdatePage.types';
import { pagePaths } from '../config';

type checkAppVersionProps = {
  site?: { id: string; name: string };
  contract?: { id: string; name: string };
};

type checkAppVersionApiProps = {
  siteId?: string;
  contractId?: string;
  dispatch: Dispatch;
  history: RouteComponentProps['history'];
};

interface checkAppVersionApiResponse {
  ok: boolean;
  cached: boolean;
  responseStatus?: number;
  responseData?: AppVersionInfo;
}

const checkAppVersion = async (props: checkAppVersionApiProps) => {
  const { dispatch, contractId, siteId, history } = props;
  if (Capacitor.isNativePlatform()) {
    const appInfo = await CapacitorApp.getInfo();
    const appId = process.env.REACT_APP_MOBILE_PACKAGE_NAME ?? '';

    const requestParams: AppUpdatePageRequest = {
      appId:
        appId.lastIndexOf('.internal') > -1
          ? appId.substring(0, appId.lastIndexOf('.internal'))
          : appId,
      appVersion: appInfo.version,
      platform: Capacitor.getPlatform(),
    };
    if (contractId) requestParams.contractId = contractId;
    if (siteId) requestParams.siteId = siteId;
    const appInformation = (await dispatch(
      getAppVersionInfo(requestParams)
    )) as unknown as checkAppVersionApiResponse;
    if (
      appInformation?.responseData?.type === AppUpdateFlags.FORCE ||
      appInformation?.responseData?.type === AppUpdateFlags.SOFT
    ) {
      history.push(pagePaths.AppUpdate);
    }
  }
};
const useCheckAppVersion = (props: checkAppVersionProps) => {
  const { contract, site } = props;
  const [siteId, setSiteId] = useState<string | undefined>();
  const [contractId, setContractId] = useState<string | undefined>();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentRegionCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE);

  useEffect(() => {
    if (contract?.id) setContractId(contract.id);
    if (site?.id) setSiteId(site.id);
  }, [site, contract]);

  useEffect(() => {
    if (currentRegionCode?.length === 2) {
      // For security reasons check if user has valid version of app if not force him to update screen
      checkAppVersion({ dispatch, history, siteId, contractId }).catch((err) =>
        console.error('APP VERSION CHECK ERROR:', err)
      );
    }
  }, [siteId, contractId, dispatch, history, currentRegionCode]);
};

export default useCheckAppVersion;
