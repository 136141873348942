const labels = {
  PrivacyPolicy: 'Privatumo politika',
  TermsOfSale: 'Pagal pardavimų',
  TermsOfUse: 'Sąlygos ir nuostatos',
  home: 'Namai',
  anytime: 'kada',
  anywhere: 'bet kur',
  Download: 'parsisiųsti',
  scrollRightButton: 'Slinkite kategorijas į dešinę',
  scrollLeftButton: 'Slinkite kategorijas į kairę',
  calendar: 'kalendorius',
  'See more': 'Žiūrėti daugiau',
  'See less': 'Žiūrėti mažiau',
  'from (followed by a date, or date and time)': 'iš',
  'to (followed by a date, or date and time)': 'į',
  'on (followed by a date)': 'apie',
  'at (followed by time)': 'at',
  'from (followed by a location)': 'iš',
  'to (followed by a location)': 'į',
  'on (followed by a site name)': 'apie',
  'from (followed by a person)': 'iš',
  previous: 'ankstesnis',
  next: 'Kitas',
  success: 'sėkmė',
  failure: 'nesėkmė',
  today: 'šiandien',
  tomorrow: 'rytoj',
  Monday: 'pirmadienis',
  Tuesday: 'antradienis',
  Wednesday: 'trečiadienis',
  Thursday: 'ketvirtadienis',
  Friday: 'penktadienis',
  Saturday: 'šeštadienis',
  Sunday: 'sekmadienis',
  everyday: 'Kiekvieną dieną',
  'open all day': 'Atidarykite visą dieną',
  'opening hours': 'Darbo valandos',
  status: 'statusą',
  'to be confirmed': 'Turi būti patvirtinta',
  'only (as in filter only this)': 'tik',
  edit: 'Redaguoti',
  submit: 'Pateikti',
  cancel: 'atšaukti',
  confirm: 'Patvirtinti',
  reset: 'Atstatyti',
  Delete: 'Ištrinti',
  select: 'pasirinkite',
  switch: 'jungiklis',
  close: 'Uždaryti',
  clear: 'aišku',
  remove: 'pašalinti',
  logout: 'Atsijungti',
  go: 'eik',
  'see (as in consult this page or document)': 'pamatyti',
  'see all (as in see all items)': 'Matyti viską',
  'see more (as in see more details for this item)': 'Žiūrėti daugiau',
  retry: 'bandykite dar kartą',
  'Ref: ok': 'Gerai',
  'yes (as in yes I accept)': 'taip',
  'no (as in no I do not accept)': 'ne',
  you: 'tu',
  'thank you': 'Ačiū',
  'sorry (standalone)': 'atsiprašau',
  'to home (as in navigate to the homepage)': 'į pagrindinį puslapį',
  map: 'žemėlapis',
  list: 'sąrašas',
  'Loading...': 'Kraunasi ...',
  'Refreshing...': 'Atnaujinama...',
  'Please select': 'Prašome pasirinkti',
  'Switch site': 'Perjungimo svetainė čia',
  'File size cannot exceed {max file size}': 'Failo dydis negali viršyti {max file size}',
  Filters: 'Filtrai',
  'Add attachment': 'Pridėti priedą',
  Search: 'Paieška',
  'More (as in: see more info)': 'daugiau',
  'You can attach a maximum of {max files number} files.':
    'Galite pridėti daugiausiai failų: {max files number}.',
  'There are no messages to display.': 'Nėra pranešimų, kuriuos būtų galima rodyti.',
  'Your selection did not return any result.': 'Jūsų pasirinkimas nedavė jokių rezultatų.',
  'Ref: Popup header for missing required fields in a form': 'Pateikite papildomos informacijos',
  'Ref: Required field': '{ref} yra privalomas laukas.',
  email: 'El. Paštas',
  phone: 'Mobilaus telefono numeris',
  Account: 'sąskaita',
  Contacts: 'kontaktai',
  Content: 'Turinys',
  Events: 'Renginiai',
  Facilities: 'paslaugos',
  Shop: 'Parduotuvė',
  Menu: 'Meniu',
  Menus: 'Meniu',
  Order: 'Įsakymas',
  Orders: 'Užsakymai',
  Info: 'Informacija',
  FAQ: 'DUK',
  Feedback: 'Atsiliepimas',
  Review: 'Peržiūra',
  Requests: 'prašymai',
  'Request Helpdesk': 'Prašyti pagalbos tarnybos',
  Location: 'vieta',
  Safety: 'Saugumas',
  FreeCoffee: 'Nemokama kava',
  Newsfeed: 'Naujienų srautas',
  MyOrders: 'Mano įsakymai',
  LoyaltyStamps: 'Lojalumo antspaudai',
  Stamps: 'Antspaudai',
  Stays: 'korsetas',
  Suppliers: 'Tiekėjai',
  Surveys: 'Apklausos',
  notifications: 'Pranešimai',
  cart: 'Krepšelis',
  'Ref: Reset your order': 'Iš naujo nustatykite užsakymą',
  'Ref: My language': 'Mano kalba',
  Validate: 'Patvirtinti',
  'contact us': 'Susisiekite su mumis',
  'Ref: Start typing': 'Pradėkite rašyti',
  Occupancy: 'Užimtumas',
  'see less': 'Pamatyti mažiau',
  'see more': 'Žiūrėti daugiau',
  contacts: 'Kontaktai',
  Imprint: 'Įspaudas',
  'Ref: camera access request title': 'Leiskite prieiti prie fotoaparato',
  'Ref: camera access request body':
    'Norėdami naudoti QR kodo skaitytuvą, turite įjungti fotoaparatą šioje svetainėje',
  or: 'arba',
  'Ref: Input required': '{input_name} reikalingas',
  'Enter a valid email address': 'Įveskite galiojantį el. Pašto adresą',
  'Ref: Input error E-mail already exists':
    'Jau yra sąskaita su šiuo įvestu el. Laišku. Prašome prisijungti.',
  'Password must contain': 'Slaptažodis turi būti:',
  'Ref: Input password error strength':
    'Slaptažodyje turi būti bent 8 simboliai, 1 numeris, 1 raidė ir 1 specialus simboliai',
  Password: 'Slaptažodis',
  'New password': 'Naujas Slaptažodis',
  'Confirm password': 'Patvirtinti slaptažodį',
  'Ref: Password does not match': 'Slaptažodis ir patvirtinti slaptažodį nesutampa',
  'Ref: Old password does not match': 'Senas slaptažodis nesutampa',
  'Ref: Password validation min8Characters': 'Bent 8 simboliai',
  'Ref: Password validation min1Number': 'Bent 1 skaitmuo',
  'Ref: Password validation min1Letter': 'Bent 1 raidė',
  'Ref: Password validation min1SpecialChar': 'Bent 1 specialus personažas',
  Continue: 'Tęsti',
  'Enter a valid phone number': 'Įveskite galiojantį telefono numerį',
  'Phone is required': 'Reikalingas telefonas',
  'Go to home': 'Eiti namo',
  'Something went wrong': 'Kažkas nutiko ne taip',
  'Something went wrong, data not loaded': 'Kažkas nutiko ne taip, duomenys nebuvo įkelti',
  'Change password': 'Pakeisti slaptažodį',
  'remove one': 'Pašalinkite vieną',
  'add one': 'pridėti vieną',
  'Confirm mobile phone': 'Patvirtinkite mobilųjį telefoną',
  'Confirm Filters': 'Patvirtinkite filtrus',
  'Reset Filters': 'Atstatymo filtrai',
  '{selectedNum} active filters': '{selectedNum} Aktyvūs filtrai',
  'Wrong input icon': 'Neteisinga įvesties piktograma',
  'Correct input icon': 'Teisinga įvesties piktograma',
  'Show input text icon': 'Rodyti įvesties teksto piktogramą',
  'Hide input text icon': 'Slėpti įvesties teksto piktogramą',
  'Required fields info': '* Privalomi laukai',
  'Ref: Select site on the map': 'Pasirinkite savo svetainę žemėlapyje',
  'Ref: Geolocation disabled':
    'Geolokacija yra išjungta. Norėdami pamatyti netoliese esančias svetaines, įjunkite tai.',
  'Ref: Reaction - LIKE': 'Kaip',
  'Ref: Reaction - LOVE': 'Meilė',
  'Ref: Reaction - INTERESTING': 'Įdomus',
  'Ref: Reaction - CONGRATE': 'Sveikinu',
  'Ref: Share': 'Dalintis',
  'Ref: Send by Email': 'Siųsti el. Paštu',
  'Ref: Copy the link': 'Nukopijuokite nuorodą',
  'Ref: Link was copied': 'Nuoroda buvo nukopijuota',
  'Ref: unable to copy url': 'Nepavyko nukopijuoti URL',
  'Ref: External link': 'Išorinė nuoroda',
  'Ref: Step': 'Žingsnis',
  'input field': 'įvesties laukas',
  'Ref: Zoom': 'Mastelio keitimas',
  'Increase hours': 'Padidinti valandas',
  'Decrease hours': 'Sumažinti valandas',
  Hours: 'Valandos',
  Minutes: 'Minutės',
  'Ref: filter label: favorites': 'Parodykite tik mano mėgstamiausius',
  'Ref: filter label: max calories': 'Maksimalios kalorijos',
  'Ref: filter label: dishes': 'Patiekalai',
  'Ref: filter label: allergens': 'alergenai',
  'Ref: filter label: highlight': 'Pažymėkite arba paslėpti filtrus su patiekalais',
  'Ref: Highlight': 'Pažymėkite',
  'Ref: Hide': 'Paslėpti',
  'Ref: Vegan': 'Vegan.  T',
  'Ref: Vegeterian': 'Vegetaras',
  'Ref: Mindful': 'Atkreipkite dėmesį',
  'Ref: Highlight text':
    'Čia galite pasirinkti "Hightlight" arba paslėpti patiekalus meniu, kuriame yra tam tikrų alergenų',
  'Ref: Just now': 'Dabar',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} prieš kelias minutes',
  'Ref: 1 hour ago': 'Prieš 1 valandą',
  'Ref: {amountHours} hours ago': '{amountHours} prieš kelias valandas',
  'Ref: Yesterday at {time}': 'Vakar {time}',
  'Ref: {date} at {time}': '{date} {time}',
  'Ref: filter label: date': 'Data',
  'Ref: expand menu': 'Išplėskite meniu',
  'Ref: collapse menu': 'sugriūti meniu',
  'Ref: My site': 'Mano svetainė',
  'Ref: Recent': 'Naujausias',
  'Ref: Services': 'Paslaugos',
  'Ref: products in cart': 'Produktų skaičius krepšyje',
  'Ref: no product in cart': 'Nėra produkto krepšyje',
  'Ref: Survey': 'Apklausa',
  'Access to camera was denied. Please allow access and try again':
    'Prieiga prie kameros buvo atmesta. Leiskite prieigą ir bandykite dar kartą',
  'Add an attachment': 'Pridėkite priedą',
  'Take a picture': 'Nufotografuok',
  'Add a file from your device': 'Pridėkite failą iš savo įrenginio',
  'Ref: Loading page content, please wait': 'Įkėlimo puslapio turinys, palaukite',
  Star: 'Žvaigždė',
  Stars: 'Žvaigždės',
  'Accommodation Request': 'Apgyvendinimo prašymas',
  'Ref: Guest Accomodation Request': 'Svečio prašymas',
  'Ref: Search a menu, product or facility': 'Ieškokite meniu, produkto ar įrenginio',
  ConnectionLost: 'Tinklo ryšys prarastas',
  ConnectionRestored: 'Atkurti tinklo ryšį',
  'Ref: Skip to main content': 'Pereikite prie pagrindinio turinio',
  'Ref: No options': 'Jokių variantų',
  'Ref: Global search modal': 'Visuotinis paieškos modalas',
  'Ref: Search a facility': 'Ieškokite įrenginio',
  'Ref: Search a menu or product': 'Ieškokite meniu ar produkto',
  'Ref: Reaction': 'Reakcija',
  'Ref: times': 'laikai',
  'Ref: reaction. Change reaction': 'reakcija. Pakeisti reakciją',
  'Ref: No reaction. React': 'Jokios reakcijos. Reaguoti',
  'Ref: LIKE': 'Kaip',
  'Ref: LOVE': 'Meilė',
  'Ref: INTERESTING': 'Įdomus',
  'Ref: CONGRATE': 'Sveikiname',
  'Ref: Open search': 'Atidarykite paieškos laukelį',
  'Ref: Close search': 'Uždarykite paieškos laukelį',
  'Ref: Total file size msg': 'Bendras failo dydis negali viršyti {max failo dydžio}',
  'Ref: Oops Title': 'Oi! {robot_emoji}',
  'Ref: Well Done': 'Šauniai padirbėta {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigacijos rodyklė - tęskite',
  'Ref: Reload': 'Perkrauti',
  'Ref: All Dates': 'Visos datos',
  'Ref: Today': 'Šiandien',
  'Ref: Validate': 'Patvirtinti',
  'Ref: toast error title': 'Oi!',
  'Ref: toast error subtitle': 'Įvyko klaida, bandykite dar kartą.',
  'Ref: Calendar': 'Kalendorius',
  'Ref: Selected': 'Pasirinktas',
  'Ref: Previous Month': 'Praeita mėnesį',
  'Ref: Next Month': 'Kitą mėnesį',
  'This module has been retired': 'Šis modulis pašalintas',
};
export default labels;
