const labels = {
  PrivacyPolicy: 'Privacybeleid',
  TermsOfSale: 'Verkoopsvoorwaarden',
  TermsOfUse: 'algemene voorwaarden',
  home: 'Home',
  anytime: 'Elk moment',
  anywhere: 'overal',
  Download: 'Downloaden',
  scrollRightButton: 'Scroll -categorieën aan de rechterkant',
  scrollLeftButton: 'Scroll -categorieën links',
  calendar: 'Kalender',
  'See more': 'Bekijk meer',
  'See less': 'Minder zien',
  'from (followed by a date, or date and time)': 'van',
  'to (followed by a date, or date and time)': 'tot',
  'on (followed by a date)': 'Op',
  'at (followed by time)': 'Om',
  'from (followed by a location)': 'van',
  'to (followed by a location)': 'tot',
  'on (followed by a site name)': 'Op',
  'from (followed by a person)': 'van',
  previous: 'Vorige',
  next: 'Volgende',
  success: 'succes',
  failure: 'fout',
  today: 'Vandaag',
  tomorrow: 'Morgen',
  Monday: 'maandag',
  Tuesday: 'dinsdag',
  Wednesday: 'woensdag',
  Thursday: 'donderdag',
  Friday: 'vrijdag',
  Saturday: 'zaterdag',
  Sunday: 'zondag',
  everyday: 'Elke dag',
  'open all day': 'De hele dag geopend',
  'opening hours': 'Openingstijden',
  status: 'status',
  'to be confirmed': 'Nog te bevestigen',
  'only (as in filter only this)': 'enkel en alleen',
  edit: 'Bewerk',
  submit: 'Verzenden',
  cancel: 'annuleren',
  confirm: 'Bevestigen',
  reset: 'Resetten',
  Delete: 'Verwijderen',
  select: 'selecteer',
  switch: 'verwissel',
  close: 'Sluiten',
  clear: 'Duidelijk',
  remove: 'verwijderen',
  logout: 'uitloggen',
  go: 'Gaan',
  'see (as in consult this page or document)': 'zie',
  'see all (as in see all items)': 'Alles zien',
  'see more (as in see more details for this item)': 'Bekijk meer',
  retry: 'probeer het opnieuw',
  'Ref: ok': 'OK',
  'yes (as in yes I accept)': 'Ja',
  'no (as in no I do not accept)': 'Nee',
  you: 'jij',
  'thank you': 'Dank je',
  'sorry (standalone)': 'sorry',
  'to home (as in navigate to the homepage)': 'naar startpagina',
  map: 'kaart',
  list: 'lijst',
  'Loading...': 'Bezig met laden...',
  'Refreshing...': 'Bijwerken...',
  'Please select': 'Selecteer alstublieft',
  'Switch site': 'Verander hier van site',
  'File size cannot exceed {max file size}':
    'Bestandsgrootte mag niet groter zijn dan {max file size}',
  Filters: 'filters',
  'Add attachment': 'Voeg bijlage toe',
  Search: 'Zoekopdracht',
  'More (as in: see more info)': 'Meer',
  'You can attach a maximum of {max files number} files.':
    'U kunt maximaal {max files number} bestanden bijvoegen.',
  'There are no messages to display.': 'Er zijn geen berichten om weer te geven.',
  'Your selection did not return any result.': 'Uw selectie heeft geen resultaat opgeleverd.',
  'Ref: Popup header for missing required fields in a form': 'Geef aanvullende informatie op',
  'Ref: Required field': '{ref} is een verplicht veld.',
  email: 'E -mail',
  phone: 'Telefoon',
  Account: 'Account',
  Contacts: 'Contacten',
  Content: 'Inhoud',
  Events: 'Gebeurtenissen',
  Facilities: 'Faciliteiten',
  Shop: 'Winkel',
  Menu: 'Menu',
  Menus: "Menu's",
  Order: 'Bestelling',
  Orders: 'bestellingen',
  Info: 'Info',
  FAQ: 'FAQ',
  Feedback: 'terugkoppeling',
  Review: 'Beoordeling',
  Requests: 'aanvragen',
  'Request Helpdesk': 'Helpdesk aanvragen',
  Location: 'Locatie',
  Safety: 'Veiligheid',
  FreeCoffee: 'Gratis koffie',
  Newsfeed: 'Nieuwsfeed',
  MyOrders: 'Mijn bestellingen',
  LoyaltyStamps: 'Loyaliteitstzegels',
  Stamps: 'Zegels',
  Stays: 'verblijven',
  Suppliers: 'Leveranciers',
  Surveys: 'Enquetes',
  notifications: 'meldingen',
  cart: 'kaart',
  'Ref: Reset your order': 'Reset uw bestelling',
  'Ref: My language': 'Mijn taal',
  Validate: 'Bevestigen',
  'contact us': 'Neem contact op',
  'Ref: Start typing': 'Beginnen met typen',
  Occupancy: 'Bezetting',
  'see less': 'Zie minder',
  'see more': 'Bekijk meer',
  contacts: 'Contacten',
  Imprint: 'Afdrukken',
  'Ref: camera access request title': 'Laat toegang tot de camera toe',
  'Ref: camera access request body':
    'U moet het gebruik van een camera op deze site inschakelen om de QR -codescanner te gebruiken',
  or: 'of',
  'Ref: Input required': '{input_name} is vereist',
  'Enter a valid email address': 'Voer een geldig e -mailadres in',
  'Ref: Input error E-mail already exists':
    'Er is al een account met deze e -mail die u hebt ingevoerd. Log in plaats daarvan in.',
  'Password must contain': 'Wachtwoord moet bevatten:',
  'Ref: Input password error strength':
    'Wachtwoord moet minimaal 8 tekens, 1 nummer, 1 letter en 1 speciaal teken bevatten',
  Password: 'Wachtwoord',
  'New password': 'Nieuw wachtwoord',
  'Confirm password': 'Bevestig wachtwoord',
  'Ref: Password does not match': 'Wachtwoord komt niet overeen',
  'Ref: Old password does not match': 'Oud wachtwoord komt niet overeen',
  'Ref: Password validation min8Characters': 'Ten minste 8 tekens',
  'Ref: Password validation min1Number': 'Minstens 1 cijfer',
  'Ref: Password validation min1Letter': 'Minstens 1 brief',
  'Ref: Password validation min1SpecialChar': 'Minstens 1 speciaal karakter',
  Continue: 'Doorgaan',
  'Enter a valid phone number': 'Voer een geldig telefoonnummer in',
  'Phone is required': 'Telefoon is vereist',
  'Go to home': 'Naar startpagina',
  'Something went wrong': 'Er is iets fout gegaan',
  'Something went wrong, data not loaded': 'Er ging iets mis, gegevens niet geladen',
  'Change password': 'Verander wachtwoord',
  'remove one': 'Verwijder er één',
  'add one': 'Voeg één toe',
  'Confirm mobile phone': 'Bevestig mobiele telefoon',
  'Confirm Filters': 'Bevestig filters',
  'Reset Filters': 'Reset filters',
  '{selectedNum} active filters': '{selectedNum} Actieve filters',
  'Wrong input icon': 'Verkeerd invoerpictogram',
  'Correct input icon': 'Corrigeer INPUT -pictogram',
  'Show input text icon': 'Toon invoertekstpictogram',
  'Hide input text icon': 'Verberg het invoertekstpictogram',
  'Required fields info': '* Verplichte velden',
  'Ref: Select site on the map': 'Selecteer uw site op de kaart',
  'Ref: Geolocation disabled': 'Geolocatie is uitgeschakeld. Schakel het in nabijgelegen sites in.',
  'Ref: Reaction - LIKE': 'Leuk vinden',
  'Ref: Reaction - LOVE': 'Geweldig',
  'Ref: Reaction - INTERESTING': 'Interessant',
  'Ref: Reaction - CONGRATE': 'Feliciteren',
  'Ref: Share': 'Deel',
  'Ref: Send by Email': 'Verzenden per e -mail',
  'Ref: Copy the link': 'Kopieer de link',
  'Ref: Link was copied': 'Link werd gekopieerd',
  'Ref: unable to copy url': 'Kan URL niet kopiëren',
  'Ref: External link': 'Externe link',
  'Ref: Step': 'Stap',
  'input field': 'invoer veld',
  'Ref: Zoom': 'Zoom',
  'Increase hours': 'Uren verhogen',
  'Decrease hours': 'Uren verminderen',
  Hours: 'Uren',
  Minutes: 'Minuten',
  'Ref: filter label: favorites': 'Toon alleen mijn favorieten',
  'Ref: filter label: max calories': 'Max Calorieën',
  'Ref: filter label: dishes': 'Borden',
  'Ref: filter label: allergens': 'allergenen',
  'Ref: filter label: highlight': 'Markeer of verberg filters metgerechten',
  'Ref: Highlight': 'Highlight',
  'Ref: Hide': 'Verbergen',
  'Ref: Vegan': 'Veganistisch',
  'Ref: Vegeterian': 'Vegetarisch',
  'Ref: Mindful': 'Mindfull',
  'Ref: Highlight text':
    'Hier kunt u ervoor kiezen om lichten op te halen of gerechten te verbergen op het menu dat bepaalde allergenen bevat',
  'Ref: Just now': 'Net nu',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} minuten geleden',
  'Ref: 1 hour ago': '1 uur geleden',
  'Ref: {amountHours} hours ago': '{amountHours} uren geleden',
  'Ref: Yesterday at {time}': 'Gisteren op {time}',
  'Ref: {date} at {time}': '{date} om {time}',
  'Ref: filter label: date': 'Datum',
  'Ref: expand menu': 'Vouw het menu uit',
  'Ref: collapse menu': 'instortmenu',
  'Ref: My site': 'Mijn plaats',
  'Ref: Recent': 'Recentelijk',
  'Ref: Services': 'Diensten',
  'Ref: products in cart': 'Aantal producten in de winkelwagen',
  'Ref: no product in cart': 'Geen product in de winkelwagen',
  'Ref: Survey': 'Enquete',
  'Access to camera was denied. Please allow access and try again':
    'Toegang tot de camera werd geweigerd. Geef toegang toe en probeer het opnieuw',
  'Add an attachment': 'Voeg een bijlage toe',
  'Take a picture': 'Een foto nemen',
  'Add a file from your device': 'Voeg een bestand toe van uw toestel',
  'Ref: Loading page content, please wait': 'Pagina -inhoud laden, even wacht alstublieft',
  Star: 'Ster',
  Stars: 'Sterren',
  'Accommodation Request': 'Aanvraag voor logement',
  'Ref: Guest Accomodation Request': 'Gastverzoek',
  'Ref: Search a menu, product or facility': 'Zoek een menu, product of faciliteit',
  ConnectionLost: 'Netwerkverbinding verloren',
  ConnectionRestored: 'Netwerkverbinding hersteld',
  'Ref: Skip to main content': 'Sla naar hoofdinhoud',
  'Ref: No options': 'Geen opties',
  'Ref: Global search modal': 'Globaal zoekmodaal',
  'Ref: Search a facility': 'Zoek een faciliteit',
  'Ref: Search a menu or product': 'Zoek een menu of product',
  'Ref: Reaction': 'Reactie',
  'Ref: times': 'keer',
  'Ref: reaction. Change reaction': 'reactie. Verander de reactie',
  'Ref: No reaction. React': 'Geen gehoor. Reageer',
  'Ref: LIKE': 'Leuk vinden',
  'Ref: LOVE': 'Liefde',
  'Ref: INTERESTING': 'Interessant',
  'Ref: CONGRATE': 'Feliciteren',
  'Ref: Open search': 'Open het zoekvak',
  'Ref: Close search': 'Sluit het zoekvak',
  'Ref: Total file size msg': 'Totale bestandsgrootte kan niet hoger zijn dan {max file size}',
  'Ref: Oops Title': 'Oeps!',
  'Ref: Well Done': 'Goed gedaan {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigatiepijl - Ga door',
  'Ref: Reload': 'Herladen',
  'Ref: All Dates': 'Alle datums',
  'Ref: Today': 'Vandaag',
  'Ref: Validate': 'Bevestigen',
  'Ref: toast error title': 'Oeps!',
  'Ref: toast error subtitle': 'Er is een fout opgetreden. Probeer het alstublieft nog eens.',
  'Ref: Calendar': 'Kalender',
  'Ref: Selected': 'Geselecteerd',
  'Ref: Previous Month': 'Vorige maand',
  'Ref: Next Month': 'Volgende maand',
  'This module has been retired': 'Deze module is buiten gebruik gesteld',
};
export default labels;
