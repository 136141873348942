const labels = {
  PrivacyPolicy: '隱私政策',
  TermsOfSale: '銷售條款',
  TermsOfUse: '條款及細則',
  home: '首頁',
  anytime: '任何時間',
  anywhere: '任何地方',
  Download: '下載',
  scrollRightButton: '右邊的滾動類別',
  scrollLeftButton: '左側的滾動類別',
  calendar: '日曆',
  'See more': '查看詳情',
  'See less': '隱藏詳情',
  'from (followed by a date, or date and time)': '從',
  'to (followed by a date, or date and time)': '到',
  'on (followed by a date)': '在',
  'at (followed by time)': '在',
  'from (followed by a location)': '從',
  'to (followed by a location)': '至',
  'on (followed by a site name)': '在',
  'from (followed by a person)': '從',
  previous: '上一個',
  next: '下一個',
  success: '成功',
  failure: '失敗',
  today: '今天',
  tomorrow: '明天',
  Monday: '星期一',
  Tuesday: '星期二',
  Wednesday: '星期三',
  Thursday: '星期四',
  Friday: '星期五',
  Saturday: '星期六',
  Sunday: '星期日',
  everyday: '每天',
  'open all day': '全天開放',
  'opening hours': '營業時間',
  status: '狀態',
  'to be confirmed': '待確認',
  'only (as in filter only this)': '只要',
  edit: '編輯',
  submit: '提交',
  cancel: '取消',
  confirm: '確認',
  reset: '重置',
  Delete: '刪除',
  select: '選擇',
  switch: '切換',
  close: '關閉',
  clear: '清除',
  remove: '移除',
  logout: '登出',
  go: '轉到',
  'see (as in consult this page or document)': '查看',
  'see all (as in see all items)': '查看全部',
  'see more (as in see more details for this item)': '查看更多',
  retry: '重試',
  'Ref: ok': '確認',
  'yes (as in yes I accept)': '確認',
  'no (as in no I do not accept)': '否',
  you: '你',
  'thank you': '謝謝',
  'sorry (standalone)': '抱歉',
  'to home (as in navigate to the homepage)': '轉到主頁',
  map: '地圖',
  list: '列表',
  'Loading...': '載入中...',
  'Refreshing...': '更新中...',
  'Please select': '請選擇',
  'Switch site': '切換站點在這裡',
  'File size cannot exceed {max file size}': '文件大小不能超過 {max file size}',
  Filters: '篩選器',
  'Add attachment': '添加附件',
  Search: '搜索',
  'More (as in: see more info)': '更多',
  'You can attach a maximum of {max files number} files.':
    '您最多可以附加 {max files number} 個文件。',
  'There are no messages to display.': '沒有要顯示的消息。',
  'Your selection did not return any result.': '您的選擇沒有返回任何結果。',
  'Ref: Popup header for missing required fields in a form': '請提供更多信息',
  'Ref: Required field': '{ref} 是必填字段。',
  email: '電子郵件',
  phone: '電話號碼',
  Account: '我的帳戶',
  Contacts: '連絡人',
  Content: '內容',
  Events: '活動',
  Facilities: '設施',
  Shop: '店鋪',
  Menu: '菜單',
  Menus: '菜單',
  Order: '訂單',
  Orders: '歷史訂單',
  Info: '信息',
  FAQ: '常見問題',
  Feedback: '備註',
  Review: '點評',
  Requests: '服務請求',
  'Request Helpdesk': '请求帮助台',
  Location: '位置',
  Safety: '安全',
  FreeCoffee: '免費咖啡',
  Newsfeed: '新聞源',
  MyOrders: '我的訂單',
  LoyaltyStamps: '忠誠郵票',
  Stamps: '郵票',
  Stays: '入住',
  Suppliers: '供應商',
  Surveys: '調查',
  notifications: '通知',
  cart: '購物車',
  'Ref: Reset your order': '重置您的訂單',
  'Ref: My language': '我的語言',
  Validate: '證實',
  'contact us': '聯繫我們',
  'Ref: Start typing': '開始輸入',
  Occupancy: '佔用',
  'see less': '少看',
  'see more': '查看詳情',
  contacts: '聯繫人',
  Imprint: '印記',
  'Ref: camera access request title': '允許訪問相機',
  'Ref: camera access request body': '請允許使用您的相機，以掃描地點的二維碼。',
  or: '或者',
  'Ref: Input required': '{input_name}是必需的',
  'Enter a valid email address': '輸入一個有效的電子郵件地址',
  'Ref: Input error E-mail already exists': '您輸入的此電子郵件已經有一個帳戶。請改用。',
  'Password must contain': '密碼必須包含：',
  'Ref: Input password error strength': '密碼必須至少包含8個字符，1個數字，1個字母和1個特殊字符',
  Password: '密碼',
  'New password': '新密碼',
  'Confirm password': '確認密碼',
  'Ref: Password does not match': '密碼和確認密碼不匹配',
  'Ref: Old password does not match': '舊密碼不匹配',
  'Ref: Password validation min8Characters': '至少8位字符',
  'Ref: Password validation min1Number': '至少1位數字',
  'Ref: Password validation min1Letter': '至少1位字母',
  'Ref: Password validation min1SpecialChar': '至少1位特殊符號',
  Continue: '繼續',
  'Enter a valid phone number': '輸入有效的電話號碼',
  'Phone is required': '電話為必填項',
  'Go to home': '轉到主頁',
  'Something went wrong': '出錯了',
  'Something went wrong, data not loaded': '出錯了，數據未加載',
  'Change password': '更改密碼',
  'remove one': '刪除',
  'add one': '增加',
  'Confirm mobile phone': '確認手機',
  'Confirm Filters': '確認篩選',
  'Reset Filters': '重置篩選',
  '{selectedNum} active filters': '{selectedNum} 活動過濾器',
  'Wrong input icon': '輸入圖標錯誤',
  'Correct input icon': '正確輸入圖標',
  'Show input text icon': '顯示輸入文本圖標',
  'Hide input text icon': '隱藏輸入文本圖標',
  'Required fields info': '*必需的字段',
  'Ref: Select site on the map': '在地圖上選擇您的網站',
  'Ref: Geolocation disabled': '地理位置被禁用。要查看附近的站點，請啟用它。',
  'Ref: Reaction - LIKE': '讚',
  'Ref: Reaction - LOVE': '喜歡',
  'Ref: Reaction - INTERESTING': '有趣',
  'Ref: Reaction - CONGRATE': '祝賀',
  'Ref: Share': '分享',
  'Ref: Send by Email': '通過電子郵件發送',
  'Ref: Copy the link': '複製鏈接',
  'Ref: Link was copied': '鏈接被複製',
  'Ref: unable to copy url': '無法複製URL',
  'Ref: External link': '外部鏈接',
  'Ref: Step': '步',
  'input field': '輸入字段',
  'Ref: Zoom': '飛漲',
  'Increase hours': '增加工作時間',
  'Decrease hours': '減少小時',
  Hours: '小時',
  Minutes: '分鐘',
  'Ref: filter label: favorites': '僅顯示我的最愛',
  'Ref: filter label: max calories': '最大卡路里',
  'Ref: filter label: dishes': '菜餚',
  'Ref: filter label: allergens': '過敏原',
  'Ref: filter label: highlight': '高亮显示或隐藏带有菜肴的过滤器',
  'Ref: Highlight': '強調',
  'Ref: Hide': '隱藏',
  'Ref: Vegan': '素食主義者',
  'Ref: Vegeterian': '素食者',
  'Ref: Mindful': '介意',
  'Ref: Highlight text': '在這裡，您可以選擇在Hightlight或隱藏包含某些過敏原的菜單中的菜餚',
  'Ref: Just now': '現在',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes}幾分鐘前',
  'Ref: 1 hour ago': '1小時前',
  'Ref: {amountHours} hours ago': '{amountHours}小時前',
  'Ref: Yesterday at {time}': '昨天{time}',
  'Ref: {date} at {time}': '{date} at {time}',
  'Ref: filter label: date': '日期',
  'Ref: expand menu': '展開菜單',
  'Ref: collapse menu': '崩潰菜單',
  'Ref: My site': '我的地点',
  'Ref: Recent': '最近的',
  'Ref: Services': '服務',
  'Ref: products in cart': '購物車中的商品數量',
  'Ref: no product in cart': '目前沒有添加商品',
  'Ref: Survey': '調查',
  'Access to camera was denied. Please allow access and try again':
    '訪問相機被拒絕。請允許訪問並重試',
  'Add an attachment': '添加附件',
  'Take a picture': '拍照',
  'Add a file from your device': '從您的設備添加文件',
  'Ref: Loading page content, please wait': '加載頁面內容，請等待',
  Star: '星',
  Stars: '星',
  'Accommodation Request': '住宿請求',
  'Ref: Guest Accomodation Request': '賓客要求',
  'Ref: Search a menu, product or facility': '搜索菜單，產品或設施',
  ConnectionLost: '網絡連接丟失',
  ConnectionRestored: '網絡連接還原',
  'Ref: Skip to main content': '跳過主要內容',
  'Ref: No options': '沒有選項',
  'Ref: Global search modal': '全局搜索模式',
  'Ref: Search a facility': '搜索設施',
  'Ref: Search a menu or product': '搜索菜單或產品',
  'Ref: Reaction': '反應',
  'Ref: times': '時代',
  'Ref: reaction. Change reaction': '反應。改變反應',
  'Ref: No reaction. React': '沒有反應。反應',
  'Ref: LIKE': '喜歡',
  'Ref: LOVE': '愛',
  'Ref: INTERESTING': '有趣的',
  'Ref: CONGRATE': '恭喜',
  'Ref: Open search': '打開搜索框',
  'Ref: Close search': '關閉搜索框',
  'Ref: Total file size msg': '總文件大小不能超過{max file size}',
  'Ref: Oops Title': '哎呀！',
  'Ref: Well Done': '做得好 {party_emoji}',
  'Ref: Navigation arrow - Continue': '導航箭頭 - 繼續',
  'Ref: Reload': '重新加載',
  'Ref: All Dates': '所有日期',
  'Ref: Today': '今天',
  'Ref: Validate': '證實',
  'Ref: toast error title': '哎呀！',
  'Ref: toast error subtitle': '發生了錯誤。請再試一次。',
  'Ref: Calendar': '日曆',
  'Ref: Selected': '選定',
  'Ref: Previous Month': '前一個月',
  'Ref: Next Month': '下個月',
  'This module has been retired': '該模組已退役',
};
export default labels;
