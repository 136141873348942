import { RedoIcon } from '../../../../../assets';
import TitleBar from '../../../../../components/organisms/TitleBar';
import { orderConfig } from '../../../config';
import useResetKioskSession from '../../../hooks/useClearKioskSession/useResetKioskSession';
import { useOrderTranslation } from '../../../hooks/useOrderTranslation';

const ResetSessionWidget = () => {
  const [resetSession] = useResetKioskSession();
  const { label } = useOrderTranslation(__filename);
  const { isResetSessionWidgetShownInTitleBar } = orderConfig();

  if (!isResetSessionWidgetShownInTitleBar) return <></>;
  return (
    <TitleBar.Widget
      label={label}
      labelKey="Ref: Reset your order"
      Icon={RedoIcon}
      filled
      onClick={resetSession}
      forceToDisplayLabel
      iconOnTheLeft
    />
  );
};

export default ResetSessionWidget;
