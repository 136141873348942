import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import withSite from '../../../../_deprecated/context/withSite';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import { Alert } from '../../../../components/molecules/Alert';
import { Tile, TILE_VARIANT } from '../../../../components/molecules/Tile';
import { hasCameraPermission } from '../../../../helpers/camera';
import { toLocalDate } from '../../../../helpers/dateTime';
import useToggle from '../../../../helpers/hooks/useToggle';
import { isValidHttpUrl } from '../../../../helpers/urlValidator';
import { pagePaths } from '../../config';
import { isFFProductFavouriteEnabled } from '../../helpers/feature.flags.helper';
import { hasPortionsOrModifiers } from '../../helpers/menuCartActions.helper';
import { getMenuItemById } from '../../helpers/productDetails.helper';
import {
  buildAllergenChip,
  buildLoyaltyPointsForOrderingChip,
  buildLoyaltyRewardChip,
  buildSticker,
  buildVegeChip,
  getFormattedPricesForViewable,
  getFormattedPricesOrderable,
} from '../../helpers/productTile.helpers';
import { useLoyaltyReward } from '../../hooks/useLoyaltyReward/useLoyaltyReward';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { useProductPortion } from '../../hooks/useProductPortion/useProductPortion';
import { MenuItem, OrderState } from '../../types/orderState.types';
import { ProductTileProps } from '../../types/productList.types';
import ProductCornerAction from '../ProductCornerAction';
import { getProductIllustration } from '../ProductsList/productList.helper';

import StarAction from '@/components/atoms/StarAction';

import styles from './ProductTile.module.css';

const ProductTile = ({
  id,
  menuItemId,
  genericCategory,
  uomId,
  title,
  description,
  imageUrl,
  price,
  viewablePrices,
  showAllergensWarning,
  productCategory,
  isVegan,
  isVegetarian,
  date,
  menu,
  site,
  saveScrollPosition,
  cart,
  languageCode,
  onScanButtonClick,
  dark,
  variant = TILE_VARIANT.BIG,
  oneLine,
  onAddFirstItemToCart,
  onAddOrQuantityButtonClick,
  isSuggestion = false,
  pageType,
  isKiosk = false,
  isGuest = false,
  canAddToFavorite,
  tags,
  focusOnRender,
}: ProductTileProps) => {
  const history = useHistory();
  const { label } = useOrderTranslation(__filename);
  const isFavoriteEnabled =
    canAddToFavorite && isFFProductFavouriteEnabled() && !isGuest && !isKiosk;

  const promotionalDiscounts = useSelector(
    (state: { Order: OrderState }) => state.Order.draft?.promotionalDiscountForItems
  );

  const menuItem = getMenuItemById(menuItemId, [menu]);

  const { productPortion, addToFavorites, removeFromFavorites } = useProductPortion(
    menuItemId,
    uomId,
    site?.id
  );

  const {
    state: isCameraAlertOpen,
    toggleOn: openCameraAlert,
    toggleOff: closeCameraAlert,
  } = useToggle(false);

  const {
    portionsFreeItemsInfo,
    isLoading: numberOfRedeemablePortionsIsLoading,
    isPortionProvidingStamp,
    isPortionRedeemableAsReward,
  } = useLoyaltyReward(menuItem?.productPortions ?? [], menu?.id, isGuest);
  const handleStarActionClick = () => {
    if (productPortion?.isFavorite) {
      removeFromFavorites();
    } else {
      addToFavorites();
    }
  };

  const imgInfo =
    imageUrl && isValidHttpUrl(imageUrl)
      ? {
          src: imageUrl,
          alt: title,
        }
      : undefined;
  const image = (
    <ImageWithFallback
      data-testid="product-tile"
      imgInfo={imgInfo}
      imgElement={getProductIllustration(genericCategory as string)}
    />
  );

  const sticker = buildSticker({
    productCategory,
  });

  const vegeChip = buildVegeChip({
    isVegan,
    isVegetarian,
    veganLabel: label('Ref: Vegan'),
    vegeterianLabel: label('Ref: Vegeterian'),
  });

  const allergensWarningChip = buildAllergenChip({
    showAllergensWarning,
    label: label('Ref: Allergens'),
    ariaLabel: label('Ref: Presence of allergen'),
  });

  const loyaltyPointsForOrderingChip =
    !isKiosk &&
    buildLoyaltyPointsForOrderingChip({
      showLoyaltyPointsForOrderingChip: isPortionProvidingStamp,
      label: label('Ref: Equation for stamps per item'),
    });

  const loyaltyRewardChip =
    !isKiosk &&
    buildLoyaltyRewardChip({
      showLoyaltyRewardChip: isPortionRedeemableAsReward,
      label: label('Ref: Loyalty Reward'),
      ariaLabel: label('Ref: Can be redeemed as a Reward in Loyalty Scheme'),
    });

  const chips = [];

  if (allergensWarningChip) {
    chips.push(allergensWarningChip);
  }

  if (vegeChip) {
    chips.push(vegeChip);
  }

  if (loyaltyRewardChip) {
    chips.push(loyaltyRewardChip);
  }

  if (loyaltyPointsForOrderingChip) {
    chips.push(loyaltyPointsForOrderingChip);
  }

  const isoCode = site?.currency?.isoCode ?? '';

  const containsPortionsOrModifiers = hasPortionsOrModifiers(menuItem as MenuItem);
  const { priceWithoutDiscount, discountPrice } = menu?.isOrderable
    ? getFormattedPricesOrderable({
        uomId,
        price: containsPortionsOrModifiers
          ? (menuItem?.minimalPrice as number) || 0
          : (price as number) || 0,
        languageCode,
        promotionalDiscounts,
        isoCode,
      })
    : getFormattedPricesForViewable({
        price: containsPortionsOrModifiers
          ? (menuItem?.minimalPrice as number) || 0
          : (price as number) || 0,
        viewablePrices,
        languageCode,
        label,
        isoCode,
      });

  const baseMenuItemPath = menu?.isOrderable
    ? pagePaths.ProductDetails
    : pagePaths.MenuProductDetails;

  const numberOfRedeemablePortions = portionsFreeItemsInfo.reduce(
    (result, item) => result + item.numberOfFreeItems,
    0
  );

  const menuItemPath = useMemo(
    () =>
      baseMenuItemPath
        .replace(':id', menuItemId.toString())
        .replace(':facilityId', menu?.facilityId)
        .replace(':menuId', menu?.id.toString())
        .replace(':cartItem', 'no-cart')
        .replace(':date', date),
    [baseMenuItemPath, menuItemId, menu?.facilityId, menu?.id, date]
  );

  const onScanBtnClicked = useCallback(async () => {
    const hasPermission = await hasCameraPermission();
    if (hasPermission) {
      if (onScanButtonClick) onScanButtonClick();
    } else {
      openCameraAlert();
    }
  }, [onScanButtonClick, openCameraAlert]);
  const discountedOnly = discountPrice && !containsPortionsOrModifiers;
  const getDisplayPrice = () => {
    let displayPrice = '';

    if (numberOfRedeemablePortionsIsLoading) return <span className={classNames(styles.loading)} />;
    if (numberOfRedeemablePortions > 0) return label('Ref: Free');

    if (discountedOnly && menu?.isOrderable) {
      displayPrice = `${discountPrice}`;
    } else {
      displayPrice = `${
        containsPortionsOrModifiers ? label('Ref: From') : ''
      } ${priceWithoutDiscount}`;
    }
    return displayPrice;
  };

  return (
    <>
      <Tile
        variant={variant}
        oneLineTile={oneLine}
        oneLineDescription={oneLine}
        dark={dark}
        id={id}
        title={title}
        tags={tags}
        description={description}
        image={image}
        focusOnRender={focusOnRender}
        childText={getDisplayPrice()}
        strikethroughChildText={discountedOnly ? priceWithoutDiscount : undefined}
        children={
          menu?.isOrderable ? (
            <ProductCornerAction
              menuItem={getMenuItemById(menuItemId, [menu])}
              facilityId={menu?.facilityId}
              date={toLocalDate(date)}
              menuId={menu?.id}
              saveScrollPosition={saveScrollPosition}
              isScanAndGo={menu?.isScanAndGo}
              cart={cart}
              scanButtonLabel={label('Ref: Scan')}
              onScanButtonClick={onScanBtnClicked}
              onAddFirstItemToCart={onAddFirstItemToCart}
              onAddOrQuantityButtonClick={onAddOrQuantityButtonClick}
              isSuggestion={isSuggestion}
              pageType={pageType}
            />
          ) : (
            <></>
          )
        }
        chips={chips}
        stickers={sticker ? [sticker] : []}
        onClick={() => history?.push(menuItemPath)}
        data-testid={`ProductTile-${id}`}
        preventBubblingForCorner
        cornerContent={
          isFavoriteEnabled ? (
            <StarAction
              isSelected={productPortion?.isFavorite ?? false}
              title={
                productPortion?.isFavorite
                  ? label('Ref: Already in product favorite list. Click to remove it.')
                  : label('Ref: Currently not added to product favorite list. Click to add it.')
              }
              onClick={() => handleStarActionClick()}
              data-testid={`product-tile-star-action-${id}`}
            />
          ) : null
        }
      />
      <Alert
        isOpen={isCameraAlertOpen}
        onDismiss={closeCameraAlert}
        className="popup-warning"
        header={label('Ref: camera access request title')}
        message={label('Ref: camera access request body')}
        buttons={['Ok']}
        data-testid="product-tile-camera-alert"
      />
    </>
  );
};

export default withSite(ProductTile);
