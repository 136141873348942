const labels = {
  PrivacyPolicy: '隐私政策',
  TermsOfSale: '销售条款',
  TermsOfUse: '条款及细则',
  home: '首页',
  anytime: '任何时间',
  anywhere: '任何地方',
  Download: '下载',
  scrollRightButton: '右边的滚动类别',
  scrollLeftButton: '左侧的滚动类别',
  calendar: '日历',
  'See more': '查看详情',
  'See less': '隐藏详情',
  'from (followed by a date, or date and time)': '从',
  'to (followed by a date, or date and time)': '到',
  'on (followed by a date)': '在',
  'at (followed by time)': '在',
  'from (followed by a location)': '从',
  'to (followed by a location)': '至',
  'on (followed by a site name)': '在',
  'from (followed by a person)': '从',
  previous: '上一个',
  next: '下一个',
  success: '成功',
  failure: '失败',
  today: '今天',
  tomorrow: '明天',
  Monday: '星期一',
  Tuesday: '星期二',
  Wednesday: '星期三',
  Thursday: '星期四',
  Friday: '星期五',
  Saturday: '星期六',
  Sunday: '星期日',
  everyday: '每天',
  'open all day': '全天开放',
  'opening hours': '营业时间',
  status: '状态',
  'to be confirmed': '待确认',
  'only (as in filter only this)': '只要',
  edit: '编辑',
  submit: '提交',
  cancel: '取消',
  confirm: '确认',
  reset: '重置',
  Delete: '删除',
  select: '选择',
  switch: '切换',
  close: '关闭',
  clear: '清除',
  remove: '移除',
  logout: '登出',
  go: '转到',
  'see (as in consult this page or document)': '查看',
  'see all (as in see all items)': '查看全部',
  'see more (as in see more details for this item)': '查看更多',
  retry: '重试',
  'Ref: ok': '确认',
  'yes (as in yes I accept)': '确认',
  'no (as in no I do not accept)': '否',
  you: '你',
  'thank you': '谢谢',
  'sorry (standalone)': '抱歉',
  'to home (as in navigate to the homepage)': '转到主页',
  map: '地图',
  list: '列表',
  'Loading...': '载入中...',
  'Refreshing...': '更新中...',
  'Please select': '请选择',
  'Switch site': '切换站点在这里',
  'File size cannot exceed {max file size}': '文件大小不能超过 {max file size}',
  Filters: '筛选器',
  'Add attachment': '添加附件',
  Search: '搜索',
  'More (as in: see more info)': '更多',
  'You can attach a maximum of {max files number} files.':
    '您最多可以附加 {max files number} 个文件。',
  'There are no messages to display.': '没有要显示的消息。',
  'Your selection did not return any result.': '您的选择没有返回任何结果。',
  'Ref: Popup header for missing required fields in a form': '请提供更多信息',
  'Ref: Required field': '{ref} 是必填字段。',
  email: '电子邮件',
  phone: '电话号码',
  Account: '我的账户',
  Contacts: '联系人',
  Content: '内容',
  Events: '活动',
  Facilities: '设施',
  Shop: '店铺',
  Menu: '菜单',
  Menus: '菜单',
  Order: '订单',
  Orders: '历史订单',
  Info: '信息',
  FAQ: '常见问题',
  Feedback: '备注',
  Review: '点评',
  Requests: '服务请求',
  'Request Helpdesk': '請求幫助台',
  Location: '位置',
  Safety: '安全',
  FreeCoffee: '免费咖啡',
  Newsfeed: '新闻源',
  MyOrders: '我的订单',
  LoyaltyStamps: '忠诚邮票',
  Stamps: '邮票',
  Stays: '入住',
  Suppliers: '供应商',
  Surveys: '调查',
  notifications: '通知',
  cart: '购物车',
  'Ref: Reset your order': '重置您的订单',
  'Ref: My language': '我的语言',
  Validate: '证实',
  'contact us': '联系我们',
  'Ref: Start typing': '开始输入',
  Occupancy: '占用',
  'see less': '少看',
  'see more': '查看详情',
  contacts: '联系人',
  Imprint: '印记',
  'Ref: camera access request title': '允许访问相机',
  'Ref: camera access request body': '请允许使用您的相机，以扫描地点的二维码。',
  or: '或者',
  'Ref: Input required': '{input_name}是必需的',
  'Enter a valid email address': '输入一个有效的电子邮件地址',
  'Ref: Input error E-mail already exists': '您输入的此电子邮件已经有一个帐户。请改用。',
  'Password must contain': '密码必须包含：',
  'Ref: Input password error strength': '密码必须至少包含8个字符，1个数字，1个字母和1个特殊字符',
  Password: '密码',
  'New password': '新密码',
  'Confirm password': '确认密码',
  'Ref: Password does not match': '密码和确认密码不匹配',
  'Ref: Old password does not match': '旧密码不匹配',
  'Ref: Password validation min8Characters': '至少8位字符',
  'Ref: Password validation min1Number': '至少1位数字',
  'Ref: Password validation min1Letter': '至少1位字母',
  'Ref: Password validation min1SpecialChar': '至少1位特殊符号',
  Continue: '继续',
  'Enter a valid phone number': '输入有效的电话号码',
  'Phone is required': '电话为必填项',
  'Go to home': '转到主页',
  'Something went wrong': '出错了',
  'Something went wrong, data not loaded': '出错了，数据未加载',
  'Change password': '更改密码',
  'remove one': '删除',
  'add one': '增加',
  'Confirm mobile phone': '确认手机',
  'Confirm Filters': '确认筛选',
  'Reset Filters': '重置筛选',
  '{selectedNum} active filters': '{selectedNum} 活动过滤器',
  'Wrong input icon': '输入图标错误',
  'Correct input icon': '正确输入图标',
  'Show input text icon': '显示输入文本图标',
  'Hide input text icon': '隐藏输入文本图标',
  'Required fields info': '*必需的字段',
  'Ref: Select site on the map': '在地图上选择您的网站',
  'Ref: Geolocation disabled': '地理位置被禁用。要查看附近的站点，请启用它。',
  'Ref: Reaction - LIKE': '赞',
  'Ref: Reaction - LOVE': '喜欢',
  'Ref: Reaction - INTERESTING': '有趣',
  'Ref: Reaction - CONGRATE': '祝贺',
  'Ref: Share': '分享',
  'Ref: Send by Email': '通过电子邮件发送',
  'Ref: Copy the link': '复制链接',
  'Ref: Link was copied': '链接被复制',
  'Ref: unable to copy url': '无法复制URL',
  'Ref: External link': '外部链接',
  'Ref: Step': '步',
  'input field': '输入字段',
  'Ref: Zoom': '飞涨',
  'Increase hours': '增加工作时间',
  'Decrease hours': '减少小时',
  Hours: '小时',
  Minutes: '分钟',
  'Ref: filter label: favorites': '仅显示我的最爱',
  'Ref: filter label: max calories': '最大卡路里',
  'Ref: filter label: dishes': '菜肴',
  'Ref: filter label: allergens': '过敏原',
  'Ref: filter label: highlight': '高亮显示或隐藏带有菜肴的过滤器',
  'Ref: Highlight': '强调',
  'Ref: Hide': '隐藏',
  'Ref: Vegan': '素食主义者',
  'Ref: Vegeterian': '素食者',
  'Ref: Mindful': '介意',
  'Ref: Highlight text': '在这里，您可以选择在Hightlight或隐藏包含某些过敏原的菜单中的菜肴',
  'Ref: Just now': '现在',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes}几分钟前',
  'Ref: 1 hour ago': '1小时前',
  'Ref: {amountHours} hours ago': '{amountHours}小时前',
  'Ref: Yesterday at {time}': '昨天{time}',
  'Ref: {date} at {time}': '{date} at {time}',
  'Ref: filter label: date': '日期',
  'Ref: expand menu': '展开菜单',
  'Ref: collapse menu': '崩溃菜单',
  'Ref: My site': '我的地点',
  'Ref: Recent': '最近的',
  'Ref: Services': '服务',
  'Ref: products in cart': '购物车中的商品数量',
  'Ref: no product in cart': '目前没有添加商品',
  'Ref: Survey': '调查',
  'Access to camera was denied. Please allow access and try again':
    '访问相机被拒绝。请允许访问并重试',
  'Add an attachment': '添加附件',
  'Take a picture': '拍照',
  'Add a file from your device': '从您的设备添加文件',
  'Ref: Loading page content, please wait': '加载页面内容，请等待',
  Star: '星',
  Stars: '星',
  'Accommodation Request': '住宿请求',
  'Ref: Guest Accomodation Request': '宾客要求',
  'Ref: Search a menu, product or facility': '搜索菜单，产品或设施',
  ConnectionLost: '网络连接丢失',
  ConnectionRestored: '网络连接还原',
  'Ref: Skip to main content': '跳过主要内容',
  'Ref: No options': '没有选项',
  'Ref: Global search modal': '全局搜索模式',
  'Ref: Search a facility': '搜索设施',
  'Ref: Search a menu or product': '搜索菜单或产品',
  'Ref: Reaction': '反应',
  'Ref: times': '时代',
  'Ref: reaction. Change reaction': '反应。改变反应',
  'Ref: No reaction. React': '没有反应。反应',
  'Ref: LIKE': '喜欢',
  'Ref: LOVE': '爱',
  'Ref: INTERESTING': '有趣的',
  'Ref: CONGRATE': '恭喜',
  'Ref: Open search': '打开搜索框',
  'Ref: Close search': '关闭搜索框',
  'Ref: Total file size msg': '总文件大小不能超过{max file size}',
  'Ref: Oops Title': '哎呀！',
  'Ref: Well Done': '做得好 {party_emoji}',
  'Ref: Navigation arrow - Continue': '导航箭头 - 继续',
  'Ref: Reload': '重新加载',
  'Ref: All Dates': '所有日期',
  'Ref: Today': '今天',
  'Ref: Validate': '证实',
  'Ref: toast error title': '哎呀！',
  'Ref: toast error subtitle': '发生了错误。请再试一次。',
  'Ref: Calendar': '日历',
  'Ref: Selected': '选定',
  'Ref: Previous Month': '前一个月',
  'Ref: Next Month': '下个月',
  'This module has been retired': '该模块已退役',
};
export default labels;
