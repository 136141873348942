import { TranslationProps } from '../../../../localization/localization.types';
import { FacilityMenu, SuggestedMenuItem } from '../../types/orderState.types';
import { ProductItem } from '../../types/productList.types';

export const defaultDropdownOption = (label: TranslationProps['label']) => {
  return {
    value: '000',
    label: label('Ref: Any menu'),
  };
};

export const getDropdownOptions = (menus: FacilityMenu[], label: TranslationProps['label']) => {
  const dropdownOptions = menus.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));
  dropdownOptions.length !== 1 && dropdownOptions.unshift(defaultDropdownOption(label));

  return dropdownOptions;
};

export const mapItemToProductItem = (item: SuggestedMenuItem): ProductItem | null => {
  const defaultPortion = item.productPortions.find((x) => x.isDefault === true);
  if (!defaultPortion) return null;

  return {
    id: `${item.menuId}_${item.menuItemId}`,
    menuItemId: item.menuItemId,
    uomId: defaultPortion.uomId,
    title: item.name,
    description: '',
    imageUrl: item.listImage ?? '',
    price: item.price,
    isVegan: defaultPortion.isVegan,
    isVegetarian: defaultPortion.isVegetarian,
    showAllergensWarning: false,
    productCategory: item.productCategory,
    genericCategory: item.genericCategory,
  };
};
