import classNames from 'classnames';
import { useRef, useMemo } from 'react';

import Title, { TITLE_SIZE, TITLE_TAG } from '../../atoms/Title';
import SimpleFormPage from '../SimpleFormPage/SimpleFormPage';

import { INoticePageProps, NOTICEPAGE_TYPE } from './NoticePage.types';

import { DefaultSuccessIllustration, DeviceErrorIllustration } from '@/assets/illustrations';
import { CheckCiclefillIcon } from '@/assets/index';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import useWindowSize from '@/helpers/hooks/useWindowSize';

import successPageStyles from '../../../components/templates/SuccessPage/SuccessPage.module.css';
import styles from './NoticePage.module.css';

const NoticePage = ({
  id,
  hasBackLink = false,
  pageTitle = '',
  tabTitle,
  title,
  subtitle,
  content,
  img,
  actions,
  children,
  withNavBar = true,
  withTitleBar = true,
  note,
  hideAccountWidget,
  hideNotificationsWidget,
  hideAllWidgets,
  type,
}: INoticePageProps) => {
  const contentRef = useRef();
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < 1024;

  const deviceErrorIllustration = useMemo(
    () => (isMobile ? <DeviceErrorIllustration /> : null),
    [isMobile]
  );
  //SuccessIllustration as a fallback when no type and img properties
  const pageImage = img ||
    (type === NOTICEPAGE_TYPE.SUCCESS && <DefaultSuccessIllustration />) ||
    (type === NOTICEPAGE_TYPE.ERROR && <DeviceErrorIllustration />) || (
      <DefaultSuccessIllustration />
    );

  const icon =
    type !== undefined
      ? (type === NOTICEPAGE_TYPE.SUCCESS && (
          <CheckCiclefillIcon width="72" height="72" className={successPageStyles.icon} />
        )) ||
        (type === NOTICEPAGE_TYPE.ERROR && deviceErrorIllustration)
      : null;

  return (
    <SimpleFormPage
      hasBackLink={hasBackLink}
      title={pageTitle}
      contentRef={contentRef}
      withNavBar={withNavBar}
      withTitleBar={withTitleBar}
      hideAccountWidget={hideAccountWidget}
      hideNotificationsWidget={hideNotificationsWidget}
      hideAllWidgets={hideAllWidgets}
      tabTitle={tabTitle}
    >
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            <div id="notice_container" className={styles.noticeContainer}>
              {icon}
              {(title || subtitle) && (
                <hgroup>
                  {title && (
                    <Title
                      tag={TITLE_TAG.H2}
                      size={TITLE_SIZE.HEADLINES}
                      data-testid={`${id}-notice-title`}
                    >
                      {title}
                    </Title>
                  )}
                  {subtitle && (
                    <Title tag={TITLE_TAG.H2} data-testid={`${id}-notice-subtitle`}>
                      {subtitle}
                    </Title>
                  )}
                </hgroup>
              )}
              {content && (
                <p
                  id={id}
                  className={classNames(styles.noticeText, 'bodySBold')}
                  data-testid={`${id}-notice-content`}
                >
                  {content}
                </p>
              )}
              {note && (
                <div
                  id={id}
                  className={classNames(styles.noticeText, styles.noticeNote, 'bodySDefault')}
                  data-testid={`${id}-notice-note`}
                >
                  {note}
                </div>
              )}
              {children}
            </div>
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>{pageImage}</Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};

export default NoticePage;
