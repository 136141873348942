const labels = {
  PrivacyPolicy: 'politica sulla riservatezza',
  TermsOfSale: 'Termini delle vendite',
  TermsOfUse: 'Termini & Condizioni',
  home: 'Casa',
  anytime: 'in qualsiasi momento',
  anywhere: 'ovunque',
  Download: 'Scarica',
  scrollRightButton: 'Scorri le categorie a destra',
  scrollLeftButton: 'Le categorie di scorrimento a sinistra',
  calendar: 'Calendario',
  'See more': 'Vedi altro',
  'See less': 'Vedi di meno',
  'from (followed by a date, or date and time)': 'a partire dal',
  'to (followed by a date, or date and time)': 'a',
  'on (followed by a date)': 'Sopra',
  'at (followed by time)': 'A',
  'from (followed by a location)': 'a partire dal',
  'to (followed by a location)': 'a',
  'on (followed by a site name)': 'Sopra',
  'from (followed by a person)': 'a partire dal',
  previous: 'Precedente',
  next: 'Il prossimo',
  success: 'successo',
  failure: 'fallimento',
  today: 'Oggi',
  tomorrow: 'Domani',
  Monday: 'Lunedi',
  Tuesday: 'martedì',
  Wednesday: 'mercoledì',
  Thursday: 'giovedi',
  Friday: 'Venerdì',
  Saturday: 'Sabato',
  Sunday: 'Domenica',
  everyday: 'Ogni giorno',
  'open all day': 'Aperto tutto il giorno',
  'opening hours': 'Orari di apertura',
  status: 'stato',
  'to be confirmed': 'Essere confermato',
  'only (as in filter only this)': 'solo',
  edit: 'modificare',
  submit: 'Invia',
  cancel: 'Annulla',
  confirm: 'Confermare',
  reset: 'Ripristina',
  Delete: 'Elimina',
  select: 'Selezionare',
  switch: 'interruttore',
  close: 'Vicino',
  clear: 'chiaro',
  remove: 'rimuovere',
  logout: 'disconnettersi',
  go: 'andare',
  'see (as in consult this page or document)': 'vedere',
  'see all (as in see all items)': 'Vedi tutti',
  'see more (as in see more details for this item)': 'Vedi altro',
  retry: 'riprovare',
  'Ref: ok': 'Ok',
  'yes (as in yes I accept)': 'sì',
  'no (as in no I do not accept)': 'No',
  you: 'Voi',
  'thank you': 'Grazie',
  'sorry (standalone)': 'spiacente',
  'to home (as in navigate to the homepage)': 'alla home page',
  map: 'carta geografica',
  list: 'elenco',
  'Loading...': 'Caricamento in corso...',
  'Refreshing...': 'In aggiornamento...',
  'Please select': 'Si prega di selezionare',
  'Switch site': 'Cambia sito qui',
  'File size cannot exceed {max file size}':
    'La dimensione del file non può superare la {max file size}',
  Filters: 'filtri',
  'Add attachment': 'Aggiungi allegato',
  Search: 'Ricerca',
  'More (as in: see more info)': 'Di Più',
  'You can attach a maximum of {max files number} files.':
    'Puoi allegare un massimo di {max files number}.',
  'There are no messages to display.': 'Non ci sono messaggi da visualizzare.',
  'Your selection did not return any result.': 'La tua selezione non ha prodotto alcun risultato.',
  'Ref: Popup header for missing required fields in a form':
    'Si prega di fornire ulteriori informazioni',
  'Ref: Required field': '{ref} è un campo obbligatorio.',
  email: 'E-mail',
  phone: 'Numero di cellulare',
  Account: 'account',
  Contacts: 'Contatti',
  Content: 'Contenuto',
  Events: 'eventi',
  Facilities: 'Strutture',
  Shop: 'Negozio',
  Menu: 'Menù',
  Menus: 'Menu',
  Order: 'Ordine',
  Orders: 'Ordini',
  Info: 'Informazioni',
  FAQ: 'FAQ',
  Feedback: 'Risposta',
  Review: 'Revisione',
  Requests: 'richieste',
  'Request Helpdesk': "Richiedi l'Helpdesk",
  Location: 'Posizione',
  Safety: 'Sicurezza',
  FreeCoffee: 'Caffè gratuito',
  Newsfeed: 'Newsfeed',
  MyOrders: 'I miei ordini',
  LoyaltyStamps: 'Francobolli fedeltà',
  Stamps: 'Francobolli',
  Stays: 'soggiorni',
  Suppliers: 'Fornitori',
  Surveys: 'indagini',
  notifications: 'notifiche',
  cart: 'carrello',
  'Ref: Reset your order': 'Ripristina il tuo ordine',
  'Ref: My language': 'Mio linguaggio',
  Validate: 'Convalidare',
  'contact us': 'Contattaci',
  'Ref: Start typing': 'Iniziare a digitare',
  Occupancy: 'Occupazione',
  'see less': 'Vedere meno',
  'see more': 'Vedi altro',
  contacts: 'Contatti',
  Imprint: 'Impronta',
  'Ref: camera access request title': "Consenti l'accesso alla fotocamera",
  'Ref: camera access request body':
    "È necessario abilitare l'uso della fotocamera su questo sito per utilizzare lo scanner del codice QR",
  or: 'o',
  'Ref: Input required': '{input_name} è richiesto',
  'Enter a valid email address': 'Inserire un indirizzo email valido',
  'Ref: Input error E-mail already exists':
    "C'è già un account con questa e -mail che hai inserito. Esegui il login.",
  'Password must contain': 'La password deve contenere:',
  'Ref: Input password error strength':
    'La password deve contenere almeno 8 caratteri, 1 numero, 1 lettera e 1 carattere speciale',
  Password: 'Password',
  'New password': 'Nuova password',
  'Confirm password': 'Conferma password',
  'Ref: Password does not match': 'La password e la conferma della password non corrispondono',
  'Ref: Old password does not match': 'La vecchia password non corrisponde',
  'Ref: Password validation min8Characters': 'Almeno 8 caratteri',
  'Ref: Password validation min1Number': 'Almeno 1 cifra',
  'Ref: Password validation min1Letter': 'Almeno 1 lettera',
  'Ref: Password validation min1SpecialChar': 'Almeno 1 carattere speciale',
  Continue: 'Continua',
  'Enter a valid phone number': 'Immettere un numero di telefono valido',
  'Phone is required': 'È richiesto il telefono',
  'Go to home': "Torna all'inizio",
  'Something went wrong': 'Qualcosa è andato storto',
  'Something went wrong, data not loaded': 'Qualcosa è andato storto, i dati non caricati',
  'Change password': 'Cambia la password',
  'remove one': 'rimuoverne uno',
  'add one': 'Aggiungi uno',
  'Confirm mobile phone': 'Conferma il cellulare',
  'Confirm Filters': 'Conferma i filtri',
  'Reset Filters': 'Ripristina i filtri',
  '{selectedNum} active filters': '{selectedNum} Filtri attivi',
  'Wrong input icon': 'Icona di input sbagliata',
  'Correct input icon': 'Icona di input corretta',
  'Show input text icon': "Mostra l'icona del testo di input",
  'Hide input text icon': 'Nascondi icona del testo input',
  'Required fields info': '* Campi richiesti',
  'Ref: Select site on the map': 'Seleziona il tuo sito sulla mappa',
  'Ref: Geolocation disabled':
    'La geolocalizzazione è disabilitata. Per vedere i siti vicini, abilitarlo.',
  'Ref: Reaction - LIKE': 'Piace',
  'Ref: Reaction - LOVE': 'Amare',
  'Ref: Reaction - INTERESTING': 'Interessante',
  'Ref: Reaction - CONGRATE': 'Si congratula',
  'Ref: Share': 'Condividere',
  'Ref: Send by Email': 'Inviare per email',
  'Ref: Copy the link': 'Copia il link',
  'Ref: Link was copied': 'Il collegamento è stato copiato',
  'Ref: unable to copy url': "Impossibile copiare l'URL",
  'Ref: External link': 'Link esterno',
  'Ref: Step': 'Fare un passo',
  'input field': 'Campo di inserimento',
  'Ref: Zoom': 'Ingrandisci',
  'Increase hours': 'Aumenta le ore',
  'Decrease hours': 'Diminuire le ore',
  Hours: 'Ore',
  Minutes: 'Minuti',
  'Ref: filter label: favorites': 'Mostra solo i miei preferiti',
  'Ref: filter label: max calories': 'Max Calorie',
  'Ref: filter label: dishes': 'Piatti',
  'Ref: filter label: allergens': 'Allergeni',
  'Ref: filter label: highlight': 'Evidenziare o nascondere i filtri con i piatti',
  'Ref: Highlight': 'Evidenziare',
  'Ref: Hide': 'Nascondere',
  'Ref: Vegan': 'Vegano',
  'Ref: Vegeterian': 'Vegetariano',
  'Ref: Mindful': 'Consapevole',
  'Ref: Highlight text':
    'Qui è possibile optare a Hightlight o nascondere i piatti sul menu che contengono determinati allergeni',
  'Ref: Just now': 'Proprio adesso',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} minuti fa',
  'Ref: 1 hour ago': '1 ora fa',
  'Ref: {amountHours} hours ago': '{amountHours} ore fa',
  'Ref: Yesterday at {time}': 'Ieri a {time}',
  'Ref: {date} at {time}': '{date} a {time}',
  'Ref: filter label: date': 'Data',
  'Ref: expand menu': 'Espandi il menu',
  'Ref: collapse menu': 'menu di collasso',
  'Ref: My site': 'Il mio sito',
  'Ref: Recent': 'Recente',
  'Ref: Services': 'Servizi',
  'Ref: products in cart': 'Numero di prodotti nel carrello',
  'Ref: no product in cart': 'Nessun prodotto nel carrello',
  'Ref: Survey': 'Sondaggio',
  'Access to camera was denied. Please allow access and try again':
    "L'accesso alla fotocamera è stato negato. Si prega di consentire l'accesso e riprovare",
  'Add an attachment': 'Aggiungi un allegato',
  'Take a picture': 'Fai una foto',
  'Add a file from your device': 'Aggiungi un file dal tuo dispositivo',
  'Ref: Loading page content, please wait': 'Caricamento del contenuto della pagina, attendere',
  Star: 'Stella',
  Stars: 'Stelle',
  'Accommodation Request': 'Richiesta di alloggio',
  'Ref: Guest Accomodation Request': 'Richiesta ospite',
  'Ref: Search a menu, product or facility': 'Cerca un menu, un prodotto o una struttura',
  ConnectionLost: 'Connessione di rete persa',
  ConnectionRestored: 'Connessione di rete ripristinata',
  'Ref: Skip to main content': 'Passa ai contenuti principali',
  'Ref: No options': 'Nessuna opzione',
  'Ref: Global search modal': 'Modal di ricerca globale',
  'Ref: Search a facility': 'Cerca una struttura',
  'Ref: Search a menu or product': 'Cerca un menu o un prodotto',
  'Ref: Reaction': 'Reazione',
  'Ref: times': 'volte',
  'Ref: reaction. Change reaction': 'reazione. Cambia reazione',
  'Ref: No reaction. React': 'Nessuna reazione. Reagire',
  'Ref: LIKE': 'Come',
  'Ref: LOVE': 'Amore',
  'Ref: INTERESTING': 'Interessante',
  'Ref: CONGRATE': 'Congruendo',
  'Ref: Open search': 'Apri la casella di ricerca',
  'Ref: Close search': 'Chiudi la casella di ricerca',
  'Ref: Total file size msg': 'La dimensione totale del file non può superare {max file size}',
  'Ref: Oops Title': 'Oops!',
  'Ref: Well Done': 'Ben fatto {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Freccia di navigazione - Continua',
  'Ref: Reload': 'Ricaricare',
  'Ref: All Dates': 'Tutte le date',
  'Ref: Today': 'Oggi',
  'Ref: Validate': 'Convalidare',
  'Ref: toast error title': 'Oops!',
  'Ref: toast error subtitle': 'Si è verificato un errore. Per favore riprova.',
  'Ref: Calendar': 'Calendario',
  'Ref: Selected': 'Selezionato',
  'Ref: Previous Month': 'Il mese scorso',
  'Ref: Next Month': 'Il prossimo mese',
  'This module has been retired': 'Questo modulo è stato ritirato',
};
export default labels;
