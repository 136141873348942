import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TranslationProps } from '../../../../localization/localization.types';
import { ISite } from '../../../Sites/types/sites.types';
import { useGetAvailablePromotionsQuery } from '../../api';
import { PromotionsTile } from '../../components/PromotionsTile/PromotionsTile';
import { findProductByUomId } from '../../helpers/order.helper';
import { getSortedPromotions, isPromotionActive } from '../../helpers/promotions.helper';
import { PromotionInfo, StateWithOrder } from '../../types/orderState.types';

export const usePromotionsMiniWidget = (site: ISite, label: TranslationProps['label']) => {
  const { data: availablePromotions } = useGetAvailablePromotionsQuery({
    siteId: site.id,
    useErrorBoundary: false,
  });
  const { draft, menus } = useSelector((state: StateWithOrder) => state.Order);

  const getMenuItemsForPromotion = useCallback(
    (promotion: PromotionInfo) => {
      if (promotion.items?.length) {
        return promotion.items
          .map((item) => {
            const menu =
              menus && item.menuIds
                ? menus.find((menu) => item.menuIds.some((im) => im === menu.id))
                : undefined;
            const product = findProductByUomId(menu, item.uomId);
            return {
              facilityId: menu?.facilityId,
              menuId: menu?.id,
              menuItemId: product?.menuItemId,
              date: menu?.date,
            };
          })
          .filter((menuItem) => menuItem);
      } else {
        return;
      }
    },
    [menus]
  );

  const availablePromotionsSorted = useMemo(
    () => getSortedPromotions(availablePromotions, draft, getMenuItemsForPromotion),
    [availablePromotions, draft, getMenuItemsForPromotion]
  );

  const promoTiles = useMemo(() => {
    const promotionTiles = availablePromotionsSorted?.map((promotion, index) => {
      const isActive = isPromotionActive(promotion.id, draft?.promotionsApplied);
      return (
        <PromotionsTile
          key={`promotionItem--${index}`}
          items={getMenuItemsForPromotion(promotion)}
          id={promotion.id}
          name={promotion.name}
          isActive={isActive}
          description={promotion.description}
          label={label}
        />
      );
    });
    return promotionTiles;
  }, [availablePromotionsSorted, label, getMenuItemsForPromotion, draft?.promotionsApplied]);

  return { promoTiles };
};
