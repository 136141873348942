import { PDFGenerator } from '@awesome-cordova-plugins/pdf-generator';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { isPlatform } from '@ionic/react';
import { History } from 'history';
import { LinkProps } from 'react-router-dom';
import sanitize from 'sanitize-html';

import { APP_DISPLAY_NAME, APP_NAME } from '../constants';
import { themeKeys } from '../modules/Core/helpers/helpers';
import { ServiceDefinition } from '../modules/defaultServices';
import { ISite } from '../modules/Sites/types/sites.types';
import { store } from '../store';
import { AppSpecificSettingsType } from '../types';
import { User } from '../types/user.types';

import { downloadBase64asFile } from './file';

export const isIosMobile = () => {
  return isPlatform('ios') && (isPlatform('capacitor') || isPlatform('ipad'));
};

export const download = async (data: BlobPart, filename: string, type: string) => {
  const utf8Bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const file = new Blob([utf8Bom, data], { type: type });

  if (Capacitor.isNativePlatform()) {
    try {
      const base64Data = await blobToBase64(file);
      downloadBase64asFile(base64Data, filename, type);
    } catch (error) {
      console.error('Error sharing file:', error);
    }
  } else if (window.navigator.msSaveOrOpenBlob) {
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    // Others
    var a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const addParamsToSurveyUrl = (
  url: string,
  { user, site }: { user: User; site: Pick<ISite, 'id' | 'name'> }
): string => {
  const urlObj = new URL('', url);
  const data = {
    contact_id: user.contactId,
    contact_first_name: user.firstName,
    contact_last_name: user.lastName,
    site_id: site.id,
    site_name: site.name,
    contract_id: user.contract?.id,
    contract_name: user.contract?.name,
  };

  for (const [key, value] of Object.entries(data)) {
    if (value) {
      urlObj.searchParams.append(key, value);
    }
  }

  return urlObj.toString();
};

export const openUrl = async (url: string, target: string = '_blank') => {
  const theme = store.getState().Core.context.theme?.settings || [];

  // TODO see src/index.js applyTheme for more info
  const defaultThemeSettings = JSON.parse(process.env.REACT_APP_DEFAULT_THEME_JSON || '[]');

  // Get theme color from Json, or fallback to original
  const fallbackPrimaryColor =
    defaultThemeSettings.find((el: { key: string; value: string }) =>
      themeKeys.primaryColor.includes(el.key)
    )?.value || '#283897';

  // Get theme color from context or fallback to json
  const primaryColor =
    theme.find((el: { key: string; value: string }) => themeKeys.primaryColor.includes(el.key))
      ?.value || fallbackPrimaryColor;

  await Browser.open({
    url,
    windowName: target,
    toolbarColor: primaryColor,
  });

  return;
};

export const getCurrentLanguageCode = () => store?.getState().Shared.language.currentLanguageCode;

export const isKiosk = getAppName() === APP_NAME.BITEKIOSK;
// same as isKiosk - but const variable can not be mocked
export const isKioskApp = () => getAppName() === APP_NAME.BITEKIOSK;

export const prepareAddress = (address?: {
  line1: string | null;
  line2: string | null;
  line3: string | null;
  city: string | null;
  postalCode: string | null;
  stateOrProvince: string | null;
  country: string | null;
}) => {
  if (!address) return [];

  let arrAddress = [];
  if (address.line1) arrAddress.push(address.line1);
  if (address.line2) arrAddress.push(address.line2);
  if (address.line3) arrAddress.push(address.line3);

  let arrCityAndCode = [];
  if (address.city) arrCityAndCode.push(address.city);
  if (address.postalCode) arrCityAndCode.push(address.postalCode);
  if (arrCityAndCode.length)
    arrAddress.push(arrCityAndCode.reduce((addr, elem) => addr + ', ' + elem));

  if (address.stateOrProvince) arrAddress.push(address.stateOrProvince);
  if (address.country) arrAddress.push(address.country);

  return arrAddress;
};

export const getImageSrcFromInfo = (imgInfo: { mimeType: string; content: string }) => {
  if (imgInfo.content?.includes('data:')) return imgInfo.content;
  return 'data:' + imgInfo.mimeType + ';base64, ' + imgInfo.content;
};

// TODO: To be improved, can be moved to css approach
// Display only maxLength first characters of the file name with the entire the extension
export const getDisplayFileName = (fileName: string, maxLength: number = 15) => {
  const fileNameParts = fileName.split('.');
  if (fileNameParts.length > 1) {
    const extension = fileNameParts[fileNameParts.length - 1];
    let displayFileName = fileNameParts.slice(0, -1).join('.');

    if (displayFileName.length > maxLength) {
      displayFileName = displayFileName.substring(0, maxLength);
      return `${displayFileName}….${extension}`;
    }
    return `${displayFileName}.${extension}`;
  }
  return fileName;
};

export const getAppDisplayName = () => {
  if (!process.env.REACT_APP_APP_NAME) {
    return '';
  }
  const appId = process.env.REACT_APP_APP_NAME!.toUpperCase();

  return APP_DISPLAY_NAME[appId as keyof typeof APP_DISPLAY_NAME];
};

// TODO due to some issues with TS compiling in src/config.ts, APP_NAME import was called after this function was invoked. Hotfixed with passing APP_NAME as a argument.
export function getAppName(appNames = null as any): APP_NAME | '' {
  if (!process.env.REACT_APP_APP_NAME) {
    return '';
  }

  const app = process.env.REACT_APP_APP_NAME!.toUpperCase();

  const appNamesSource = appNames || APP_NAME;

  return appNamesSource[app as keyof typeof appNamesSource];
}

export const getAppSpecificData = <T>(settings: AppSpecificSettingsType<T>): T => {
  const app = getAppName();

  if (app === '' || !settings[app]) return settings.default;

  return settings[app] ?? settings.default;
};

export const isServiceType = (service: ServiceDefinition, type: string | Array<string>) => {
  return service.name === type || !!type.includes(service.name);
};

export const updateRelativePathUrl = (originalText: string) => {
  return Capacitor.isNativePlatform()
    ? originalText.replaceAll(/(href=")(\/.*?">)/g, `$1${process.env.REACT_APP_HOST}$2`)
    : originalText;
};

export const isExternalUrl = ({ to }: LinkProps): boolean => {
  return typeof to === 'string' && /^https?:\/\//i.test(to);
};

export const widgetTileAction = (
  e: React.MouseEvent<HTMLInputElement>,
  path: string,
  history: History
) => {
  if (!e.defaultPrevented && path && isExternalUrl({ to: path })) {
    openUrl(path);
  } else {
    history.push(path);
  }
};

export const sanitizeHtml = (input: string, options?: sanitize.IOptions): string => {
  const defaultOptions: sanitize.IOptions = {
    allowedTags: sanitize.defaults.allowedTags.concat(['img', 'del']),
    allowedAttributes: { img: ['src', 'alt'], a: ['href'], '*': ['style', 'title'] },
    allowedStyles: {
      '*': {
        'list-style-type': [/.*/i],
        // Match HEX and RGB
        color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'text-align': [/^left$/, /^right$/, /^center$/],
        // Match any number with px, em, or %
        'font-size': [/^\d+(?:px|em|%)$/],
      },
    },
  };
  return sanitize(input, { ...defaultOptions, ...options });
};

export const waitSeconds = async (waitSeconds: number) => {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), 1000 * waitSeconds));
};

export const saveHtmlAsPdf = async (html: string) => {
  const { platform } = await Device.getInfo();

  if (platform === 'web') {
    const iframe = document.createElement('iframe');

    try {
      window.document.body.appendChild(iframe);
      iframe.contentWindow?.document.open();
      iframe.contentWindow?.document.write(html);
      iframe.contentWindow?.document.close();

      iframe.contentWindow?.print();
    } finally {
      try {
        window.document.body.removeChild(iframe);
      } catch (x) {}
    }
  } else {
    const options = {
      documentSize: 'A4',
      type: 'share',
    };

    await PDFGenerator.fromData(html, options);
  }
};
