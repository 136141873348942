import { IonBadge } from '@ionic/react';

import {
  Filter,
  FilterOption,
  FilterPosition,
  FilterType,
} from '../../../../components/atoms/Filters/Filters.types';
import { TranslationProps } from '../../../../localization/localization.types';
import { defaultValues } from '../../config';
import { cacheSelectedAllergens, displayAllergen } from '../../helpers/allergens.helper';
import { isFFProductFavouriteEnabled } from '../../helpers/feature.flags.helper';
import { Allergen, AllergenType, FacilityMenuSource } from '../../types/orderState.types';
import {
  HightlightAllergenFilterValues,
  ProductListItem,
  Section,
} from '../../types/productList.types';

import { BUTTON_LOOK } from '@/components/atoms/Button';
import { isKiosk } from '@/helpers/misc';

import styles from './ProductsList.module.css';

const allOptionsLabel = 'all';
const veganValue = 'vegan';
const vegetarianValue = 'vegetarian';
const mindfulValue = 'mindful';

export const buildAllergens = (allergens: Allergen[]): FilterOption[] => {
  return allergens?.map((allergen) => {
    return {
      value: allergen.allergen,
      label: allergen.name,
      icon: displayAllergen(allergen.allergen),
    };
  });
};

const getCategoriesOptions = (sections: Section[], label: TranslationProps['label']) => {
  const isOnlyOneChoice = sections.length === 1;
  if (isOnlyOneChoice) {
    return [];
  }
  const options = sections.map((section) => ({
    value: section.title,
    label: label(section.title, { textTransform: 'capitalize' }),
  }));

  return [
    {
      value: allOptionsLabel,
      label: label('Ref: all', { textTransform: 'capitalize' }),
      default: true,
    },
    ...options,
  ];
};

export const getDishes = (label: TranslationProps['label']): FilterOption[] => {
  const dished = [
    {
      value: veganValue,
      label: label('Ref: Vegan'),
      icon: (
        <IonBadge className={styles.vegeBadge} color="primary">
          VN
        </IonBadge>
      ),
    },
    {
      value: vegetarianValue,
      label: label('Ref: Vegeterian'),
      icon: (
        <IonBadge className={styles.vegeBadge} color="primary">
          V
        </IonBadge>
      ),
    },
    {
      value: mindfulValue,
      label: label('Ref: Mindful'),
      icon: (
        <IonBadge className={styles.vegeBadge} color="primary">
          M
        </IonBadge>
      ),
    },
  ];

  return dished;
};

export const getFilters = ({
  label,
  menuItemsList,
  sections,
  moments,
  allergens,
  hideAllergens,
  isGuest,
  menuSource,
  setHideAllergens,
  selectedAllergens,
  setSelectedAllergens,
  setSelectedMoment,
}: {
  menuItemsList: ProductListItem[];
  sections: Section[];
  moments: string[];
  allergens: Allergen[];
  hideAllergens: boolean;
  isGuest: boolean;
  menuSource: FacilityMenuSource;
  setHideAllergens: (val: boolean) => void;
  selectedAllergens: AllergenType[];
  setSelectedAllergens: (allergens: AllergenType[]) => void;
  setSelectedMoment: (moment: string) => void;
} & TranslationProps): Filter[] => {
  const getMomentPosition = () => {
    if (moments.length < 2) return FilterPosition.INVISIBLE;
    return FilterPosition.NOT_IN_MODAL;
  };

  const filters: Filter[] = [
    {
      id: 'filter_moment',
      position: getMomentPosition(),
      name: label('Ref: filter label: moment', { textTransform: 'capitalize' }),
      withTitle: false,
      options: moments.map((moment) => ({
        value: moment.toLowerCase(),
        label: label(moment, { textTransform: 'capitalize' }),
      })),
      displayType: FilterType.DROPDOWN,
      look: BUTTON_LOOK.TERTIARY,
      multiple: false,
      apply: (selectedValues: string[]) => {
        let selectedValue: string;
        if (!selectedValues?.length) {
          selectedValue = moments[0];
        } else {
          selectedValue = selectedValues[0];
        }
        setSelectedMoment(selectedValue?.toLowerCase());

        return menuItemsList
          .filter((item) => item.moment?.toLowerCase() === selectedValue.toLowerCase())
          .map((item) => item.id);
      },
    },
    {
      id: 'filter_category',
      position: FilterPosition.NOT_IN_MODAL,
      name: label('Ref: filter label: category', { textTransform: 'capitalize' }),
      withTitle: false,
      options: getCategoriesOptions(sections, label),
      displayType: FilterType.EXPANDED,
      multiple: false,
      apply: (selectedValues: string[]) => {
        if (!selectedValues?.length || selectedValues[0] === allOptionsLabel)
          return menuItemsList.map((item) => item.id);
        const selectedValue = selectedValues[0]?.toLowerCase();
        const filterMenuItems = menuItemsList.filter(
          (item) => item.category?.toLowerCase() === selectedValue
        );

        return filterMenuItems.map((item) => item.id);
      },
    },
  ];

  const areFavoritesEnabled = isFFProductFavouriteEnabled() && !isGuest && !isKiosk;
  if (areFavoritesEnabled) {
    filters.push({
      id: 'filter_favorites',
      position: FilterPosition.ALWAYS_IN_MODAL,
      name: label('Ref: filter label: favorites'),
      displayType: FilterType.CHECKBOX_SINGLE,
      options: [{ value: 'favorites', label: 'filter' }],
      withTitle: false,
      multiple: false,
      apply: (selectedValues: string[]) => {
        if (!selectedValues?.length) return menuItemsList.map((item) => item.id);

        const filteredItems = menuItemsList.filter((item) => item.isFavorite);
        return filteredItems.map((item) => item.id);
      },
    });
  }

  const dishesFilterOptions = menuSource === FacilityMenuSource.Rr ? getDishes(label) : [];
  if (dishesFilterOptions.length > 0) {
    filters.push({
      id: 'filter_dishes',
      position: FilterPosition.ALWAYS_IN_MODAL,
      name: label('Ref: filter label: dishes', { textTransform: 'capitalize' }),
      displayType: FilterType.MULTIPLE_SELECT,
      options: dishesFilterOptions,
      isMultipleSelect: true,
      multiple: true,
      columns: 3,
      seeMoreLabel: label('Ref: see more', { textTransform: 'capitalize' }),
      seeLessLabel: label('Ref: see less', { textTransform: 'capitalize' }),
      apply: (selectedValues: string[]) => {
        const isVegetarianFilterSelected = selectedValues.some((x) => x === vegetarianValue);
        const isVeganFilterSelected = selectedValues.some((x) => x === veganValue);
        const isMindfulFilterSelected = selectedValues.some((x) => x === mindfulValue);
        const filterIsEnabled = selectedValues.some((x) =>
          [vegetarianValue, veganValue, mindfulValue].includes(x)
        );

        const filteredItems = filterIsEnabled
          ? menuItemsList.filter(
              (item) =>
                (item.isVegan || !isVeganFilterSelected) &&
                (item.isVegetarian || !isVegetarianFilterSelected) &&
                (item.isMindful || !isMindfulFilterSelected)
            )
          : menuItemsList;

        return filteredItems.map((item) => item.id);
      },
    });
  }

  if (menuSource === FacilityMenuSource.Rr) {
    filters.push({
      id: 'filter_calories',
      position: FilterPosition.ALWAYS_IN_MODAL,
      name: label('Ref: filter label: max calories'),
      min: defaultValues.minCalories,
      max: defaultValues.maxCalories,
      displayType: FilterType.RANGE,
      defaultValue: defaultValues.maxCalories,
      customMaxLabel: `${defaultValues.maxCalories}+`,
      multiple: false,
      unit: 'kcal',
      apply: (selectedValues: string[]) => {
        const value = Number(selectedValues[0]);
        const selectedValue = Number.isInteger(value) ? value : defaultValues.maxCalories;

        const isSelectedValueEqualsMaxCalories = selectedValue === defaultValues.maxCalories;
        const filteredItems = menuItemsList.filter(
          (item) => isSelectedValueEqualsMaxCalories || item.calories < selectedValue
        );

        return filteredItems.map((item) => item.id);
      },
    });
  }

  const allergrensFilterOptions = buildAllergens(allergens);
  if (allergens?.length > 0) {
    filters.push({
      id: 'filter_allergens',
      position: allergens?.length ? FilterPosition.ALWAYS_IN_MODAL : FilterPosition.INVISIBLE,
      name: label('Ref: filter label: allergens', { textTransform: 'capitalize' }),
      displayType: allergens?.length ? FilterType.MULTIPLE_SELECT : FilterType.NONE,
      options: allergrensFilterOptions,
      isMultipleSelect: true,
      multiple: true,
      columns: 3,
      columnWidth: '6.75rem',
      seeMoreLabel: label('Ref: see more', { textTransform: 'capitalize' }),
      seeLessLabel: label('Ref: see less', { textTransform: 'capitalize' }),
      apply: (selectedValues: string[]) => {
        if (JSON.stringify(selectedValues) !== JSON.stringify(selectedAllergens)) {
          cacheSelectedAllergens(selectedValues as AllergenType[]);
          setSelectedAllergens((selectedValues || selectedAllergens) as AllergenType[]);
        }

        const filteredItems = hideAllergens
          ? menuItemsList.filter((mi) => !mi.allergens?.some((a) => selectedValues.includes(a)))
          : menuItemsList;
        return filteredItems.map((item) => item.id);
      },
    });

    filters.push({
      id: 'filter_allergens_highlight',
      position: allergens?.length ? FilterPosition.ALWAYS_IN_MODAL : FilterPosition.INVISIBLE,
      name: label('Ref: filter label: highlight', { textTransform: 'capitalize' }),
      displayType: allergens?.length ? FilterType.MULTIPLE_SELECT : FilterType.NONE,
      options: [
        { value: HightlightAllergenFilterValues.Hightlight, label: label('Ref: Highlight') },
        { value: HightlightAllergenFilterValues.Hide, label: label('Ref: Hide') },
      ],
      isMultipleSelect: false,
      multiple: false,
      preventUnselect: true,
      columns: 2,
      excludeCount: true,
      text: label('Ref: Highlight text'),
      apply: (selectedValues: string[]) => {
        setHideAllergens(selectedValues[0] === HightlightAllergenFilterValues.Hide);
        return menuItemsList.map((item) => item.id);
      },
    });
  }

  return filters;
};
