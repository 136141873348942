import React from 'react';
import { ReactReduxContext } from 'react-redux';

import { serviceTypes } from '../../modules/config';

function getModule(moduleId) {
  try {
    return require('../../modules/' + moduleId + '/containers/' + moduleId).default;
  } catch (e) {
    /** This is here to support new folder structure */
    try {
      return require('../../modules/' + moduleId).default;
    } catch (e) {
      return undefined;
    }
  }
}

function getContext(moduleId, contextName) {
  try {
    return require('../../modules/' + moduleId + '/context/' + contextName).default;
  } catch (e) {
    return undefined;
  }
}

/**
 * @deprecated The function should not be used
 */
const withModuleContext =
  (serviceType, contextName, fallbackOnDefaultModule = false) =>
  (WrappedComponent) => {
    return (props) => {
      return (
        <ReactReduxContext.Consumer>
          {({ store }) => {
            //get the module id by looking at the service
            const services = store.getState().Core?.services;
            const service = services?.list?.find((el) => el.name === serviceType);

            //we use the active module, so if the setup option comes with a setup option, we use it, otherwise we use the default module
            //todo: only rely on the setup option if the corresponding module actually exists, probably better to create a getActiveModule function
            let moduleId = service?.setupOptions?.find((el) => el.name === module)?.value;
            let usedDefaultModule = false;
            if (!moduleId) {
              moduleId = serviceTypes[serviceType]?.defaultModule;
              usedDefaultModule = true;
            }

            //get the module: we will wrap the component with it to perform any relevant pre-requisit
            let ModuleComponent = getModule(moduleId);
            let context = getContext(moduleId, contextName);

            //at this point, we should have a context unless the active module doesn't provide this context. If so, we fall back on the default module if that's the expectation
            if (!context && !usedDefaultModule && fallbackOnDefaultModule) {
              moduleId = serviceTypes[serviceType]?.defaultModule;
              ModuleComponent = getModule(moduleId);
              context = getContext(moduleId, contextName);
            }

            if (!context) context = (Component) => Component;

            const ComponentWithContext = context(WrappedComponent);
            const ResultingComponent = () => (
              <ModuleComponent>
                <ComponentWithContext {...props} />
              </ModuleComponent>
            );

            return <ResultingComponent />;
          }}
        </ReactReduxContext.Consumer>
      );
    };
  };

export default withModuleContext;
