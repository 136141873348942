import { GiftIcon, VeganIcon, WarningAllergens } from '../../../assets/icons';
import { Tag, TAG_COLOR } from '../../../components/molecules/Tile';
import { TranslationProps } from '../../../localization/localization.types';
import {
  ProductPortion,
  PromotedItemModifier,
  PromotionalDiscountForItem,
  RedeemedFoodItem,
} from '../types/orderState.types';

import { getPrice } from './order.helper';
import { checkIfMoreThanOnePrice } from './order.helper';

import { GetLoyaltySchemesResponse } from '@/modules/LoyaltyStamps/api/api.types';

const iconSize = 20;

export const buildAllergenChip = ({
  showAllergensWarning,
  label,
  ariaLabel,
}: {
  showAllergensWarning: boolean;
  label: string;
  ariaLabel: string;
}): Tag | undefined => {
  if (showAllergensWarning) {
    return {
      name: label,
      icon: (
        <WarningAllergens width={iconSize} height={iconSize} role="img" aria-label={ariaLabel} />
      ),
      color: TAG_COLOR.DANGER,
    };
  }
};

export const buildLoyaltyRewardChip = ({
  showLoyaltyRewardChip,
  label,
  ariaLabel,
}: {
  showLoyaltyRewardChip: boolean;
  label: string;
  ariaLabel: string;
}): Tag | undefined => {
  if (showLoyaltyRewardChip) {
    return {
      name: label,
      icon: <GiftIcon width={iconSize} height={iconSize} role="img" aria-label={ariaLabel} />,
      color: TAG_COLOR.SECONDARY,
    };
  }
};

export const buildLoyaltyPointsForOrderingChip = ({
  showLoyaltyPointsForOrderingChip,
  label,
}: {
  showLoyaltyPointsForOrderingChip: boolean;
  label: string;
}): Tag | undefined => {
  if (showLoyaltyPointsForOrderingChip) {
    return {
      name: label,
      color: TAG_COLOR.SECONDARY,
    };
  }
};

export const buildVegeChip = ({
  isVegan,
  isVegetarian,
  veganLabel,
  vegeterianLabel,
}: {
  isVegan?: boolean;
  isVegetarian?: boolean;
  veganLabel: string;
  vegeterianLabel: string;
}): Tag | undefined => {
  if (isVegan) {
    return {
      name: veganLabel,
      icon: <VeganIcon width={iconSize} height={iconSize} role="img" aria-label={veganLabel} />,
      color: TAG_COLOR.SUCCESS,
    };
  }

  if (isVegetarian) {
    return {
      name: vegeterianLabel,
      icon: (
        <VeganIcon width={iconSize} height={iconSize} role="img" aria-label={vegeterianLabel} />
      ),
      color: TAG_COLOR.SUCCESS,
    };
  }
};

export const buildSticker = ({
  productCategory,
}: {
  productCategory?: string | null;
}): Tag | undefined => {
  if (productCategory) {
    return {
      name: productCategory,
    };
  }
};

export const hasPortionProvidingLoyaltyStamps = ({
  productPortions,
  menuId,
  loyaltySchemes,
}: {
  productPortions?: ProductPortion[];
  menuId?: number;
  loyaltySchemes?: GetLoyaltySchemesResponse[];
}) => {
  return loyaltySchemes && menuId && productPortions
    ? loyaltySchemes.some(
        (item) =>
          item.menuIdsProvidingProductsRedeemable.some((i) => i === menuId) &&
          item.productsProvidingStamp.some(
            (p) =>
              productPortions.some((x) => x.foodItemId === p.foodItemId) &&
              productPortions.some((x) => x.uomId === p.uomId)
          )
      )
    : false;
};

export const hasPortionRedeemableAsRewardInLoyaltyScheme = ({
  productPortions,
  menuId,
  loyaltySchemes,
}: {
  productPortions?: ProductPortion[];
  menuId?: number;
  loyaltySchemes?: GetLoyaltySchemesResponse[];
}) => {
  return loyaltySchemes && menuId && productPortions
    ? loyaltySchemes.some(
        (item) =>
          item.menuIdsProvidingProductsRedeemable.some((i) => i === menuId) &&
          item.productsRedeemable.some(
            (p) =>
              productPortions.some((x) => x.foodItemId === p.foodItemId) &&
              productPortions.some((x) => x.uomId === p.uomId)
          )
      )
    : false;
};

export const getFormattedPricesForViewable = ({
  price,
  viewablePrices,
  languageCode,
  label,
  isoCode,
}: {
  price: number;
  viewablePrices?: [{ name: string; value: number }];
  languageCode: string;
  isoCode?: string;
} & TranslationProps): {
  priceWithoutDiscount: string;
  discountPrice?: string;
} => {
  const lowestPrice = viewablePrices
    ? Math.min(...viewablePrices?.map((price: { name: string; value: number }) => price.value))
    : price;

  const lowestPriceFormatted = getPrice(lowestPrice, languageCode, isoCode ?? '');

  const isMoreThanOnePrice = checkIfMoreThanOnePrice(viewablePrices);

  return {
    priceWithoutDiscount: isMoreThanOnePrice
      ? `${label('Ref: From')} ${lowestPriceFormatted}`
      : lowestPriceFormatted,
  };
};

export const getFormattedPricesOrderable = ({
  uomId,
  price,
  languageCode,
  promotionalDiscounts,
  isoCode,
}: {
  uomId: number;
  price: number;
  languageCode: string;
  promotionalDiscounts?: PromotionalDiscountForItem[];
  isoCode?: string;
}): {
  priceWithoutDiscount: string;
  discountPrice?: string;
} => {
  const discountItem = promotionalDiscounts?.find((item) => item.uomId === uomId);
  const discountPrice = discountItem?.price;

  return {
    priceWithoutDiscount: getPrice(price, languageCode, isoCode ?? ''),
    discountPrice: discountPrice ? getPrice(discountPrice, languageCode, isoCode ?? '') : undefined,
  };
};

function compareItemModifierValueIds(
  firstModifierValueIds: number[],
  secondModifierValueIds: number[]
) {
  if (
    !Array.isArray(firstModifierValueIds) ||
    !Array.isArray(secondModifierValueIds) ||
    firstModifierValueIds.length !== secondModifierValueIds.length
  ) {
    return false;
  }

  const sortedFirstModifierValueIds = firstModifierValueIds.concat().sort((a, b) => a - b);
  const sortedSecondModifierValueIds = secondModifierValueIds.concat().sort((a, b) => a - b);

  if (sortedFirstModifierValueIds.toString() === sortedSecondModifierValueIds.toString())
    return true;
  else return false;
}

export const getFormattedProductTotals = ({
  uomId,
  price,
  quantity,
  languageCode,
  promotionalDiscounts,
  redeemedFoodItems,
  isoCode,
  itemModifierIds,
}: {
  uomId: number;
  price: number;
  quantity: number;
  languageCode: string;
  promotionalDiscounts: PromotionalDiscountForItem[] | undefined;
  redeemedFoodItems: RedeemedFoodItem[] | undefined;
  isoCode?: string;
  itemModifierIds?: number[];
}): {
  priceWithoutDiscount: string;
  discountPrice?: string;
} => {
  const promotionalDiscountForItems = promotionalDiscounts?.filter((item) => item.uomId === uomId);
  const redeemedFoodItemsForUom = redeemedFoodItems?.filter((item) => item.uomId === uomId);

  const promotionalDiscountsForItemWithModifiers: PromotionalDiscountForItem[] = (
    promotionalDiscountForItems || []
  ).reduce((acc: PromotionalDiscountForItem[], pd: PromotionalDiscountForItem) => {
    const matchingModifiers: number[] = pd.promotedItemModifiers?.reduce(
      (acc2: number[], item: PromotedItemModifier) => {
        return acc2.concat(Array(item.quantity).fill(item.modifierId));
      },
      []
    );

    if (compareItemModifierValueIds(matchingModifiers, itemModifierIds ?? [])) {
      return [...acc, pd];
    }
    return acc;
  }, []);

  const redeemedFoodItemsForUomQuantity = redeemedFoodItemsForUom?.reduce(
    (previousValue, item) => (previousValue += item.quantity),
    0
  );
  const paidItemsQuantity = quantity - (redeemedFoodItemsForUomQuantity ?? 0);
  const totalWithoutDiscount = price * paidItemsQuantity;
  const totalWithDiscount = promotionalDiscountsForItemWithModifiers?.reduce(
    (previousValue, item) => (previousValue += item.price),
    0
  );

  return {
    priceWithoutDiscount: getPrice(totalWithoutDiscount, languageCode, isoCode ?? ''),
    discountPrice: totalWithDiscount
      ? getPrice(totalWithDiscount, languageCode, isoCode ?? '')
      : undefined,
  };
};
