const labels = {
  PrivacyPolicy: 'Rahasia pribadi',
  TermsOfSale: 'Ketentuan Penjualan',
  TermsOfUse: 'Syarat & Ketentuan',
  home: 'Beranda',
  anytime: 'Kapan saja',
  anywhere: 'dimana saja',
  Download: 'Unduh',
  scrollRightButton: 'Kategori gulir ke kanan',
  scrollLeftButton: 'Kategori gulir di sebelah kiri',
  calendar: 'Kalender',
  'See more': 'Lihat lebih',
  'See less': 'Lihat lebih sedikit',
  'from (followed by a date, or date and time)': 'dari',
  'to (followed by a date, or date and time)': 'ke',
  'on (followed by a date)': 'Di',
  'at (followed by time)': 'pada',
  'from (followed by a location)': 'dari',
  'to (followed by a location)': 'ke',
  'on (followed by a site name)': 'Di',
  'from (followed by a person)': 'dari',
  previous: 'Sebelumnya',
  next: 'Berikutnya',
  success: 'kesuksesan',
  failure: 'kegagalan',
  today: 'Hari ini',
  tomorrow: 'Besok',
  Monday: 'Senin',
  Tuesday: 'Selasa',
  Wednesday: 'Rabu',
  Thursday: 'Kamis',
  Friday: 'Jumat',
  Saturday: 'Sabtu',
  Sunday: 'Minggu',
  everyday: 'Setiap hari',
  'open all day': 'Buka Nonstop',
  'opening hours': 'Jam buka',
  status: 'status',
  'to be confirmed': 'Untuk di konfirmasi',
  'only (as in filter only this)': 'hanya',
  edit: 'edit',
  submit: 'Kirim',
  cancel: 'Membatalkan',
  confirm: 'Konfirmasi',
  reset: 'Mengatur ulang',
  Delete: 'Menghapus',
  select: 'Pilih',
  switch: 'mengalihkan',
  close: 'Menutup',
  clear: 'bersih',
  remove: 'menghapus',
  logout: 'keluar',
  go: 'Pergilah',
  'see (as in consult this page or document)': 'Lihat',
  'see all (as in see all items)': 'Lihat semua',
  'see more (as in see more details for this item)': 'Lihat lebih',
  retry: 'mencoba kembali',
  'Ref: ok': 'Oke',
  'yes (as in yes I accept)': 'Iya',
  'no (as in no I do not accept)': 'Tidak',
  you: 'Anda',
  'thank you': 'Terima kasih',
  'sorry (standalone)': 'maaf',
  'to home (as in navigate to the homepage)': 'ke halaman rumah',
  map: 'peta',
  list: 'daftar',
  'Loading...': 'Memuat...',
  'Refreshing...': 'Memperbarui...',
  'Please select': 'Silahkan pilih',
  'Switch site': 'Beralih situs di sini',
  'File size cannot exceed {max file size}': 'Ukuran file tidak boleh melebihi {max file size}',
  Filters: 'filter',
  'Add attachment': 'Tambahkan sisipan',
  Search: 'Mencari',
  'More (as in: see more info)': 'Lebih',
  'You can attach a maximum of {max files number} files.':
    'Anda dapat melampirkan maksimum {max files number} file.',
  'There are no messages to display.': 'Tidak ada pesan untuk ditampilkan.',
  'Your selection did not return any result.': 'Pilihan Anda tidak memberikan hasil apa pun.',
  'Ref: Popup header for missing required fields in a form': 'Harap berikan informasi tambahan',
  'Ref: Required field': '{ref} adalah bidang yang wajib diisi.',
  email: 'Surel',
  phone: 'Nomor telepon',
  Account: 'Akun saya',
  Contacts: 'Kontak',
  Content: 'Isi',
  Events: 'Acara',
  Facilities: 'Fasilitas',
  Shop: 'Toko',
  Menu: 'Tidak bisa',
  Menus: 'Menu',
  Order: 'Memesan',
  Orders: 'Pesanan',
  Info: 'Info',
  FAQ: 'FAQ',
  Feedback: 'Penilaian',
  Review: 'Tinjauan',
  Requests: 'Permintaan',
  'Request Helpdesk': 'Minta Meja Bantuan',
  Location: 'Lokasi',
  Safety: 'Keamanan',
  FreeCoffee: 'Kopi gratis',
  Newsfeed: 'NEWSFEED',
  MyOrders: 'Pesananku',
  LoyaltyStamps: 'Perangko loyalitas',
  Stamps: 'Perangko',
  Stays: 'Tetap',
  Suppliers: 'Pemasok',
  Surveys: 'Survei',
  notifications: 'Pemberitahuan',
  cart: 'keranjang',
  'Ref: Reset your order': 'Setel ulang pesanan Anda',
  'Ref: My language': 'Bahasaku',
  Validate: 'Mengesahkan',
  'contact us': 'hubungi kami',
  'Ref: Start typing': 'Mulai mengetik',
  Occupancy: 'Hunian',
  'see less': 'Lihat lebih sedikit',
  'see more': 'Lihat lebih banyak',
  contacts: 'Kontak',
  Imprint: 'Jejak',
  'Ref: camera access request title': 'Izinkan akses ke kamera',
  'Ref: camera access request body':
    'Anda harus mengaktifkan penggunaan kamera di situs ini untuk menggunakan pemindai kode QR',
  or: 'atau',
  'Ref: Input required': '{input_name} diperlukan',
  'Enter a valid email address': 'Masukkan alamat email yang valid',
  'Ref: Input error E-mail already exists':
    'Sudah ada akun dengan email yang Anda masukkan. Harap login sebagai gantinya.',
  'Password must contain': 'Kata sandi harus berisi:',
  'Ref: Input password error strength':
    'Kata sandi harus berisi setidaknya 8 karakter, 1 nomor, 1 huruf dan 1 karakter khusus',
  Password: 'Kata sandi',
  'New password': 'Kata sandi baru',
  'Confirm password': 'Konfirmasi sandi',
  'Ref: Password does not match': 'Kata sandi dan konfirmasi kata sandi tidak cocok',
  'Ref: Old password does not match': 'Kata sandi lama tidak cocok',
  'Ref: Password validation min8Characters': 'Setidaknya 8 karakter',
  'Ref: Password validation min1Number': 'Setidaknya 1 digit',
  'Ref: Password validation min1Letter': 'Setidaknya 1 huruf',
  'Ref: Password validation min1SpecialChar': 'Setidaknya 1 karakter khusus',
  Continue: 'Melanjutkan',
  'Enter a valid phone number': 'Masukkan nomor telepon yang valid',
  'Phone is required': 'Telepon diperlukan',
  'Go to home': 'Pergi ke rumah',
  'Something went wrong': 'Ada yang salah',
  'Something went wrong, data not loaded': 'Ada yang salah, data tidak dimuat',
  'Change password': 'Ganti kata sandi',
  'remove one': 'Hapus satu',
  'add one': 'tambahkan satu',
  'Confirm mobile phone': 'Konfirmasikan ponsel',
  'Confirm Filters': 'Konfirmasi filter',
  'Reset Filters': 'Reset filter',
  '{selectedNum} active filters': '{selectedNum} filter aktif',
  'Wrong input icon': 'Ikon Input yang Salah',
  'Correct input icon': 'Ikon input yang benar',
  'Show input text icon': 'Tampilkan Ikon Teks Input',
  'Hide input text icon': 'Sembunyikan Ikon Teks Input',
  'Required fields info': '* Bidang yang diperlukan',
  'Ref: Select site on the map': 'Pilih situs Anda di peta',
  'Ref: Geolocation disabled':
    'Geolokasi dinonaktifkan. Untuk melihat situs terdekat, harap aktifkan.',
  'Ref: Reaction - LIKE': 'Suka',
  'Ref: Reaction - LOVE': 'Cinta',
  'Ref: Reaction - INTERESTING': 'Menarik',
  'Ref: Reaction - CONGRATE': 'Mengucapkan selamat',
  'Ref: Share': 'Membagikan',
  'Ref: Send by Email': 'Kirim melalui email',
  'Ref: Copy the link': 'Salin tautan',
  'Ref: Link was copied': 'Link disalin',
  'Ref: unable to copy url': 'Tidak dapat menyalin URL',
  'Ref: External link': 'Tautan eksternal',
  'Ref: Step': 'Melangkah',
  'input field': 'bidang input',
  'Ref: Zoom': 'Perbesar',
  'Increase hours': 'Tingkatkan jam',
  'Decrease hours': 'Berkurang jam',
  Hours: 'Jam',
  Minutes: 'Menit',
  'Ref: filter label: favorites': 'Tunjukkan favorit saya saja',
  'Ref: filter label: max calories': 'Max Kalori',
  'Ref: filter label: dishes': 'Cucian piring',
  'Ref: filter label: allergens': 'Alergen',
  'Ref: filter label: highlight': 'Sorot atau sembunyikan filter dengan piring',
  'Ref: Highlight': 'Menyorot',
  'Ref: Hide': 'Bersembunyi',
  'Ref: Vegan': 'Vegan',
  'Ref: Vegeterian': 'Vegetarian',
  'Ref: Mindful': 'Sadar',
  'Ref: Highlight text':
    'Di sini Anda dapat memilih untuk Hightlight atau menyembunyikan piring pada menu yang berisi alergen tertentu',
  'Ref: Just now': 'Baru saja',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} menit yang lalu',
  'Ref: 1 hour ago': '1 jam yang lalu',
  'Ref: {amountHours} hours ago': '{amountHours} beberapa jam yang lalu',
  'Ref: Yesterday at {time}': 'Kemarin di {time}',
  'Ref: {date} at {time}': '{date} at {time}',
  'Ref: filter label: date': 'Tanggal',
  'Ref: expand menu': 'memperluas menu',
  'Ref: collapse menu': 'Menu runtuh',
  'Ref: My site': 'Situsku',
  'Ref: Recent': 'Terkini',
  'Ref: Services': 'Jasa',
  'Ref: products in cart': 'Jumlah produk di dalam kereta',
  'Ref: no product in cart': 'Tidak ada produk di dalam kereta',
  'Ref: Survey': 'Survei',
  'Access to camera was denied. Please allow access and try again':
    'Akses ke kamera ditolak. Mohon izinkan akses dan coba lagi',
  'Add an attachment': 'Tambahkan lampiran',
  'Take a picture': 'Mengambil gambar',
  'Add a file from your device': 'Tambahkan file dari perangkat Anda',
  'Ref: Loading page content, please wait': 'Memuat konten halaman, harap tunggu',
  Star: 'Bintang',
  Stars: 'Bintang',
  'Accommodation Request': 'Permintaan akomodasi',
  'Ref: Guest Accomodation Request': 'Permintaan Tamu',
  'Ref: Search a menu, product or facility': 'Cari menu, produk, atau fasilitas',
  ConnectionLost: 'Koneksi jaringan hilang',
  ConnectionRestored: 'Koneksi jaringan dipulihkan',
  'Ref: Skip to main content': 'Lewati Konten Utama',
  'Ref: No options': 'Tidak ada opsi',
  'Ref: Global search modal': 'Modal Pencarian Global',
  'Ref: Search a facility': 'Cari fasilitas',
  'Ref: Search a menu or product': 'Cari menu atau produk',
  'Ref: Reaction': 'Reaksi',
  'Ref: times': 'waktu',
  'Ref: reaction. Change reaction': 'reaksi. Ubah reaksi',
  'Ref: No reaction. React': 'Tidak ada reaksi. Reaksi',
  'Ref: LIKE': 'Menyukai',
  'Ref: LOVE': 'Cinta',
  'Ref: INTERESTING': 'Menarik',
  'Ref: CONGRATE': 'Selamat',
  'Ref: Open search': 'Buka kotak pencarian',
  'Ref: Close search': 'Tutup kotak pencarian',
  'Ref: Total file size msg': 'Total ukuran file tidak dapat melebihi {max file size}',
  'Ref: Oops Title': 'Ups!',
  'Ref: Well Done': 'Bagus sekali {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Panah Navigasi - Lanjutkan',
  'Ref: Reload': 'Muat ulang',
  'Ref: All Dates': 'Semua tanggal',
  'Ref: Today': 'Hari ini',
  'Ref: Validate': 'Mengesahkan',
  'Ref: toast error title': 'Ups!',
  'Ref: toast error subtitle': 'Terjadi kesalahan, silakan coba lagi.',
  'Ref: Calendar': 'Kalender',
  'Ref: Selected': 'Terpilih',
  'Ref: Previous Month': 'Bulan sebelumnya',
  'Ref: Next Month': 'Bulan depan',
  'This module has been retired': 'Modul ini telah dihentikan',
};
export default labels;
