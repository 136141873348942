import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ShoppingBagIcon } from '../../../assets/icons';
import { MenuIllustration } from '../../../assets/illustrations';
import { Filtering } from '../../../components/atoms/Filters/Filters.types';
import Column from '../../../components/organisms/Column';
import ListPage from '../../../components/templates/ListPage/ListPage';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { BaseFacilityState } from '../../Facilities/types/types';
import { useGetOrderHistoryQuery } from '../api';
import { useFacilityMenuImage } from '../hooks/useFacilityMenuImage/useFacilityMenuImage';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import {
  HistoricalOrderType,
  OrderHistoryTileProps,
  orderTypeFilterId,
} from '../types/orderHistory.types';

import { getImageForOrder, mapListItems, prepareFilters } from './OrderHistoryHelper';
import OrderHistoryTile from './OrderHistoryTile/OrderHistoryTile';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import GenericErrorPage from '@/modules/Core/components/GenericErrorPage';
import useCurrentLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { defaultState as facilityDefaultState } from '@/modules/Facilities/reducers/facilitiesReducer';
import { UserSteps } from '@/types/userSteps.types';

const OrderHistory = () => {
  const [orderType, setOrderType] = useState<HistoricalOrderType>(
    HistoricalOrderType.CurrentAndFuture
  );
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const { label } = useOrderTranslation(__filename);

  const {
    data: orders,
    isError,
    isLoading,
  } = useGetOrderHistoryQuery({ siteId: site.id, useErrorBoundary: false });
  const facilityData = useSelector(
    (state: BaseFacilityState) => state.Facilities?.data ?? facilityDefaultState.data
  );
  const { currentLanguageCode } = useCurrentLanguage();
  const { logUserSteps } = useUserStepsInsightsLogging();

  useEffect(() => {
    logUserSteps({ event: UserSteps.OrderHistoryList });
  }, [logUserSteps]);

  const facilityImagesInfo = useMemo(() => {
    const facilities = Object.values(facilityData);
    return facilities.map((facility) => ({ facilityId: facility.id, imageId: facility.images[0] }));
  }, [facilityData]);

  const facilities = useMemo(() => Object.values(facilityData), [facilityData]);

  const { getFacilityImage } = useFacilityMenuImage(facilityImagesInfo);

  if (isLoading) return <LoadingPage />;
  if (isError) return <GenericErrorPage />;

  const listItems = mapListItems(orders, orderType);

  const listFilters = {
    filters: prepareFilters(orderType, label, listItems),
    handleFilteringChange: (filtering: Filtering) => {
      const orderType = !!filtering[orderTypeFilterId].CurrentAndFuture
        ? HistoricalOrderType.CurrentAndFuture
        : HistoricalOrderType.Past;
      setOrderType(orderType);
    },
  };

  const noOrdersKey =
    orderType === HistoricalOrderType.CurrentAndFuture
      ? 'Ref: You have no future orders'
      : 'Ref: You have no past orders';

  const noResults = {
    icon: <ShoppingBagIcon width="32" height="32" />,
    title: label(noOrdersKey),
  };

  return (
    <ListPage
      data-testid="order-history-list"
      hasBackLink={false}
      title={label('Ref: Page Title')}
      items={listItems}
      filter={listFilters}
      noResults={noResults}
      hideFilterTitle={true}
      aside={
        <Column.Complementary>
          <MenuIllustration />
        </Column.Complementary>
      }
      itemRenderer={(item: OrderHistoryTileProps) => {
        return (
          <OrderHistoryTile
            order={item.order}
            facilities={facilities}
            currentLanguageCode={currentLanguageCode}
            image={getImageForOrder(getFacilityImage, item.order)}
          />
        );
      }}
    />
  );
};

export default OrderHistory;
