import { act, cleanup, screen } from '@testing-library/react';

import { SERVICE } from '../../../config';
import { useGetFilterPreferencesQuery } from '../../../Core/api/account/accountPreferencesApi';
import { useGetLoyaltySchemesQuery, useGetUserLoyaltySchemesProgressQuery } from '../../api';

import RedeemableProducts from './RedeemableProducts';

import { renderedComponent } from '@/helpers/tests/renderComponent';
import { useFetchLoyaltySchemes } from '@/modules/Order/hooks/useFetchLoyaltySchemes';
import { useFetchLoyaltyStampsProgress } from '@/modules/Order/hooks/useFetchLoyaltyStampsProgress';

jest.mock('../../api', () => ({
  ...jest.requireActual('../../api'),
  useGetLoyaltySchemesQuery: jest.fn(),
  useGetUserLoyaltySchemesProgressQuery: jest.fn(),
}));

jest.mock('../../../Order/hooks/useProductPortion/useProductPortion', () => ({
  useProductPortion: () => ({
    addFavoriteProduct: jest.fn(),
    removeFavoriteProduct: jest.fn(),
  }),
}));

jest.mock('@/modules/Order/hooks/useFetchLoyaltySchemes', () => ({
  ...jest.requireActual('@/modules/Order/hooks/useFetchLoyaltySchemes'),
  useFetchLoyaltySchemes: jest.fn(),
}));

jest.mock('@/modules/Order/hooks/useFetchLoyaltyStampsProgress', () => ({
  ...jest.requireActual('@/modules/Order/hooks/useFetchLoyaltyStampsProgress'),
  useFetchLoyaltyStampsProgress: jest.fn(),
}));

jest.mock('../../../Core/api/account/accountPreferencesApi', () => ({
  ...jest.requireActual('../../../Core/api/account/accountPreferencesApi'),
  useGetFilterPreferencesQuery: jest.fn(),
}));

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: () => ({
    id: 1064,
  }),
}));

const mockUseGetMenusQuery = jest.fn();
jest.mock('../../../Order/api', () => ({
  useGetMenusQuery: () => mockUseGetMenusQuery(),
}));

const loyaltySchemes = [
  {
    id: 1064,
    name: 'Exotic Summer Deal',
    description:
      'Collect 5 stamps for buying Banana, Potato or Granny Smith Apple and get one Pineapple, Strawberries or Onion for free. ',
    productsProvidingStamp: [
      {
        uomId: 30551,
        foodItemId: 28508,
      },
    ],
    productsRedeemable: [
      {
        uomId: 30552,
        foodItemId: 28509,
      },
      {
        uomId: 30635,
        foodItemId: 28586,
      },
    ],
    imageUrl:
      'https://rgukretailrangerpre9277.blob.core.windows.net/loyalty/20230706115314-pineapple.jpg',
    stampsRequiredForRedeem: 5,
    menuIdsProvidingProductsRedeemable: [3329],
    menuIdsProvidingProductsProvidingStamp: [3329],
  },
];

const userLoyaltySchemesProgress = [
  {
    loyaltySchemeId: 1064,
    stampsCollected: 13,
  },
];

const userAllergenPreferences = {
  selectedAllergens: [
    'Milk',
    'Eggs',
    'Treenuts',
    'Peanut',
    'Shellfish',
    'Wheat',
    'Mustard',
    'Fish',
    'Soybean',
    'Sesame',
    'Gluten',
  ],
  selectedDietaries: [],
  maxCalories: null,
  hideFilteredDishes: false,
};

const state = {
  Core: {
    context: {
      site: {
        id: 1,
        currency: { isoCode: 'USD' },
      },
    },
    services: {
      list: [
        {
          name: SERVICE.FOOD_ORDER,
        },
        {
          name: SERVICE.FACILITY,
        },
      ],
    },
    notifications: {
      items: [
        {
          notificationId: 1,
          message: 'well well well',
        },
        {
          notificationId: 2,
          message: 'Mr Bond',
        },
      ],
      total: 2,
    },
    access: { shouldSelectSiteContext: false },
  },
  Order: {
    locks: { createOrderDraft: false },
    cart: {
      date: new Date(),
      facilityId: '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
      submissionTrackingId: '5d19613e-1bc7-4442-b361-6cb7742af193',
      menuId: 3329,
      siteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
      menuPortionItems: [
        {
          id: '53d264ca-02d6-480b-a348-e8d4a5ce8b90',
          img: 'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28509-20230706122601-1579502896-Pineapple_copy.png',
          menuItemId: 16814831,
          name: 'Abacaxi pineapple',
          price: 2.4,
          quantity: 1,
          uomId: 30552,
          foodItemId: 28509,
          description: '',
          isVegan: true,
          isVegetarian: true,
          genericCategory: 'Fruit',
        },
      ],
      moment: 'Fruits and Vegetables',
      selectedFulfillmentType: {
        id: 10214,
        type: 'PickupOption',
      },
      pickupLocations: [
        {
          pickupSpotId: 2195,
          order: 0,
          name: 'bite timeset',
        },
      ],
      pickupInformation: {
        pickupSpotId: null,
        pickupSpotName: null,
        pickupTimeSlotId: null,
        pickupTime: null,
      },
    },
    menus: [
      {
        id: 3329,
        facilityId: '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
        name: 'Bite Everyday',
        message: 'Bite Team Test Menu for Loyalty Module.',
        isOrderable: true,
        logoUrl:
          'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
        date: new Date(),
        isFutureOrdering: true,
        isConferenceCatering: false,
        fulfillmentTypes: [
          {
            id: 10214,
            type: 'PickupOption',
          },
          {
            id: 28,
            type: 'DineIn',
          },
          {
            id: 18979,
            type: 'Delivery',
          },
        ],
        menuItems: [
          {
            name: 'Granny Smith Apple',
            mealName: 'All day groceries',
            foodSubCategoryDescription: null,
            description:
              'The Granny Smith, also known as a green apple or sour apple, is an apple cultivar that originated in Australia in 1868. It is named after Maria Ann Smith, who propagated the cultivar from a chance seedling. The tree is thought to be a hybrid of Malus sylvestris, the European wild apple, with the domesticated apple Malus domestica as the polleniser.',
            isOrderable: true,
            sortOrder: 0,
            price: 1.2,
            minimalPrice: 0,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '16819065_30551',
                foodItemSourceKey: '',
                name: 'Granny Smith Apple',
                description:
                  'The Granny Smith, also known as a green apple or sour apple, is an apple cultivar that originated in Australia in 1868. It is named after Maria Ann Smith, who propagated the cultivar from a chance seedling. The tree is thought to be a hybrid of Malus sylvestris, the European wild apple, with the domesticated apple Malus domestica as the polleniser.',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 100,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 5,
                  sugar: 11,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '146g',
                portion: 'standard',
                gramWeight: '146',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 1.2,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                uomId: 30551,
                foodItemId: 28508,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage:
              'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28508-20230706121748-640px-Granny_smith_and_cross_section.jpg',
            detailsImage:
              'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28508-20230706121750-640px-Granny_smith_and_cross_section.jpg',
            menuItemId: 16819065,
            dayPart: 'All day groceries',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Fruit',
          },
          {
            name: 'Abacaxi pineapple',
            mealName: 'All day groceries',
            foodSubCategoryDescription: null,
            description: null,
            isOrderable: true,
            sortOrder: 1,
            price: 2.4,
            minimalPrice: 2.4,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '16819066_30552',
                foodItemSourceKey: '',
                name: 'Abacaxi pineapple',
                description: null,
                ingredients: null,
                allergens: [
                  {
                    id: 'Milk',
                    name: 'Milk',
                    contains: true,
                  },
                  {
                    id: 'Celery',
                    name: 'Celery',
                    contains: true,
                  },
                  {
                    id: 'Nuts',
                    name: 'Nuts',
                    contains: true,
                  },
                ],
                nutritions: {
                  calories: 80,
                  kilojoules: 0,
                  carbohydrates: 22,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 2,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 2.4,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                uomId: 30552,
                foodItemId: 28509,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage:
              'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28509-20230706122601-1579502896-Pineapple_copy.png',
            detailsImage:
              'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28509-20230706122705-1579502896-Pineapple_copy.png',
            menuItemId: 16819066,
            dayPart: 'All day groceries',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Fruit',
          },
          {
            name: 'Strawberries',
            mealName: 'All day groceries',
            foodSubCategoryDescription: null,
            description: null,
            isOrderable: true,
            sortOrder: 2,
            price: 4.8,
            minimalPrice: 4.8,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '16819067_30635',
                foodItemSourceKey: '',
                name: 'Strawberries',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '100g',
                portion: 'Standart',
                gramWeight: '100',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 4.8,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                uomId: 30635,
                foodItemId: 28586,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage:
              'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28586-20230714132026-640px-Strawberries_at_Ljubljana_Central_Market.jpg',
            detailsImage:
              'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28586-20230714132034-640px-Strawberries_at_Ljubljana_Central_Market.jpg',
            menuItemId: 16819067,
            dayPart: 'All day groceries',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Fruit',
          },
        ],
        source: 'Rr',
        isScanAndGo: false,
      },
    ],
  },
};

describe('RedeemableProducts', () => {
  afterAll(() => cleanup());

  describe('while fetching data', () => {
    beforeEach(async () => {
      mockUseGetMenusQuery.mockReturnValue({ data: [], isLoading: false });
      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: true,
      });
      (useGetUserLoyaltySchemesProgressQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: true,
      });
      (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: true,
      });
      await act(async () => {
        renderedComponent(RedeemableProducts, { label: (s: string) => s });
      });
    });
    it('should display tile skeletons', () => {
      const skeleton1 = screen.getAllByTestId('skeleton-placeholder-0');
      const skeleton2 = screen.getAllByTestId('skeleton-placeholder-1');
      const skeleton3 = screen.getAllByTestId('skeleton-placeholder-2');
      expect(skeleton1).toBeTruthy();
      expect(skeleton2).toBeTruthy();
      expect(skeleton3).toBeTruthy();
    });
  });

  describe('without data', () => {
    beforeEach(async () => {
      mockUseGetMenusQuery.mockReturnValue({ data: [], isLoading: false });
      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({ data: [], isLoading: false });
      (useGetUserLoyaltySchemesProgressQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: false,
      });
      (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: false,
      });
      await act(async () => {
        renderedComponent(RedeemableProducts, { label: (s: string) => s });
      });
    });
    it('should render info-message', () => {
      const infoMessage = screen.getAllByText('Your selection did not return any result.');
      expect(infoMessage).toBeTruthy();
    });
  });

  describe('with data', () => {
    beforeEach(async () => {
      mockUseGetMenusQuery.mockReturnValue({ data: state.Order.menus, isLoading: false });
      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
        data: loyaltySchemes,
        isLoading: false,
      });
      (useGetUserLoyaltySchemesProgressQuery as jest.Mock).mockReturnValue({
        data: userLoyaltySchemesProgress,
        isLoading: false,
      });
      (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
        data: userAllergenPreferences,
        isLoading: false,
      });
      (useFetchLoyaltySchemes as jest.Mock).mockReturnValue({
        schemes: loyaltySchemes,
        isLoading: false,
      });
      (useFetchLoyaltyStampsProgress as jest.Mock).mockReturnValue({
        userProgress: userLoyaltySchemesProgress,
        isLoading: false,
      });
      await act(async () => {
        renderedComponent(RedeemableProducts, { label: (s: string) => s }, state);
      });
    });

    it('should render name of the scheme', () => {
      const schemeName = screen.getByText(loyaltySchemes[0].name);

      expect(schemeName).toBeTruthy();
    });

    it('should correctly compute progress towards a stamp', () => {
      const progress = screen.getByText(
        `${
          userLoyaltySchemesProgress[0].stampsCollected % loyaltySchemes[0].stampsRequiredForRedeem
        }/${loyaltySchemes[0].stampsRequiredForRedeem}`
      );

      expect(progress).toBeTruthy();
    });

    it('should correctly compute number of rewards with redeemable item in cart', () => {
      const reward = screen.getByText(
        `${
          (userLoyaltySchemesProgress[0].stampsCollected -
            (userLoyaltySchemesProgress[0].stampsCollected %
              loyaltySchemes[0].stampsRequiredForRedeem)) /
            loyaltySchemes[0].stampsRequiredForRedeem -
          state.Order.cart.menuPortionItems[0].quantity
        } Reward`
      );

      expect(reward).toBeTruthy();
    });

    it('should show scheme description', () => {
      const schemeDescription = screen.getByText(
        `Collect 5 stamps for buying Banana, Potato or Granny Smith Apple and get one Pineapple, Strawberries or Onion for free.`
      );

      expect(schemeDescription).toBeTruthy();
    });

    it('should date filter component', () => {
      const dateCmp = screen.getByText(`Today`);

      expect(dateCmp).toBeTruthy();
    });

    it('should show Menu Name and Category joined by •', () => {
      const reward1 = screen.getByText(`Bite Everyday • All day groceries`);

      expect(reward1).toBeTruthy();
    });

    it('should display list with redeemable products', () => {
      const list = screen.getByTestId(`tile-list`);
      const firstProductTile = screen.getByTestId(`ProductTile-3329_Abacaxi pineapple_standard`);
      const thirdProductTile = screen.getByTestId(
        `ProductTile-3329_Strawberries_Standart-children`
      );

      expect(list).toBeTruthy();
      expect(firstProductTile).toBeTruthy();
      expect(thirdProductTile).toBeTruthy();
    });

    it('should display "Free" for each product', () => {
      const freeItems = screen.getAllByText(`Free`);

      expect(freeItems.length === 2).toBeTruthy();
    });

    it('should display Allergen Chip for product where it match with user preferences', () => {
      const chips = screen.getAllByText(`Allergens`);

      expect(chips.length === 1).toBeTruthy();
    });

    it('should display Vegan Chip ', () => {
      const chips = screen.getAllByText(`Vegan`);

      expect(chips.length === 2).toBeTruthy();
    });

    it('should show the image of the scheme and items', () => {
      const schemeImage = screen.getByTestId(`redeemable-products-image-with-fallback`);
      const image = screen.getAllByTestId(`product-tile-image-with-fallback`);

      expect(schemeImage).toHaveProperty(
        'src',
        'https://rgukretailrangerpre9277.blob.core.windows.net/loyalty/20230706115314-pineapple.jpg'
      );

      expect(image[0]).toHaveProperty(
        'src',
        'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28509-20230706122601-1579502896-Pineapple_copy.png'
      );

      expect(image[1]).toHaveProperty(
        'src',
        'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28586-20230714132026-640px-Strawberries_at_Ljubljana_Central_Market.jpg'
      );
    });
  });
});
