import { useHistory, useLocation } from 'react-router';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import { NOTICEPAGE_TYPE } from '../../../../components/templates/NoticePage/NoticePage.types';
import { isKiosk } from '../../../../helpers/misc';
import KioskOrder from '../../../Order/components/KioskOrder';
import { KioskOrderVariant } from '../../../Order/types/KioskOrder.types';
import { getErrors } from '../../helpers/helpers';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

import { GenericErrorProps } from './GenericErrorPage.types';

const GenericErrorPage = ({ title, errors, actions, withNavbar }: GenericErrorProps) => {
  const history = useHistory();
  const location =
    useLocation<{
      errorBody: string;
    }>();
  const { label } = useCoreTranslation(__filename);

  const errorContent = errors?.length
    ? getErrors(errors, label)
    : location.state?.errorBody || label('Ref: Generic Error Body');

  if (isKiosk) {
    return <KioskOrder variant={KioskOrderVariant.FAIL} label={label} />;
  }

  return (
    <NoticePage
      withNavBar={withNavbar}
      id="generic-error"
      hideAllWidgets
      type={NOTICEPAGE_TYPE.ERROR}
      title={title ?? label('Ref: Generic Error Title', { textTransform: 'capitalize' })}
      content={errorContent}
      actions={
        actions ?? [
          {
            label: label('Go to home'),
            action: () => history.push('/'),
            look: 'secondary',
          },
        ]
      }
      tabTitle={label('Ref: TabTitle')}
    />
  );
};
export default GenericErrorPage;
