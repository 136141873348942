import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { CheckIcon } from '../../../../../assets/icons';
import { LetterDelivered } from '../../../../../assets/illustrations';
import { BUTTON_LOOK } from '../../../../../components/atoms/Button';
import Notification, { NOTIFICATION_LOOK } from '../../../../../components/molecules/Notification';
import NoticePage from '../../../../../components/templates/NoticePage/NoticePage';
import { sendForbiddenEmail } from '../../../actions';
import { pagePaths } from '../../../config';
import { useCoreTranslation } from '../../../hooks/useCoreTranslation';

import { ResetEmailSentProps } from '.';

const ResetEmailSent = ({ email }: ResetEmailSentProps) => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);
  const dispatch = useDispatch();

  const [isNotificationDisplayed, setNotificationDisplayed] = useState<boolean>(false);

  const notification = (
    <Notification
      title={label('Ref: New email sent')}
      look={NOTIFICATION_LOOK.SUCCESS}
      icon={<CheckIcon />}
    />
  );
  const resendResetEmail = async () => {
    if (email) {
      await dispatch(sendForbiddenEmail({ email }));
    }
    setNotificationDisplayed(true);
  };

  const actions = [
    {
      label: label('Go to login page'),
      action: () => history.push(pagePaths.Login),
      look: BUTTON_LOOK.SECONDARY,
    },
    {
      label: label('Resend email'),
      action: () => resendResetEmail(),
      look: BUTTON_LOOK.TERTIARY,
    },
  ];

  return (
    <NoticePage
      img={<LetterDelivered />}
      hideAllWidgets
      withNavBar={false}
      hasBackLink={true}
      title={label('Ref: Email sent title')}
      note={label('Ref: Email sent msg', {
        replace: { email_address: email ?? '' },
      })}
      actions={actions}
      topContent={isNotificationDisplayed && notification}
      tabTitle={label('Ref: TabTitle')}
    />
  );
};

export default ResetEmailSent;
